import React from "react";

const companyCard = ({ data, nbOfCompanies }) => {
  return (
    <div
      className={`flex flex-col items-center w-full ${
        nbOfCompanies > 1 &&
        " first:border-r first:border-neutral/gray/gray-60"
      }`}
      key={data?.companyName}
    >
      <a href={data?.link} className="space-y-1">
        <p className="text-sm text-center">Sur {data?.companyName}</p>

        <p className="text-sm font-semibold text-center">{data?.price + "€"}</p>
      </a>
    </div>
  );
};

export default companyCard;
