import React from "react";
import { IonIcon } from "@ionic/react";
import { add, removeOutline } from "ionicons/icons";

const CounterButton = ({
  handleRemove,
  handleAdd,
  counter,
  min,
  max,
  handleOnChange,
  onBlur,
}) => {
  counter = parseInt(counter);
  min = parseInt(min);
  max = parseInt(max);

  return (
    <div className="flex items-center">
      <button
        className={`flex outline-none items-center justify-center p-1.5 border-2 rounded-full cursor-pointer ${
          counter === min
            ? "border-[#EAEAEC] bg-[#EAEAEC]"
            : "border-main/main-100 bg-transparent"
        }`}
        onClick={handleRemove}
        disabled={counter === min}
      >
        <IonIcon
          icon={removeOutline}
          className={`w-6 h-6 ${
            counter === min ? "text-neutral/gray/gray-60" : "text-main/main-100"
          }`}
        ></IonIcon>
      </button>

      <input
        value={counter}
        className="outline-none text-center w-10 bg-transparent"
        onChange={handleOnChange}
        type="number"
        onBlur={onBlur}
      />

      <button
        className={`flex outline-none items-center justify-center p-1.5 border-2 rounded-full cursor-pointer ${
          counter === max
            ? "border-[#EAEAEC] bg-[#EAEAEC]"
            : "border-main/main-100 bg-transparent"
        }`}
        onClick={handleAdd}
        disabled={counter === max}
      >
        <IonIcon
          icon={add}
          className={`w-6 h-6 ${
            counter === max ? "text-neutral/gray/gray-60" : "text-main/main-100"
          }`}
        ></IonIcon>
      </button>
    </div>
  );
};

export default CounterButton;
