import React from "react";
import useAd from "../../hooks/useAd";
import AdModal from "./AdModal";

const ErrorContent = () => {
  const { startAd, setActiveStep } = useAd();

  return (
    <>
      <div className="flex flex-col justify-center h-full space-y-10 px-0 md:px-10">
        <h2 className="leading-10 text-center xs:leading-8">
          Erreur !!
          <br />
          <span className="text-main/main-100">{startAd.title}</span>
          <br />
          n'a pas pu être créé"
        </h2>

        <div className="">
          <div className="center ">
            <img
              src="/images/error-sloth.svg"
              alt="sloth-error"
              className="  h-[140px] object-contain"
            />
          </div>

          <div className="center">
            <button className="purple-button" onClick={() => setActiveStep(-1)}>
              ALLER {"\u00C0"} L'ACCUEIL
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const Error = ({}) => {
  return <AdModal children={<ErrorContent />} stepNumber={8} height="600px" />;
};
export default Error;
