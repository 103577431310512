import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@ionic/react/css/core.css";
import { AuthProvider } from "./hooks/useAuth";
import { setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";
import { BUILD_ENV } from "./App";
import { LoadScript } from "@react-google-maps/api";

const googleApiKey =
  BUILD_ENV === "production"
    ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PROD
    : process.env.REACT_APP_GOOGLE_MAPS_API_KEY_STAGING;

const libraries = ["places"];

Amplify.configure(awsconfig);
setDefaultOptions({ locale: fr });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <LoadScript googleMapsApiKey={googleApiKey} libraries={libraries}>
        <App />
      </LoadScript>
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
