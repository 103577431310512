import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { arrowForward, arrowBack } from "ionicons/icons";
import MobileStepper from "@mui/material/MobileStepper";
import useAuth from "../../hooks/useAuth";
import useAd from "../../hooks/useAd";

const Actuality = ({ field }) => {
  const {
    propertyName,
    titleVerified,
    titleCallToAction,
    titleError,
    descriptionCallToAction,
    descriptionError,
    status,
    actionName,
    image,
    action,
  } = field;

  const ToBeVerified = () => {
    return (
      <div className="py-4 pr-4 my-3 lg:py-4 lg:my-5 rounded-[16px] lg:rounded-[116px] border-2 bg-main/main-100 bg-opacity-5 border-main/main-100 ">
        <div className="grid grid-cols-5 lg:grid-cols-6 space-x-6">
          <div className="flex items-center  relative pl-4  lg:pl-5">
            <img
              className=" w-[40px] h-[40px] rounded-full lg:rounded-[0] lg:w-[148px] lg:h-[57px]  absolute top-[0] lg:static"
              src={image}
              alt="picture verification "
            />
          </div>
          <div className="col-span-4 space-y-2 lg:space-y-0 flex lg:items-center flex-col lg:grid lg:grid-rows-2 ">
            <p className="text-[16px] lg:text-xl font-semibold lg:font-bold ">
              {titleCallToAction}
            </p>

            <p className=" text-neutral/gray/gray-100  lg:-mt-1 text-[16px] line-clamp-3">
              {descriptionCallToAction}
            </p>
          </div>

          <div className="col-start-4   lg:col-start-6 pb-2 lg:pb-0 lg:flex lg:items-center lg:relative left-4  mt-3 mb-8 lg:m-0">
            <div className=" lg:flex lg:items-center absolute right-[40%]  lg:right-0  ">
              <button
                className="secondary-purple-button center transition-none !h-[38px] !w-[99px] lg:!h-[48px] lg:!w-[134px] !text-sm !font-medium absolute right-0 mr-5"
                onClick={action}
              >
                {actionName}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Error = () => {
    return (
      <div className="py-4 pr-4 my-3 lg:py-4 lg:my-5 rounded-[16px] lg:rounded-[116px] border-2 bg-[#EFC2BF33] bg-opacity-20 border-semantic/red-100">
        <div className="grid grid-cols-5 lg:grid-cols-6 space-x-6">
          <div className="flex items-center  pl-4  lg:pl-5 relative">
            <img
              className="w-[40px] h-[40px] absolute top-[0] lg:static "
              src="/images/navigation-signs/red-error-arrow.svg"
              alt="red arrow picture"
            />
          </div>
          <div className="col-span-4 space-y-2 lg:space-y-0 flex lg:items-center  flex-col lg:grid lg:grid-rows-2 ">
            <p className="text-[16px] lg:text-xl font-semibold lg:font-bold">
              {titleError}
            </p>

            <p className=" text-neutral/gray/gray-100 lg:-mt-1 text-[16px] line-clamp-3">
              {descriptionError}
            </p>
          </div>

          <div className="col-start-4   lg:col-start-6 pb-2 lg:pb-0 lg:flex lg:items-center lg:relative left-4  mt-3 mb-8 lg:m-0">
            <div className=" lg:flex lg:items-center absolute right-[40%]  lg:right-0  ">
              <button
                className="primary-red-button transition-none !h-[38px] !w-[99px] lg:!h-[48px] lg:!w-[134px] center !text-sm !font-medium absolute right-0 mr-5"
                onClick={action}
              >
                {actionName}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Verified = () => {
    return (
      <div className="py-3 pr-4 my-2 lg:py-4 lg:my-5 rounded-[16px] lg:rounded-[116px] border-2  bg-[#F3F7F3] border-semantic/success-100">
        <div className="grid grid-cols-5 lg:grid-cols-6 space-x-6">
          <div className="flex items-center  pl-4  lg:pl-5">
            <img
              className="w-[40px] h-[40px] "
              src="/images/navigation-signs/green-checked-mark-circle.svg"
              alt="green checked mark circle"
            />
          </div>
          <div className="col-span-4  flex items-center ">
            <p className="text-[16px] lg:text-xl font-semibold lg:font-bold">
              {titleVerified}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const AdToBeVerified = () => {
    return (
      <div className="py-4 pr-4  my-3 lg:py-4 lg:my-5 rounded-[16px] lg:rounded-[116px]  bg-main/main-100 bg-opacity-5 ">
        <div className="grid grid-cols-5 lg:grid-cols-6 space-x-6">
          <div className="flex items-center relative pl-4 lg:mt-0 lg:pl-5">
            <img
              className="w-[40px] h-[40px] rounded-full lg:w-[148px] lg:h-[57px] object-cover"
              src={image}
              alt="picture of the house"
            />
          </div>

          <div className="col-span-4  space-y-2 lg:space-y-0 lg:items-center  flex flex-col lg:grid lg:grid-rows-2 ">
            <p className="text-[16px] lg:text-xl font-semibold lg:font-bold">
              Publiez votre annonce{" "}
              <span className="text-main/main-100">{propertyName}</span> en{" "}
              <span className="text-main/main-100">7 étapes</span>{" "}
            </p>

            <p className=" text-neutral/gray/gray-100 -mt-1 text-[16px] line-clamp-3">
              {descriptionCallToAction}
            </p>
          </div>

          <div className="col-start-4   lg:col-start-6 pb-2 lg:pb-0 lg:flex lg:items-center lg:relative left-4  mt-3 mb-8 lg:m-0">
            <div className=" lg:flex lg:items-center absolute right-[40%]  lg:right-0  ">
              <button
                className="secondary-purple-button transition-none  !h-[38px] !w-[99px] lg:!h-[48px] lg:!w-[134px] center !text-sm !font-medium absolute right-0 mr-5"
                onClick={action}
              >
                {actionName}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (status == "verified") {
    return <Verified />;
  }
  if (status == "toBeVerified") {
    return <ToBeVerified />;
  }
  if (status == "error") {
    return <Error />;
  }
  if (status == "adToBeVerified") {
    return <AdToBeVerified />;
  }
};
const Actualities = ({ adsNotCompleted }) => {
  const [actuPageNumber, setActuPageNumber] = useState(1);
  const [actu, setActu] = useState([]);
  const { currentUser } = useAuth();
  const { setActiveStep, setCompleteAd, completeAd } = useAd();

  const addActuAds = async () => {
    return adsNotCompleted?.map((el) => {
      return {
        name: "ad",
        propertyName: el.title,
        descriptionCallToAction:
          "Ajoutez vos conditions d'annulation, vos services proposés, si vous acceptez des animaux...",

        status: "adToBeVerified",
        actionName: "Ajouter",
        image: el.picturesUrls[0],
        action: () => {
          const completeAdData = window.localStorage.getItem(
            `COMPLETE_AD_STATE_${el?.uid}`
          );
          if (completeAdData !== null) {
            setCompleteAd({
              ...JSON.parse(completeAdData),
              uid: el.uid,
              title: el.title,
            });
          } else {
            setCompleteAd({ ...completeAd, uid: el.uid, title: el.title });
          }

          setActiveStep(9);
        },
      };
    });
  };
  const updateActu = async () => {
    const actuAds = await addActuAds();
    setActu(actuAds);
  };

  useEffect(() => {
    if (currentUser) {
      updateActu();
    }
  }, [currentUser]);

  let numberOfActuPerPage = 4;
  if (window.innerWidth < 1024) {
    numberOfActuPerPage = 2;
  }

  if (actu?.length > 0) {
    return (
      <div className="flex flex-col pt-[35px] pb-[20px] lg:pt-[70px]">
        <h2 className="text-[25px] lg:text-[32px] ">Mes actualités</h2>

        <div className="">
          {actu?.map((field, index) => {
            if (
              (actuPageNumber - 1) * numberOfActuPerPage <= index &&
              index < actuPageNumber * numberOfActuPerPage
            ) {
              return (
                <div className="" key={index}>
                  <Actuality field={field} />
                </div>
              );
            } else {
              return "";
            }
          })}

          <div className={`grid grid-cols-3 items-center gap-2`}>
            <div className="">
              {actuPageNumber > 1 && (
                <button
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setActuPageNumber(actuPageNumber - 1);
                  }}
                >
                  <IonIcon
                    icon={arrowBack}
                    className="w-7 h-7 text-main/main-100 lg:pr-2 "
                  ></IonIcon>

                  <div className="hidden lg:block">Précédent</div>
                </button>
              )}
            </div>

            <div className="center">
              <MobileStepper
                variant="dots"
                steps={Math.ceil(actu?.length / numberOfActuPerPage)}
                position="static"
                activeStep={actuPageNumber - 1}
                sx={{
                  maxWidth: 400,
                  backgroundColor: "transparent",

                  "& .MuiMobileStepper-dot": {
                    backgroundColor: "white",
                    borderColor: "var(--first-color)",
                    borderWidth: "0.1px",
                    width: "7px",
                    height: "7px",
                    margin: "2px",
                  },
                  "& .MuiMobileStepper-dotActive": {
                    backgroundColor: "var(--first-color)",
                    width: "10px",
                    height: "10px",
                    margin: "4px",
                    marginTop: "0px",
                  },
                }}
              />
            </div>

            <div className="flex justify-end">
              {actuPageNumber * numberOfActuPerPage < actu?.length && (
                <button
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setActuPageNumber(actuPageNumber + 1);
                  }}
                >
                  <div className="hidden lg:block">Suivant</div>
                  <IonIcon
                    icon={arrowForward}
                    className="w-7 h-7 text-main/main-100 pl-2"
                  ></IonIcon>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return;
  }
};
export default Actualities;
