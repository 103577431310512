import React, { useEffect } from "react";
import AuthModal from "./AuthModal";
import useAuth from "../../hooks/useAuth";

const LoginOrSubsribeContent = () => {
  const { loginSubscribeDispatch } = useAuth();
  useEffect(() => {
    const createAnAccountData = window.localStorage.getItem(
      "CREATE_AN_ACCOUNT_STATE"
    );
    if (createAnAccountData !== null) {
      loginSubscribeDispatch({
        type: "SET ALL",
        target: {
          ...JSON.parse(createAnAccountData),
          openModal: "LoginOrSubscribe",
        },
      });
    }
  }, []);

  return (
    <div className="flex flex-col justify-center h-full space-y-20">
      <div className="flex justify-center">
        <img
          src="/images/logo-dark.svg"
          alt="slotr-logo-dark"
          className=" w-[150px] h-[55px] object-cover"
        />
      </div>

      <div className="flex space-x-2 justify-center">
        <button
          className="white-button transition-none px-0"
          onClick={() =>
            loginSubscribeDispatch({
              type: "GO TO",
              to: "TravelerOrHostAccount",
            })
          }
        >
          inscription
        </button>

        <button
          className="purple-button relative left-0 "
          onClick={() => {
            loginSubscribeDispatch({ type: "GO TO", to: "Login" });
          }}
        >
          connexion
        </button>
      </div>
    </div>
  );
};

const LoginOrSubsribe = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={<LoginOrSubsribeContent />}
      isOpen={loginSubscribeState.openModal === "LoginOrSubscribe"}
      withHeader={false}
      withLogo={false}
    />
  );
};

export default LoginOrSubsribe;
