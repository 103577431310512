import React, { useState } from "react";
import CarouselSlider from "./CarouselSlider";
import { useNavigate } from "react-router-dom";
import useAd from "../hooks/useAd";
import { IonIcon } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline, people } from "ionicons/icons";
import { format } from "date-fns";

const PropertyCard = ({
  data,
  FiltersData = false,
  statusLabel = false,
  coHostLabel = false,
}) => {
  const [property, setProperty] = useState(data);
  const [dropdownState, setDropdownState] = useState(false);
  const navigate = useNavigate();
  const {
    updatePropertyFromDatabase,
    setActiveStep,
    deleteProperty,
    completeAd,
    setCompleteAd,
  } = useAd();

  const path = () => {
    if (statusLabel && !FiltersData) {
      if (data.status === "inProgress") {
        return () => {
          setActiveStep(9);
          setCompleteAd({
            ...completeAd,
            uid: property.uid,
            title: property.title,
          });
        };
      } else {
        return () => navigate(`/property/${property?.uid}/edit`);
      }
    } else {
      const numberOfTravelers =
        Number(FiltersData?.adults) + Number(FiltersData?.children);
      const checkIn =
        FiltersData?.checkIn && format(FiltersData?.checkIn, "dd-MM-yyyy");
      const checkOut =
        FiltersData?.checkOut && format(FiltersData?.checkOut, "dd-MM-yyyy");
      const arePetsAllowed = FiltersData?.arePetsAllowed;

      return () =>
        navigate(
          `/property/${property?.uid}?travelers=${numberOfTravelers}&checkIn=${checkIn}&checkOut=${checkOut}&arePetsAllowed=${arePetsAllowed}`
        );
    }
  };

  const statusList = {
    published: {
      status: "Publiée",
      statusColor: "semantic/success-100",
      actionLabel: "Masquer",
      action: () => updateStatus("masked"),
      deleteAction: () => removeProperty(),
    },
    masked: {
      status: "Masquée",
      statusColor: "semantic/break",
      actionLabel: "Publier",
      action: () => updateStatus("published"),
      deleteAction: () => removeProperty(),
    },
    inProgress: {
      status: "En cours",
      statusColor: "semantic/in-progress",
      actionLabel: "Compléter",
      action: () => {
        setActiveStep(9);
        setCompleteAd({
          ...completeAd,
          uid: property.uid,
          title: property.title,
        });
      },
      deleteAction: () => removeProperty(),
    },
  };

  const updateStatus = async (newStatus) => {
    const res = await updatePropertyFromDatabase(property?.uid, {
      status: newStatus,
    });

    if (res?._writeTime) {
      setProperty({ ...property, status: newStatus });
      setDropdownState(false);
    }
  };

  const removeProperty = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette annonce ?")) {
      const res = await deleteProperty(property.uid);

      if (res?._writeTime) {
        window.location.reload();
      }
    }
  };

  const propertyStatus = () => {
    if (property?.status === "published") {
      return statusList.published;
    } else if (property?.status === "masked") {
      return statusList.masked;
    } else if (property?.status === "inProgress") {
      return statusList.inProgress;
    }
  };

  return (
    <div className="space-y-2 lg:space-y-4 z-0 flex-none">
      <div className="relative cursor-pointer">
        <div className="w-full h-[280px] lg:h-[220px] 2xl:h-[280px]">
          <CarouselSlider
            pictures={property?.picturesUrls}
            onClickPath={path()}
            moreStyle={"rounded-lg"}
          />
        </div>

        {statusLabel && (
          <div className="absolute top-3 right-3">
            <div className="flex items-center space-x-2">
              {coHostLabel && (
                <div className="relative group">
                  <div className="rounded-full bg-[#414141] p-1.5">
                    <IonIcon
                      icon={people}
                      className="block w-5 h-5 text-white flex-none"
                    />
                  </div>

                  <div className="absolute -bottom-8 w-24 bg-[#414141] rounded-[4px] p-1 opacity-0 transition-opacity duration-300 group-hover:block group-hover:opacity-100">
                    <p className="text-xs text-white w-full text-center">
                      Co-gestion
                    </p>
                  </div>
                </div>
              )}

              <Dropdown
                data={propertyStatus()}
                state={dropdownState}
                setState={setDropdownState}
              />
            </div>
          </div>
        )}
      </div>

      <div className="space-y-0.5 lg:space-y-1.5">
        <div onClick={path()} className="cursor-pointer">
          <div className="space-y-0.5 lg:space-y-1.5">
            <p className="text-main/dark-100 text-sm lg:text-[18px] font-semibold line-clamp-1">
              {property?.title}
            </p>

            <p className="text-main/dark-100 text-sm lg:text-[16px] font-inter line-clamp-1">
              {property?.city + ", " + property?.country}
            </p>

            <ul className="flex space-x-1 text-[#98949E] text-sm font-inter">
              <li className="">
                {property?.numberOfTravelers +
                  (property?.numberOfTravelers > 1
                    ? " voyageurs"
                    : " voyageur")}
              </li>

              <li className="">•</li>

              <li className="">
                {property?.numberOfBedrooms +
                  (property?.numberOfBedrooms > 1 ? " chambres" : " chambre")}
              </li>

              <li className="">•</li>

              <li className="">
                {property?.numberOfBeds +
                  (property?.numberOfBeds > 1 ? " lits" : " lit")}
              </li>
            </ul>
          </div>
        </div>

        <div className="flex items-center space-x-4 pt-2">
          {statusLabel ? (
            <button
              className="uppercase inline bg-main/main-100 text-white rounded-full py-2 px-6 text-xs font-semibold"
              onClick={path()}
            >
              voir
            </button>
          ) : (
            <div className="inline bg-main/main-100 text-white rounded-full py-2 px-5 text-xs">
              {property?.price + "€/ NUIT"}
            </div>
          )}

          {property?.competition?.length > 0 && (
            <>
              <img
                src="/images/vs-icon.png"
                alt="vs-icon"
                className="w-8 lg:w-9 object-contain"
              />

              <div>
                {property?.competition?.map((company) => (
                  <a
                    href={company.link}
                    key={company.companyName}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="text-xs xl:text-sm text-neutral/gray/gray-100 font-inter cursor-pointer">
                      {"sur " + company.companyName}{" "}
                      <span className="font-semibold text-sm xl:text-base font-poppi">
                        {company.price + "€"}
                      </span>
                    </p>
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({ data, setState, state }) => {
  return (
    <div className="relative">
      <div className="flex flex-col space-y-2 items-center">
        <button
          className={`flex justify-center items-center rounded-full w-32 text-xs font-semibold ${
            data.status === "Publiée"
              ? "bg-semantic/success-100"
              : data.status === "Masquée"
              ? "bg-semantic/break"
              : "bg-semantic/in-progress"
          }`}
          onClick={() => setState(!state)}
        >
          <span className="uppercase text-white p-2">{data?.status}</span>

          <IonIcon
            icon={state ? chevronUpOutline : chevronDownOutline}
            className="w-6 h-6 text-white"
          ></IonIcon>
        </button>

        {state && (
          <div
            className={`flex flex-col bg-white border rounded-lg p-2 ${
              data.status === "Publiée"
                ? "border-semantic/success-100"
                : data.status === "Masquée"
                ? "border-semantic/break"
                : "border-semantic/in-progress"
            }`}
          >
            <button
              className="text-start p-2 border-b border-main/dark-20"
              onClick={data?.action}
            >
              {data?.actionLabel}
            </button>

            <button className="text-start p-2" onClick={data?.deleteAction}>
              Supprimer
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyCard;
