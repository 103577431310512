import React, { useState, useEffect } from "react";
import PropertyCard from "../components/PropertyCard";
import { API } from "aws-amplify";
import { IonIcon } from "@ionic/react";
import { optionsOutline } from "ionicons/icons";
import CircularProgress from "@mui/material/CircularProgress";
import Filter from "../components/filter/Filter";
import { BUILD_ENV, hostname } from "../App";
import SearchBar from "../components/SearchBar";
import useAd from "../hooks/useAd";

const HomePage = () => {
  const [properties, setProperties] = useState(null);
  const [propertiesLimit, setPropertiesLimit] = useState(12);
  const [nbOfChanges, setNbOfChanges] = useState(0);
  const { filtersAd, setFiltersAd, defaultFiltersAd } = useAd();

  const getProperties = async (filtersValue) => {
    try {
      const apiName = "propertiesManager";
      const path = `/properties`;
      const options = {
        queryStringParameters: {},
      };

      Object.keys(filtersValue).forEach((key) => {
        if (
          key !== "isOpen" &&
          key !== "minPrice" &&
          key !== "maxPrice" &&
          key !== "filterSubmit"
        )
          if (Array.isArray(filtersValue[key])) {
            const arrayAsString = filtersValue[key].join(",");

            options.queryStringParameters[key] = arrayAsString;
          } else {
            options.queryStringParameters[key] = filtersValue[key];
          }
      });

      let response;

      if (BUILD_ENV === "localhost") {
        const queryParams = new URLSearchParams(options.queryStringParameters);

        response = await fetch(
          `http://${hostname}:3001/properties?${queryParams.toString()}`
        );
        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      if (response) {
        const minPrice = response.filtersData?.minPrice || 0;
        const maxPrice = response.filtersData?.maxPrice || 9999;

        setFiltersAd({
          ...filtersValue,
          minPrice,
          maxPrice,
          prices: [minPrice, maxPrice],
          filterSubmit: false,
        });
        setProperties(response.properties);
        setPropertiesLimit(12);
      } else {
        throw new Error("No matching properties.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const numberOfChanges = (currentValue, defaultValue) => {
    let changes = 0;

    const newObj = {
      prices: currentValue.prices,
      entireAccomodation: currentValue.entireAccomodation,
      numberOfBeds: currentValue.numberOfBeds,
      numberOfBedrooms: currentValue.numberOfBedrooms,
      categories: currentValue.categories,
      services: currentValue.services,
    };

    Object.keys(newObj).forEach((key) => {
      if (key === "prices") {
        if (
          newObj.prices[0] !== currentValue.minPrice ||
          newObj.prices[1] !== currentValue.maxPrice
        ) {
          changes++;
        }
        return;
      }

      if (key === "services" || key === "categories") {
        if (newObj[key].length > 0) {
          changes++;
        }
        return;
      }

      if (
        defaultValue.hasOwnProperty(key) &&
        newObj[key] !== defaultValue[key]
      ) {
        changes++;
      }
    });

    setNbOfChanges(changes);
  };

  useEffect(() => {
    getProperties(defaultFiltersAd);
  }, []);

  useEffect(() => {
    if (filtersAd.filterSubmit) {
      getProperties(filtersAd);
      numberOfChanges(filtersAd, defaultFiltersAd);
    }
  }, [filtersAd.filterSubmit]);

  return (
    <>
      {filtersAd.isOpen && <Filter />}

      <main className="flex flex-col w-full space-y-6 grow">
        <section className="flex bg-hero bg-cover w-full px-6 md:px-10 lg:px-16 py-14 items-center">
          <div className="space-y-6 max-w-4xl mx-auto">
            <h1 className="!text-[26px] lg:!text-[32px] text-center text-white">
              Votre nouvelle alternative française pour une location facile,
              flexible et equitable.
            </h1>

            <div className="md:p-2 md:bg-white rounded-r-full">
              <SearchBar />
            </div>
          </div>
        </section>

        <section className="flex flex-col space-y-3 max-w-bigScreen mx-auto px-6 md:px-10 lg:px-16 grow w-full">
          <div className="flex space-x-2 items-center">
            <h5>
              Vous économisez en moyenne 15% par nuit sur un logement en
              réservant en direct sur SlotR !
            </h5>

            <img
              src="/images/stickers-icon.svg"
              alt="stickers-icon"
              className="w-10 object-contain flex-none"
            />
          </div>

          <div className="flex flex-col items-start space-y-8 grow">
            <div className="relative">
              <button
                className="flex border-2 border-main/main-100 rounded-full px-6 py-2 space-x-2 items-center cursor-pointer"
                onClick={() =>
                  setFiltersAd({
                    ...filtersAd,
                    isOpen: true,
                  })
                }
              >
                <p className="text-sm uppercase font-medium text-main/main-100 leading-none">
                  Filtres
                </p>

                <IonIcon
                  icon={optionsOutline}
                  className="w-5 h-5 text-main/main-100"
                ></IonIcon>
              </button>

              {nbOfChanges > 0 && (
                <div className="flex absolute -top-0.5 -right-1 justify-center items-center rounded-full p-2 w-[18px] h-[18px] bg-main/main-100">
                  <span className="text-white text-sm leading-none font-semibold">
                    {nbOfChanges}
                  </span>
                </div>
              )}
            </div>

            {!properties ? (
              <div className="flex w-full grow justify-center items-center">
                <CircularProgress size={60} thickness={4} />
              </div>
            ) : properties?.length === 0 ? (
              <div className="flex w-full grow justify-center items-center">
                <p className="text-3xl text-center">Aucun élément à afficher</p>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 sm:gap-x-8 lg:gap-x-10 gap-y-8 lg:gap-y-10">
                  {properties.slice(0, propertiesLimit)?.map((property) => (
                    <PropertyCard
                      key={property?.uid}
                      data={property}
                      FiltersData={filtersAd}
                    />
                  ))}
                </div>

                {propertiesLimit < properties?.length && (
                  <div
                    className="pt-10 lg:pt-16 flex justify-center"
                    onClick={() => setPropertiesLimit(propertiesLimit + 12)}
                  >
                    <button className="purple-button !w-[140px] lg:!text-sm lg:!h-[48px] lg:!w-[180px]">
                      AFFICHER PLUS
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;
