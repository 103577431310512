import React from "react";
import AdModal from "../ad/AdModal";
import NextButton from "../ad/NextButton";
import useAd from "../../hooks/useAd";
import ServiceAndCategoryCard, {
  listOfServices,
} from "../../components/ServiceAndCategoryCard";

const ServicesContent = () => {
  const {
    handleNext,
    completeAd,
    setCompleteAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { services } = completeAd;
  const isValidate = services.length > 0;

  const handleServiceChange = (name) => {
    let newServices = [...services];

    if (newServices.includes(name)) {
      newServices = newServices.filter((service) => service !== name);
    } else {
      newServices.push(name);
    }

    setCompleteAd({ ...completeAd, services: newServices });
  };

  const validate = async () => {
    if (isValidate) {
      if (!isEdit) {
        handleNext();
      } else {
        if (editAd.services !== services) {
          const newValues = {
            services,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    }
  };

  return (
    <>
      <div className="space-y-6">
        <h4 className=" px-6 text-center">Ajoutez les équipements inclus</h4>

        <div className="center">
          <div className="grid grid-cols-3 gap-x-2 gap-y-6 items-start">
            {listOfServices.map(({ name }) => (
              <button key={name} onClick={() => handleServiceChange(name)}>
                <ServiceAndCategoryCard
                  name={name}
                  type={"service"}
                  isActive={services.includes(name)}
                />
              </button>
            ))}
          </div>
        </div>

        {!isEdit ? (
          <div className="flex w-full justify-end pt-6">
            <NextButton handleClick={validate} isDisabled={!isValidate} />
          </div>
        ) : (
          <div className="flex w-full justify-center pt-10">
            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
              disabled={!isValidate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const Services = ({}) => {
  return (
    <AdModal
      children={<ServicesContent />}
      stepNumber={9}
      height="900px"
      withMdScrolling={true}
      withHeader={false}
    />
  );
};
export default Services;
