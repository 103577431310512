import React, { useState, useEffect, useRef } from "react";
import { IonIcon } from "@ionic/react";
import { globeOutline, locationOutline } from "ionicons/icons";
import { geocodingPlace } from "../utils/maps";

function PlacesAutocomplete({ state, setState }) {
  const wrapperRef = useRef(null);
  const [predictions, setPredictions] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (state.destination && window.google && isInputFocused) {
      let autocompleteService =
        new window.google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        {
          input: state.destination,
          componentRestrictions: { country: "FR" },
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setPredictions(predictions);
          } else {
            setPredictions(null);
          }
        }
      );
    } else {
      setPredictions(null);
    }
  }, [state.destination]);

  const handlePlaceChanged = async (place) => {
    if (place) {
      setPredictions(null);
      setIsInputFocused(false);

      let coordinates = null;

      if (place.description !== "France") {
        coordinates = await geocodingPlace("placeId", place.place_id);
      }

      setState({
        ...state,
        placeId: place.place_id,
        destination: place.description,
        lat: coordinates?.lat || "",
        lng: coordinates?.lng || "",
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <div
        className={`flex w-full items-center px-4 py-3 space-x-2 bg-white border  overflow-hidden ${
          isInputFocused
            ? "border-main/main-100"
            : "border-neutral/gray/gray-40"
        }`}
      >
        <input
          className="w-full text-sm outline-none placeholder:text-main/dark-100 bg-white overflow-hidden whitespace-nowrap text-ellipsis"
          placeholder="Destination"
          value={state?.destination}
          onFocus={() => setIsInputFocused(true)}
          onChange={(e) => setState({ ...state, destination: e.target.value })}
          type="text"
        />

        <IonIcon
          icon={globeOutline}
          className="w-6 h-6 flex-none text-neutral/gray/gray-60"
        ></IonIcon>
      </div>

      {predictions && isInputFocused && (
        <div className="absolute top-16 left-0 z-30 bg-white rounded-[4px] border border-neutral/gray/gray-40 px-2 min-w-full">
          {predictions?.map((place) => (
            <div
              key={place?.place_id}
              className="flex space-x-2 text-start items-start w-full px-2 py-4 hover:bg-main/main-100 text-main/dark-100 hover:text-white cursor-pointer"
              onClick={() => handlePlaceChanged(place)}
            >
              <IonIcon
                icon={locationOutline}
                className="w-6 h-6 flex-none text-start"
              ></IonIcon>

              <p className="capitalize">{place?.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default PlacesAutocomplete;
