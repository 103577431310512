import React, { useState } from "react";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import { TextField } from "@mui/material";
import NextButton from "./NextButton";

const TitleContent = () => {
  const {
    handleNext,
    startAd,
    handleChange,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { title, description } = startAd;
  const isValidate = title && description;

  const validate = async () => {
    if (isValidate) {
      if (!isEdit) {
        handleNext();
      } else {
        if (editAd.title !== title || editAd.description !== description) {
          const newValues = {
            title,
            description,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    }
  };

  return (
    <>
      <div className="space-y-6 md:px-4">
        <div className="space-y-6 ">
          <h4 className="text-center px-10">Donnez un titre à votre annonce</h4>

          <TextField
            value={title}
            label="Titre"
            helperText={`${title.length}/50 caractères`}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "var(--first-color)",
                },
              },
              "& .MuiFormLabel-root": {
                "&.Mui-focused": {
                  color: "var(--first-color)",
                },
              },
            }}
            onChange={(event) => handleChange(event, "title")}
            fullWidth
            InputProps={{
              type: "email",
            }}
            inputProps={{ maxLength: 50 }}
          />

          <div className="center grid grid-cols-2 space-x-2 customedScrollNoPadding">
            <TextField
              value={description}
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={8}
              label="Description"
              helperText={`${description.length}/10000 caractères`}
              onChange={(event) => handleChange(event, "description")}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--first-color)",
                  },
                },
                "& .MuiFormLabel-root": {
                  "&.Mui-focused": {
                    color: "var(--first-color)",
                  },
                },
              }}
              inputProps={{
                maxLength: 10000,
                sx: {
                  paddingRight: 8,
                },
              }}
            />
          </div>
        </div>

        {!isEdit ? (
          <div className="flex w-full justify-end pt-6">
            <NextButton handleClick={validate} isDisabled={!isValidate} />
          </div>
        ) : (
          <div className="flex w-full justify-center pt-10">
            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
              disabled={!isValidate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const Title = ({}) => {
  return <AdModal children={<TitleContent />} stepNumber={2} height="766px" />;
};
export default Title;
