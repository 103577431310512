import { Link } from "react-router-dom";
export default function HostNavBar({ focused }) {
  return (
    <div className="hidden lg:block relative">
      <div className=" h-16 px-16 py-4 text-xl center gap-20 xl:gap-36  text-black bg-neutral/gray/gray-10 border-color-neutral/gray/gray-20 border-[1px] ">
        <Link to={`/dashboard`}>
          <button
            className={`${focused == "dashboard" ? "font-bold" : ""} z-60`}
          >
            Mon tableau de bord
          </button>
        </Link>

        <Link to={`/bookings`}>
          <button className={`${focused == "bookings" ? "font-bold" : ""}`}>
            Mes réservations
          </button>
        </Link>

        <Link to={`/properties`}>
          <button className={`${focused == "properties" ? "font-bold" : ""}`}>
            Mes annonces
          </button>
        </Link>

        <Link to={`/profile`}>
          <button className={`${focused == "profile" ? "font-bold" : ""}`}>
            Mon profil
          </button>
        </Link>

        <div className="absolute bottom-0 left-36 z-0">
          <img
            className=" lg:w-[34px] lg:h-[34px]"
            src="/images/stickers/sticker-star.svg"
            alt="sticker green"
          />
        </div>
      </div>
    </div>
  );
}
