import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const listOfCountries = [
  "Allemagne",
  "Autriche",
  "Belgique",
  "Bulgarie",
  "Chypre",
  "Croatie",
  "Danemark",
  "Espagne",
  "Estonie",
  "Finlande",
  "France",
  "Grèce",
  "Hongrie",
  "Irlande",
  "Italie",
  "Lettonie",
  "Lituanie",
  "Luxembourg",
  "Malte",
  "Pays-Bas",
  "Pologne",
  "Portugal",
  "République tchèque",
  "Roumanie",
  "Slovaquie",
  "Slovénie",
  "Suède",
];

const SelectCountry = ({ handleChange, state = "France", error }) => {
  const [countries, setCountries] = useState(listOfCountries.sort());

  return (
    <FormControl fullWidth>
      <InputLabel color={"primary"} error={error} id="demo-simple-select-label">
        Pays
      </InputLabel>

      <Select
        error={error}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={state.country}
        label="Pays"
        onChange={handleChange}
        sx={{
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--first-color)",
          },
          "&.MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
            height: "100px",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,
            },
          },
        }}
        inputProps={{
          autoComplete: "country-name",
        }}
      >
        {countries?.map((country) => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCountry;
