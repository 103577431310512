import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { BUILD_ENV, hostname } from "../App";
import { API } from "aws-amplify";
import { IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

const StripeResponse = () => {
  const navigate = useNavigate();
  const { propertyUid, status } = useParams();
  const bookingData = JSON.parse(window.localStorage.getItem("bookingData"));
  const [error, setError] = useState();

  useEffect(() => {
    const addNightInBlockchain = async () => {
      try {
        const { propertyUid, walletAddress, checkIn, checkOut } = bookingData;

        if (!walletAddress) {
          throw {
            error: "User not validated",
          };
        }

        const apiName = "web3Manager";
        const path = `/newNight`;

        let options = {
          queryStringParameters: {
            propertyUid: propertyUid,
            userAddress: walletAddress,
            startDate: checkIn,
            endDate: checkOut,
          },
        };

        let data;
        if (BUILD_ENV === "localhost") {
          const queryParams = `propertyUid=${propertyUid}&userAddress=${walletAddress}&startDate=${checkIn}&endDate=${checkOut}`;

          data = await fetch(`http://${hostname}:3004${path}?${queryParams}`, {
            method: "POST",
          });
          data = await data.json();
        } else {
          data = await API.post(apiName, path, options);
        }

        if (data.msg) {
          console.log(data.msg);
        }

        return data;
      } catch (error) {
        console.log(error);
      }
    };

    const updateBookingStatus = async (status) => {
      try {
        const apiName = "bookingsManager";
        const path = `/bookings/${bookingData?.bookingUid}`;
        let params = {
          status: status,
        };

        let options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        let data;

        if (BUILD_ENV === "localhost") {
          options["method"] = "PUT";
          options["body"] = JSON.stringify(params);

          data = await fetch(`http://${hostname}:3003${path}`, options);
        } else {
          options["body"] = params;
          data = await API.put(apiName, path, options);
        }

        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    const deleteDate = async (dateUid) => {
      try {
        const apiName = "bookingsManager";
        const path = `/dates/${dateUid}`;
        let data;

        if (BUILD_ENV === "localhost") {
          let options = {
            method: "DELETE",
          };

          data = await fetch(`http://${hostname}:3003${path}`, options);
        } else {
          data = await API.del(apiName, path);
        }
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    const getPaymentState = async () => {
      if (status === "success") {
        addNightInBlockchain();
        await updateBookingStatus("confirmed");
        navigate(`/booking-summary`, {
          state: { transactionUid: bookingData?.transactionUid },
        });
      }

      if (status === "cancel") {
        await updateBookingStatus("cancelled");
        await deleteDate(bookingData?.dateUid);
        await deleteDate(bookingData?.dateBeforeBookingUid);
        await deleteDate(bookingData?.dateAfterBookingUid);

        setError(true);
      }

      window.localStorage.removeItem("bookingData");
    };

    if (propertyUid && status && bookingData) {
      getPaymentState();
    } else {
      navigate("/error");
    }
  }, [propertyUid, status]);

  return (
    <div className="flex w-full grow justify-center items-center">
      {error ? (
        <div className="flex flex-col items-center">
          <IonIcon
            icon={closeCircleOutline}
            className="w-16 h-16 text-semantic/red-100"
          ></IonIcon>

          <div className="text-center space-y-1 pt-5 pb-10">
            <h1 className="text-semantic/red-100">Votre paiement a échoué</h1>

            <p>
              Votre réservation n’est pas confirmée et aucun montant n’a été
              débité.
              <br />
              Nous vous invitons à recommencer votre réservation.
            </p>
          </div>

          <Link to={`/property/${propertyUid}`}>
            <button className="purple-button uppercase">voir l’annonce</button>
          </Link>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-12">
          <CircularProgress size={60} thickness={4} />

          <h2 className="text-Design-system/paragraph">
            {"Votre paiement est en cours de traitement"}
          </h2>
        </div>
      )}
    </div>
  );
};

export default StripeResponse;
