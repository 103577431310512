import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline, closeOutline } from "ionicons/icons";

const GenericModal = ({
  children,
  isOpen,
  handleBack,
  handleClose,
  width = "480px",
  height = "512px",
  withHeader = true,
  withLogo = true,
  withMdScrolling = false,
}) => {
  const Header = () => {
    return (
      <div className="grid grid-cols-3 gap-2">
        <div className="relative">
          {withHeader && (
            <button
              onClick={handleBack}
              className={`text-dark  bg-white font-poppins lowercase font-normal text-sm shadow-none hover:shadow-none space-x-4 p-0 absolute left-0 rounded-lg`}
            >
              <IonIcon
                icon={arrowBackOutline}
                className="w-6 h-6 text-main/dark-100"
              />
            </button>
          )}
        </div>

        <div className="center">
          {withLogo && (
            <img
              src="/images/logo-dark.svg"
              alt="slotr-logo-dark"
              className="w-[100px] "
            />
          )}
        </div>

        <div className="flex items-center relative cursor-pointer">
          <button
            className=" text-dark  bg-white rounded-lg font-poppins lowercase font-normal text-sm shadow-none hover:shadow-none space-x-4 p-0 absolute top-0 right-0"
            onClick={handleClose}
          >
            <IonIcon
              icon={closeOutline}
              className="w-8 h-8 text-main/dark-100 "
            />
          </button>
        </div>
      </div>
    );
  };

  //------------------------------------------------------------------------------------------------------------------
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`${
        withMdScrolling
          ? "justify-center flex items-center customedScroll bg-main/dark-70"
          : "justify-center flex items-center  bg-main/dark-70"
      }`}
      sx={{ outline: "none" }}
    >
      <Box
        className={`absolute h-full w-full z-50 bg-white md:border-white md:rounded-xl`}
        sx={{
          outline: "none",
          "@media (min-width:768px)": {
            maxWidth: width,
            maxHeight: height,
          },
        }}
      >
        <div
          className={`flex flex-col h-full overflow-y-auto p-8 ${
            !withMdScrolling && "scrollbar-hide"
          }`}
        >
          <Header />

          {children}
        </div>
      </Box>
    </Modal>
  );
};

export default GenericModal;
