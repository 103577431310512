import React, { useRef, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useAd from "../../hooks/useAd";
import imageCompression from "browser-image-compression";
import AdModal from "./AdModal";
import useAuth from "../../hooks/useAuth";
import { trashOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

const PicturesContent = () => {
  const {
    setStartAd,
    startAd,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
    uploadPicturesToS3,
  } = useAd();
  const { currentUser } = useAuth();
  const { picturesFiles, picturesUrls } = startAd;
  const isValidate = picturesUrls.length >= 3;
  const maxFileSize = 10;
  const maxNbOfImages = 50;

  async function handleImageUpload(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(image, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  async function handleChange(e) {
    const images = e.target.files;
    const nbImagesAlreadySelected = picturesFiles.length;

    if (
      nbImagesAlreadySelected < maxNbOfImages &&
      images.length <= maxNbOfImages &&
      nbImagesAlreadySelected + images.length <= maxNbOfImages
    ) {
      let newPicturesUrls = [...picturesUrls];
      let newPicturesFiles = [...picturesFiles];

      for (let index = 0; index < images.length; index++) {
        const image = images[index];
        const fileType = image?.type;
        const allowedExtension = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
        ];
        const fileSize = (image?.size / 1024 / 1024).toFixed(2);

        if (allowedExtension.indexOf(fileType) > -1 && fileSize < maxFileSize) {
          try {
            const compressedFile = await handleImageUpload(image);
            compressedFile["blob"] = URL.createObjectURL(image);

            newPicturesUrls.push(compressedFile?.blob);
            newPicturesFiles.push(compressedFile);
          } catch (e) {
            console.log(e);
          }
        } else {
          alert(
            `Seuls les fichiers aux formats .png, .jpg, .jpeg et .webp, d'une taille maximale de ${maxFileSize} Mo, sont acceptés`
          );
        }
      }

      if (newPicturesFiles.length > nbImagesAlreadySelected) {
        setStartAd({
          ...startAd,
          picturesUrls: newPicturesUrls,
          picturesFiles: newPicturesFiles,
        });
      }
    } else {
      alert(`Vous ne pouvez pas ajouter plus de ${maxNbOfImages} photos`);
    }
  }

  const moveImage = (dragIndex, hoverIndex) => {
    const dragImage = picturesUrls[dragIndex];

    const newPicturesBlob = [...picturesUrls];
    newPicturesBlob.splice(dragIndex, 1);
    newPicturesBlob.splice(hoverIndex, 0, dragImage);

    setStartAd({
      ...startAd,
      picturesUrls: newPicturesBlob,
    });
  };

  const validate = async () => {
    if (isValidate) {
      if (editAd.picturesUrls !== picturesUrls) {
        await Promise.all(
          picturesFiles?.map(async (picture) => {
            const blob = picture.blob;

            const picturesUrl = await uploadPicturesToS3(
              currentUser?.uid,
              picture
            );

            picturesUrls?.forEach((url, index, arr) => {
              if (url === blob) {
                arr[index] = picturesUrl;
              }
            });

            return picturesUrl;
          })
        );

        const newValues = {
          picturesUrls,
        };

        const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

        if (res) {
          setEditAd({
            ...editAd,
            ...newValues,
          });
        }
      }

      close();
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6 lg:px-16 max-w-smallScreen mx-auto">
        <div className="space-y-6">
          <h1>Galerie</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-10 md:gap-y-6 md:gap-x-8 lg:gap-x-10 w-full">
            {picturesUrls?.map((image, index) => (
              <DraggableImage
                key={image}
                id={image}
                url={image}
                index={index}
                moveImage={moveImage}
              />
            ))}

            <div className="flex justify-center items-center w-full h-[300px]">
              <label
                htmlFor="file-upload"
                className="custom-file-upload w-1/2 h-1/2"
              >
                <img
                  src="/images/addPictureNoHeart.svg"
                  alt="next arrow"
                  className="w-full h-full object-cover inline-block rounded-lg cursor-pointer"
                />
              </label>

              <input
                type="file"
                id="file-upload"
                className="hidden"
                onChange={handleChange}
                accept=".jpg, .png, .jpeg, .webp"
                name="fileInput"
                multiple
              />
            </div>
          </div>
        </div>

        <div className="flex w-full justify-center pt-10">
          <button
            className="purple-button center relative left-0 !w-[111px]"
            onClick={validate}
            disabled={!isValidate}
          >
            VALIDER
          </button>
        </div>
      </div>
    </DndProvider>
  );
};

const Pictures = () => {
  return (
    <AdModal
      children={<PicturesContent />}
      stepNumber={19}
      height="80%"
      width="80%"
      withMdScrolling={true}
    />
  );
};

const DraggableImage = ({ id, url, index, moveImage }) => {
  const { setStartAd, startAd } = useAd();
  const { picturesFiles, picturesUrls } = startAd;
  const ref = useRef(null);
  const ItemType = "image";

  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  drag(drop(ref));

  const handleDelete = (image) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette image ?")) {
      setStartAd({
        ...startAd,
        picturesUrls: picturesUrls.filter((img) => img != image),
        picturesFiles: picturesFiles.filter((img) => img.blob != image),
      });
    }
  };

  return (
    <div
      ref={ref}
      className="relative w-full h-[200px] lg:h-[300px] cursor-grabbing"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <img className="col-span-1 object-cover w-full h-full" src={url} />

      <div className="absolute z-40 right-4 top-4">
        <button
          onClick={() => handleDelete(url)}
          className="rounded-full p-1.5 bg-main/main-100 justify-center items-center flex"
        >
          <IonIcon
            icon={trashOutline}
            className="w-5 h-5 text-white rounded-ful"
          />
        </button>
      </div>
    </div>
  );
};

export default Pictures;
