import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";

export default function CheckboxUserConditions({
  userConditionsError,
  userConditionsState,
  loginSubscribeDispatch,
  children,
}) {
  const handleClick = () => {
    loginSubscribeDispatch({
      type: "SET",
      kind: "USER CONDITIONS",
      target: !userConditionsState,
    });
  };

  return (
    <FormControl error={userConditionsError} required>
      <FormControlLabel
        sx={{ marginLeft: 0, width: "100%", alignItems: "start" }}
        control={
          <Checkbox
            size="small"
            color="default"
            checked={userConditionsState}
            onChange={handleClick}
            sx={{
              paddingRight: "10px",
              paddingLeft: 0,
              paddingTop: "2px",
              paddingBottom: 0,
              "& .MuiSvgIcon-root": { fontSize: 22 },
            }}
          />
        }
        label={
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "var(--second-color)",
              },
              color: "var(--second-color)",
              fontSize: "14px",
            }}
          >
            {children}
          </FormLabel>
        }
      />
    </FormControl>
  );
}
