import React from "react";
import AdModal from "../ad/AdModal";
import useAd from "../../hooks/useAd";
import NextButton from "../ad/NextButton";

const PetsContent = () => {
  const {
    handleNext,
    completeAd,
    setCompleteAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { arePetsAllowed } = completeAd;
  const isValidate = arePetsAllowed !== "";

  const Option = ({ title, yesOrNo }) => {
    return (
      <div className="flex items-center">
        <label
          className="relative flex cursor-pointer items-center rounded-full p-3"
          htmlFor={yesOrNo.toString()}
          data-ripple-dark="true"
        >
          <input
            id={yesOrNo.toString()}
            name="type"
            type="radio"
            checked={
              arePetsAllowed !== "" &&
              (yesOrNo ? arePetsAllowed : !arePetsAllowed)
            }
            onChange={() => {
              if (arePetsAllowed === "") {
                if (yesOrNo) {
                  setCompleteAd({
                    ...completeAd,
                    arePetsAllowed: true,
                  });
                } else {
                  setCompleteAd({
                    ...completeAd,
                    arePetsAllowed: false,
                  });
                }
              } else {
                setCompleteAd({
                  ...completeAd,
                  arePetsAllowed: !arePetsAllowed,
                });
              }
            }}
            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-main/main-100 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-main/main-100 checked:before:bg-main/main-100 hover:before:opacity-10"
          />
          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-main/main-100 opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </div>
        </label>
        <label
          className="mt-px cursor-pointer select-none font-light text-sm"
          htmlFor="html"
        >
          {title}
        </label>
      </div>
    );
  };
  const RadioButtonsGroup = () => {
    return (
      <div className="center pt-5">
        <div className="grid grid-col-1 ">
          <Option title="Oui" yesOrNo={true} />
          <Option title="Non" yesOrNo={false} />
        </div>
      </div>
    );
  };

  const validate = async () => {
    if (isValidate) {
      if (!isEdit) {
        handleNext();
      } else {
        if (editAd.arePetsAllowed !== arePetsAllowed) {
          const newValues = {
            arePetsAllowed,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    }
  };

  return (
    <>
      <div className="space-y-6">
        <h4 className=" text-center">
          Acceptez-vous les animaux de compagnie ?
        </h4>

        <RadioButtonsGroup />

        {!isEdit ? (
          <div className="flex w-full justify-end pt-6">
            <NextButton onClick={validate} isDisabled={!isValidate} />
          </div>
        ) : (
          <div className="flex w-full justify-center pt-10">
            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const Pets = ({}) => {
  return <AdModal children={<PetsContent />} stepNumber={12} height="530px" />;
};
export default Pets;
