import React from "react";
import useAd from "../hooks/useAd";
import { IonIcon } from "@ionic/react";
import { optionsOutline, arrowBackOutline } from "ionicons/icons";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const MobileSearchBar = ({ nbOfChanges }) => {
  const navigate = useNavigate();
  const { filtersAd, setFiltersAd } = useAd();
  const destinationPart = filtersAd?.destination.split(",")[0].trim();

  const TravelersText = () => {
    const adultsAndChildren =
      parseInt(filtersAd?.adults) + parseInt(filtersAd?.children);
    const babies = parseInt(filtersAd?.babies);

    const travelersText = `${adultsAndChildren} ${
      adultsAndChildren === 1 ? "voyageur" : "voyageurs"
    }`;
    const babiesText = `${babies} ${babies === 1 ? "bébé" : "bébés"}`;

    return (
      <p
        className="text-sm  line-clamp-1
       text-design-system/highlight"
      >
        {travelersText + (babies > 0 ? ", " + babiesText : "")}
      </p>
    );
  };

  return (
    <div className="flex space-x-4 items-center w-full z-50">
      <button
        className="flex p-2 rounded-full bg-main/main-100/10 flex-none"
        onClick={() => navigate(-1)}
      >
        <IonIcon
          icon={arrowBackOutline}
          className="w-5 h-5 text-main/dark-100 flex-none"
        ></IonIcon>
      </button>

      <div
        className="lg:hidden flex justify-between items-center space-x-2 bg-neutral/gray/gray-10 rounded-full px-4 py-2 w-full"
        onClick={() => setFiltersAd({ ...filtersAd, isOpen: true })}
      >
        <div className="flex space-x-2 text-sm">
          <p className="font-semibold line-clamp-1">
            {destinationPart ? destinationPart : "Destination"}
          </p>

          <span>-</span>

          <p className="text-design-system/highlight line-clamp-1">
            {filtersAd?.checkIn && filtersAd?.checkOut
              ? format(new Date(filtersAd?.checkIn), "dd MMMM") +
                "-" +
                format(new Date(filtersAd?.checkOut), "dd MMMM")
              : "Dates"}
          </p>
        </div>

        <div className="relative flex rounded-full p-1.5 bg-main/main-100/10">
          <IonIcon
            icon={optionsOutline}
            className="w-6 h-6 text-main/main-100 flex-none"
          ></IonIcon>

          {nbOfChanges > 0 && (
            <div className="absolute -top-0.5 -right-2  flex justify-center items-center rounded-full p-2 w-[18px] h-[18px] bg-main/main-100">
              <span className="text-white text-sm leading-none font-semibold">
                {nbOfChanges}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileSearchBar;
