import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useAd from "../../hooks/useAd";
import GenericModal from "../Modal";

const AdModal = ({
  children,
  width = "480px",
  height = "512px",
  withHeader = true,
  stepNumber,
  withMdScrolling = false,
}) => {
  let { handleBack, close, activeStep, isEdit } = useAd();
  let isOpen = activeStep === stepNumber;

  const heightNumber = Number(height.split("px")[0]);

  let modalHeight;
  const { windowHeight } = useWindowDimensions();

  if (0.9 * windowHeight < heightNumber) {
    modalHeight = 0.9 * windowHeight;
    withMdScrolling = true;
  } else {
    modalHeight = height;
  }

  const Stepper = (children) => {
    const withStepperFooter =
      activeStep < 7 || (activeStep < 16 && activeStep > 8);

    let path = `/images/nav-dots/img_nav-dots (${activeStep}).svg`;

    if (activeStep < 16 && activeStep > 8) {
      path = `/images/nav-dots/img_nav-dots (${activeStep - 9}).svg`;
    }

    return (
      <div className="flex flex-col justify-between grow">
        <div className={`px-2 py-16 h-full`}>{children}</div>

        {withStepperFooter && !isEdit && (
          <footer className="center pb-2">
            <img
              src={path}
              alt="slotr-steps"
              className="w-[100px] object-contain "
            />
          </footer>
        )}
      </div>
    );
  };

  return (
    <GenericModal
      children={Stepper(children)}
      isOpen={isOpen}
      handleClose={close}
      handleBack={handleBack}
      width={width}
      height={modalHeight}
      withHeader={isEdit ? false : withHeader}
      withMdScrolling={withMdScrolling}
    />
  );
};

export default AdModal;
