import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import HostNavBar from "../components/HostNavBar";
import AdModal from "../components/ad/CreateAnAdModal";
import useAd from "../hooks/useAd";
import Calendar from "../components/dashboard/calendar/Calendar";
import Actualities from "../components/dashboard/Actualities";
import SelectProperties from "../components/dashboard/SelectProperties";
import GenericModal from "../components/Modal";
import CompleteAnAdModal from "../components/complete-ad/CompleteAnAdModal";
import useAuth from "../hooks/useAuth";
import useBooking from "../hooks/useBooking";
import useWindowsSize from "../hooks/useWindowsSize";
import { subDays } from "date-fns";

function initialiseVariables(properties) {
  const colors = [
    {
      yellow: {
        inactive: "#F1E5C3",
        active: "#FFB000",
      },
    },
    {
      purple: {
        inactive: "#A4A7C9",
        active: "#8A55FB",
      },
    },
    {
      pink: {
        inactive: "#ECCACA",
        active: "#FB72BC",
      },
    },
    {
      green: {
        inactive: "#D1DED0",
        active: "#609A5E",
      },
    },
    {
      blue: {
        inactive: "#AED7D5",
        active: "#819D9B",
      },
    },
  ];

  const propertiesId = Object.keys(properties);
  const numberOfColors = colors.length;
  const colorsActive = colors.reduce((a, b) => {
    return a.concat(Object.values(b)[0]["active"]);
  }, []);
  const colorsInactive = colors.reduce((a, b) => {
    return a.concat(Object.values(b)[0]["inactive"]);
  }, []);
  const showBookingsDefaultStateTrue = propertiesId.reduce((a, b) => {
    return { ...a, [b]: true };
  }, {});
  const showBookingsDefaultStateFalse = propertiesId.reduce((a, b) => {
    return { ...a, [b]: false };
  }, {});

  return {
    showBookingsDefaultStateFalse,
    showBookingsDefaultStateTrue,
    colorsActive,
    colorsInactive,
    properties,
    propertiesId,
    numberOfColors,
  };
}

function colorEvents(
  data,
  propertiesId,
  colorsActive,
  colorsInactive,
  numberOfColors
) {
  return data?.map((element) => {
    const i = propertiesId?.indexOf(element.propertyId);
    let color;

    if (element.type === "booking") {
      color = colorsActive[i % numberOfColors];
    } else {
      color = colorsInactive[i % numberOfColors];
    }
    return {
      ...element,
      color: color,
      textColor: "black",
    };
  });
}

function initialiseDataEvents(datesArray) {
  function setTitle(type) {
    switch (type) {
      case "blocked-dates":
        return "Dates bloquées";
      case "booking":
        return "Réservation";
      default:
        return "Évènement externe";
    }
  }

  let newDataEvents = [];

  datesArray?.forEach((event) => {
    if (event.type !== "price") {
      newDataEvents.push({
        propertyId: event.propertyUid,
        uid: event.uid,
        title: setTitle(event.type),
        start: new Date(event.startDate),
        end: subDays(new Date(event.endDate), 1),
        type: event.type,
        source: event.source,
      });
    }
  });

  return newDataEvents;
}

function filterEvents(events, showBookings) {
  return events?.filter((element) => {
    if (showBookings[element?.propertyId]) {
      return true;
    }
  });
}
const Dashboard = () => {
  /*
   dataEvents : an array of all the events to print in the calendar
   coloredEvents: an array of all the events with colors of the corresponding property
   filteredEvents : an array of the colored events that are supposed to be shown on the dashboard dependaing on the properties selected and if the imported calendar is checked
   eventsHelper: an object to help transfer data accross components
   showBookings: an object with keys the propertyId and value true or false depending on if the property is selected
   menuModalIsOpen: a bool that tracks when the calendar menu is open in small screen
   */
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dataEvents, setDataEvents] = useState([]);
  const [coloredEvents, setColoredEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [eventsHelper, setEventsHelper] = useState({});
  const [showBookings, setShowBookings] = useState([]);
  const [properties, setProperties] = useState({});
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);
  const [adsNotCompleted, setAdsNotCompleted] = useState(null);
  const [datesArray, setDatesArray] = useState([]);
  const { setActiveStep, setStartAd, startAd, getPropertiesOfAnHost } = useAd();
  const { getDatesFromDatabase } = useBooking();

  /* Get ads completed and not completed */
  useEffect(() => {
    const getPropertiesData = async () => {
      try {
        const data = await getPropertiesOfAnHost(currentUser?.uid);

        if (data) {
          const propertiesInProgress = [];
          const propertiesPublished = {};

          data.forEach((property) => {
            if (property.status === "inProgress") {
              propertiesInProgress.push(property);
            } else if (property.status === "published") {
              propertiesPublished[property.uid] = property.title;
            }
          });

          setProperties(propertiesPublished);
          setAdsNotCompleted(propertiesInProgress);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentUser) {
      getPropertiesData();
    }
  }, [currentUser]);

  /* Get all dates */
  useEffect(() => {
    const getDatesOfAProperty = async () => {
      try {
        const propertiesId = Object.keys(properties);
        let data = await getDatesFromDatabase("propertyUid", propertiesId);

       
        if (data) {
          setDatesArray(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentUser && JSON.stringify(properties) !== "{}") {
      getDatesOfAProperty();
    }
  }, [currentUser, properties]);

  /*
   stores the ad state in the browser storage so taht it could be retreive if the modal is closed
*/
  useEffect(() => {
    const createAnAdData = window.localStorage.getItem(
      `CREATE_AN_AD_STATE_${currentUser?.uid}`
    );
    if (createAnAdData !== null)
      setStartAd({
        ...startAd,
        ...JSON.parse(createAnAdData),
        picturesFiles: [],
        picturesUrls: [],
      });
  }, []);

  /*
   creates the dataEvents and datesArray from the table dates
*/
  useEffect(() => {
    setDataEvents(initialiseDataEvents(datesArray));
  }, [datesArray]);

  /*
   initialize showBookings and eventsHelper
*/
  useEffect(() => {
    if (properties) {
      const initialisedVariables = initialiseVariables(properties);
      setShowBookings(initialisedVariables["showBookingsDefaultStateTrue"]);
      setEventsHelper({
        ...eventsHelper,
        ...initialisedVariables,
      });
    }
  }, [properties]);

  /*
   sets up coloredEvents
*/
  useEffect(() => {
    if (properties) {
      const initialisedVariables = initialiseVariables(properties);
      const { propertiesId, colorsActive, colorsInactive, numberOfColors } =
        initialisedVariables;
      setColoredEvents(
        colorEvents(
          dataEvents,
          propertiesId,
          colorsActive,
          colorsInactive,
          numberOfColors
        )
      );
    }
  }, [dataEvents, properties]);
  /*
   sets up filteredEvents
*/
  useEffect(() => {
    setFilteredEvents(filterEvents(coloredEvents, showBookings));
  }, [showBookings, coloredEvents]);

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const CreateAdButton = () => {
    return (
      <div className="center">
        <button
          className="purple-button center transition-none text-[12px] !h-[40px] !w-[240px] xl:!h-[48px] xl:!w-[254px] xl:!text-sm !font-medium"
          onClick={() => {
            setActiveStep(0);
          }}
        >
          <div className="flex flex-row gap-4 ">
            <div className="center">
              <img
                className=" xl:w-[24px] xl:h-[24px] "
                src="/images/navigation-signs/white-add-icon.svg"
                alt="sticker cloud"
              />
            </div>
            <p className="center">CR{"\u00c9"}ER UNE ANNONCE</p>
          </div>
        </button>
      </div>
    );
  };
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const CalendarMenuModal = () => {
    return (
      <GenericModal
        children={
          <div className="pt-[40px] pb-[60px]">
            <h4 className="font-bold text-[24px] py-4 center">
              Affiner la recherche
            </h4>
            <hr className="pb-10" />
            <MenuCalendar
              showBookings={showBookings}
              setShowBookings={setShowBookings}
              eventsHelper={eventsHelper}
            />
          </div>
        }
        isOpen={menuModalIsOpen}
        handleClose={() => {
          setMenuModalIsOpen(false);
        }}
        withHeader={false}
        height="900px"
      />
    );
  };

  const MenuCalendar = () => {
    const windowWidth = useWindowsSize();

    return (
      <div>
        <SelectProperties
          withSeeMore={windowWidth < 1280 ? true : false}
          eventsHelper={eventsHelper}
          showBookings={showBookings}
          setShowBookings={setShowBookings}
        />
      </div>
    );
  };

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <CompleteAnAdModal />
      <AdModal />
      <CalendarMenuModal />

      <main className=" flex flex-col grow">
        <HostNavBar focused="dashboard" />

        {loading ? (
          <div className="flex grow w-full h-full justify-center items-center">
            <CircularProgress size={60} thickness={4} />
          </div>
        ) : (
          <div className="xl:flex xl:justify-center xl:items-center ">
            <div className="text-black max-w-smallScreen mx-auto px-6 md:px-10 ld:px-16 py-[20px] relative">
              <div className="">
                <div className="flex flex-col  w-full">
                  <div className="text-4xl font-bold flex-row flex gap-4 pt-[20px] xl:pt-[60px] ">
                    <h1>Bonjour {currentUser && currentUser.firstName}</h1>

                    <img
                      className="w-10 h-10  xl:w-[44px] xl:h-[44px] "
                      src="/images/hello-greeting-emoji.svg"
                      alt="sticker cloud"
                    />
                  </div>
                  <Actualities adsNotCompleted={adsNotCompleted} />
                </div>

                <div className="py-[35px] xl:hidden">
                  <CreateAdButton />
                </div>

                <div className="flex flex-row justify-between xl:pt-[70px]  xl:pb-[30px]">
                  <div className="flex flex-row justify-between w-full    ">
                    <h2 className="text-[24px] xl:text-[32px]  ">
                      Mon calendrier
                    </h2>

                    <button
                      onClick={() => {
                        return setMenuModalIsOpen(true);
                      }}
                      className=" w-[55px] h-[50px] z-30 xl:hidden "
                    >
                      <img
                        className=" block  cursor-pointer p-3"
                        src="/images/navigation-signs/menu-icon.svg"
                        alt="sticker menu"
                      />
                    </button>
                  </div>
                  <div className="hidden xl:flex">
                    <CreateAdButton />
                  </div>
                </div>

                <div className="xl:grid xl:grid-cols-5">
                  <div className=" hidden xl:block xl:min-w-min">
                    <MenuCalendar />
                  </div>

                  <div className="xl:col-span-4 xl:ml-[60px]">
                    <Calendar events={filteredEvents} />
                  </div>
                </div>

                <div className="center pt-6">
                  <button
                    className="purple-button center transition-none text-[12px] !h-[40px] !w-[270px]  !font-medium lg:hidden"
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    VOIR TOUTES MES R{"\u00c9"}SERVATIONS
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};
export default Dashboard;
