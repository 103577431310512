import React, { useState, useEffect } from "react";
import AuthModal from "./AuthModal";
import { InputAdornment, TextField } from "@mui/material";
import { IonIcon } from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import * as validator from "validator";
import useAuth from "../../hooks/useAuth";

const ResetPasswordContent = () => {
  let defaultErrorForm = {
    emailError: false,
    emailErrorMessage: "",
    generalError: false,
    generalErrorMessage: "",
  };
  const { PasswordReset, loginSubscribeState, loginSubscribeDispatch } =
    useAuth();
  const buttonDisabled = loginSubscribeState.email === "" ? true : false;
  const [errorForm, setErrorForm] = useState(defaultErrorForm);
  const [notificationMessage, setNotificationMessage] = useState(null);

  const handleChange = (event, kind) => {
    let target = event.target.value;
    loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
  };

  const validate = async () => {
    let newErrorForm = defaultErrorForm;

    if (!validator.isEmail(loginSubscribeState.email)) {
      newErrorForm["emailError"] = true;
      newErrorForm["emailErrorMessage"] = "L'adresse e-mail n'est pas valide.";
    }

    let validate = !newErrorForm.emailError;

    if (validate) {
      const res = await PasswordReset(loginSubscribeState.email);

      if (res?.statusCode === 200) {
        setNotificationMessage(
          "Si votre email existe dans notre base de données, vous recevrez dans quelques minutes un email vous indiquant comment réinitialiser votre mot de passe."
        );
      } else {
        newErrorForm["generalError"] = true;

        newErrorForm["generalErrorMessage"] = res?.errorMessage;
      }
    } else {
      newErrorForm["generalError"] = true;

      newErrorForm["generalErrorMessage"] =
        "Les champs en rouge sont obligatoires ";
    }

    setErrorForm(newErrorForm);
  };

  const resetValues = () => {
    setErrorForm(defaultErrorForm);
    setNotificationMessage(null);
  };

  useEffect(() => {
    if (loginSubscribeState?.openModal === "none") {
      resetValues();
    }
  }, [loginSubscribeState.openModal]);

  return (
    <div className="flex flex-col justify-center h-full space-y-32 md:space-y-28">
      <div className="space-y-[60px] px-0 md:px-10">
        <h2 className="font-bold text-center">Mot de passe oublié</h2>

        <div className="space-y-4">
          <div className="center">
            <TextField
              error={errorForm.emailError}
              helperText={errorForm.emailErrorMessage}
              value={loginSubscribeState.email}
              label="Email"
              onChange={(event) => handleChange(event, "EMAIL")}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--first-color)",
                  },
                },
                "& .MuiFormLabel-root": {
                  "&.Mui-focused": {
                    color: "var(--first-color)",
                  },
                },
                "& .MuiFormHelperText-root": {
                  color: "var(--error-color)",
                  margin: "1px",
                },
              }}
              fullWidth
              InputProps={{
                type: "text",
                "aria-labelledby": "input-slider",
                endAdornment: (
                  <InputAdornment position="end">
                    <IonIcon
                      icon={mailOutline}
                      className="w-6 h-6 text-main/dark-100 p-[1px]"
                    ></IonIcon>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {notificationMessage && (
            <p className="block text-xs font-inter">{notificationMessage}</p>
          )}
        </div>

        <div className="flex flex-col items-center space-y-3">
          <button
            disabled={buttonDisabled}
            className="purple-button"
            onClick={validate}
          >
            SE CONNECTER
          </button>

          {errorForm.generalError && (
            <p className="block text-xs font-inter text-semantic/error">
              {errorForm.generalErrorMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const ResetPassword = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={ResetPasswordContent()}
      isOpen={loginSubscribeState.openModal == "ResetPassword"}
    />
  );
};
export default ResetPassword;
