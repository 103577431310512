import React, { useState } from "react";
import AdModal from "../ad/AdModal";
import { TextField } from "@mui/material";
import useAd from "../../hooks/useAd";
import NextButton from "../ad/NextButton";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const PropertyRegistrationNumberContent = () => {
  let defaultErrorForm = {
    showMessageError: false,
  };

  const {
    completeAd,
    setCompleteAd,
    handleNext,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { registrationNumber, isClassifiedProperty } = completeAd;
  const [swornStatementChecked, setSwornStatementChecked] = useState(false);
  const [errorForm, setErrorForm] = useState(defaultErrorForm);
  const isValidate = swornStatementChecked && isClassifiedProperty !== "";

  const validate = async () => {
    let newErrorForm = defaultErrorForm;

    newErrorForm["showMessageError"] =
      registrationNumber.length > 0 && registrationNumber.length !== 13 && true;

    if (isValidate && !newErrorForm.showMessageError) {
      if (!isEdit) {
        handleNext();
      } else {
        if (
          editAd.registrationNumber !== registrationNumber ||
          editAd.isClassifiedProperty !== isClassifiedProperty
        ) {
          const newValues = {
            registrationNumber,
            isClassifiedProperty,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    } else {
      setErrorForm(newErrorForm);
    }
  };

  const Option = ({ title, yesOrNo }) => {
    return (
      <div className="flex items-center">
        <label
          className="relative flex cursor-pointer items-center rounded-full p-3"
          htmlFor={yesOrNo.toString()}
          data-ripple-dark="true"
        >
          <input
            id={yesOrNo.toString()}
            name="type"
            type="radio"
            checked={
              isClassifiedProperty !== "" &&
              (yesOrNo ? isClassifiedProperty : !isClassifiedProperty)
            }
            onChange={() => {
              if (isClassifiedProperty == "") {
                if (yesOrNo) {
                  setCompleteAd({
                    ...completeAd,
                    isClassifiedProperty: true,
                  });
                } else {
                  setCompleteAd({
                    ...completeAd,
                    isClassifiedProperty: false,
                  });
                }
              } else {
                setCompleteAd({
                  ...completeAd,
                  isClassifiedProperty: !isClassifiedProperty,
                });
              }
            }}
            className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border ${
              errorForm?.isClassifiedError
                ? "border-semantic/error"
                : "border-blue-gray-200"
            } text-main/main-100 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-main/main-100 checked:before:bg-main/main-100 hover:before:opacity-10`}
          />
          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-main/main-100 opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </div>
        </label>
        <label
          className="mt-px cursor-pointer select-none font-light text-sm"
          htmlFor="html"
        >
          {title}
        </label>
      </div>
    );
  };
  const RadioButtonsGroup = () => {
    return (
      <div className="center pt-5">
        <div className="grid grid-col-1 ">
          <Option title="Oui" yesOrNo={true} />
          <Option title="Non" yesOrNo={false} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-2">
          <h4 className="text-center lg:px-10 py-[8px]">
            Numéro d’enregistrement du bien
          </h4>

          <p className=" text-center text-neutral-400 text-sm  leading-snug pb-2  relative">
            Le numéro d'enregistrement est un identifiant unique, composé de 13
            chiffres, délivré par les autorités locales pour réguler les
            locations à court terme. Merci de le renseigner si vous êtes
            concerné(e).
            <BasicTooltip />
          </p>
        </div>

        <div className="lg:w-[400px] center ">
          <div className="space-y-2">
            <TextField
              value={registrationNumber}
              placeholder="0000000000000"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--first-color)",
                  },
                },
                "& .MuiFormLabel-root": {
                  "&.Mui-focused": {
                    color: "var(--first-color)",
                  },
                },
              }}
              onChange={(event) => {
                setCompleteAd({
                  ...completeAd,
                  registrationNumber: event.target.value,
                });
              }}
              fullWidth
              InputProps={{
                type: "text",
                "aria-labelledby": "input-slider",
              }}
            />
            <button>
              <CheckboxCustomed
                swornStatementChecked={swornStatementChecked}
                swornStatementError={errorForm?.swornStatementError}
                handleClick={() => {
                  setSwornStatementChecked(!swornStatementChecked);
                }}
              />
            </button>
          </div>
        </div>

        {errorForm?.showMessageError && (
          <p className="text-semantic/error text-sm pl-2 pt-1 -mb-6 ">
            Le numéro d'enregistrement du bien doit comporter 13 caractères
          </p>
        )}

        <div className="space-y-4">
          <h2 className="center font-bold text-[24px] text-center pt-4 px-1">
            Votre bien est-il classé en meublé de tourisme ou hébergement
            touristique ?
          </h2>

          <RadioButtonsGroup />
        </div>
      </div>

      {!isEdit ? (
        <div className="flex w-full justify-end pt-6">
          <NextButton handleClick={validate} isDisabled={!isValidate} />
        </div>
      ) : (
        <div className="flex w-full justify-center pt-10">
          <button
            className="purple-button center relative left-0 !w-[111px]"
            disabled={!isValidate}
            onClick={validate}
          >
            VALIDER
          </button>
        </div>
      )}
    </>
  );
};

function BasicTooltip() {
  return (
    <Tooltip
      sx={{ padding: "0px", marginBottom: "4px", marginLeft: "2px" }}
      title="Vous retrouvez ce numéro sur l'accusé de réception de l'enregistrement délivré par votre municipalité (uniquement en France)."
    >
      <IconButton>
        <img
          src="/images/navigation-signs/question-mark.svg"
          alt="slotr-login-star"
          className="w-[10px] h-[10px] object-contain inline-block "
        />
      </IconButton>
    </Tooltip>
  );
}

function CheckboxCustomed({
  swornStatementError,
  swornStatementChecked,
  handleClick,
}) {
  return (
    <FormControl error={swornStatementError}>
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={swornStatementChecked}
            onChange={handleClick}
            sx={{
              transform: "scale(0.75)",
              color: "black",
              position: "absolute",
              top: "-9px",
              left: "-30px",
            }}
          />
        }
        label={
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "var(--second-color)",
              },
              color: "var(--second-color)",
              fontSize: "14px",
            }}
          >
            <p className="leading-[25px] block -ml-4 text-start">
              Je déclare sur l’honneur être propriétaire ou gestionnaire du bien
            </p>
          </FormLabel>
        }
        sx={{
          position: "relative",
          top: 0,
          left: "30px",
          paddingRight: "10px",
          paddingLeft: "22px",
        }}
      />
    </FormControl>
  );
}

const PropertyRegistrationNumber = () => {
  return (
    <AdModal
      children={<PropertyRegistrationNumberContent />}
      stepNumber={14}
      height="880px"
    />
  );
};
export default PropertyRegistrationNumber;
