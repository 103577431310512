import React from "react";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import NextButton from "./NextButton";
import ServiceAndCategoryCard, {
  listOfCategories,
} from "../../components/ServiceAndCategoryCard";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const CategoriesContent = () => {
  const {
    handleNext,
    startAd,
    setStartAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { category, entireAccomodation } = startAd;
  const isValidate = category && entireAccomodation !== "";

  const validate = async () => {
    if (isValidate) {
      if (!isEdit) {
        handleNext();
      } else {
        if (
          editAd.category !== category ||
          editAd.entireAccomodation !== entireAccomodation
        ) {
          const newValues = {
            category,
            entireAccomodation,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    }
  };

  return (
    <>
      <div className="space-y-6">
        <h4 className="center">Choisissez la catégorie</h4>

        <div className="center">
          <div className="grid grid-cols-3 gap-x-2 gap-y-6">
            {listOfCategories.map(({ name }) => (
              <button
                key={name}
                onClick={() =>
                  setStartAd({
                    ...startAd,
                    category: category !== name ? name : "",
                  })
                }
              >
                <ServiceAndCategoryCard
                  name={name}
                  type={"category"}
                  isActive={category === name}
                />
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-[15px] md:pt-[15px]">
          <h4 className="text-center">Vous mettez à disposition :</h4>

          <div className="space-y-[30px]">
            <div className="center">
              <RadioGroup
                value={startAd?.entireAccomodation}
                onChange={(e) =>
                  setStartAd({
                    ...startAd,
                    entireAccomodation:
                      e.target.value === "true" ? true : false,
                  })
                }
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Logement entier"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Une partie du logement"
                />
              </RadioGroup>
            </div>

            {!isEdit ? (
              <div className="flex w-full justify-end pt-6">
                <NextButton disabled={!isValidate} handleClick={validate} />
              </div>
            ) : (
              <div className="flex w-full justify-center pt-10">
                <button
                  className="purple-button center relative left-0 !w-[111px]"
                  onClick={validate}
                  disabled={!isValidate}
                >
                  VALIDER
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const Categories = () => {
  return (
    <AdModal
      children={<CategoriesContent />}
      stepNumber={0}
      height="766px"
      withHeader={false}
    />
  );
};
export default Categories;
