import React, { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import PropertyCard from "../components/PropertyCard";
import { API } from "aws-amplify";
import { IonIcon } from "@ionic/react";
import {
  optionsOutline,
  expandOutline,
  contractOutline,
  locationOutline,
} from "ionicons/icons";
import CircularProgress from "@mui/material/CircularProgress";
import Filter from "../components/filter/Filter";
import useAd from "../hooks/useAd";
import { BUILD_ENV, hostname } from "../App";
import SearchBar from "../components/SearchBar";
import MobileSearchBar from "../components/MobileSearchBar";
import MapContainer from "../components/MapContainer";
import { format, parse } from "date-fns";
import { geocodingPlace } from "../utils/maps";

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [properties, setProperties] = useState(null);
  const [fullSizeMap, setFullSizeMap] = useState(false);
  const [nbOfChanges, setNbOfChanges] = useState(0);
  const [propertiesLimit, setPropertiesLimit] = useState(12);
  const { filtersAd, setFiltersAd, defaultFiltersAd } = useAd();
  const [currentDestination, setCurrentDestination] = useState(null);
  const [urlParams, setUrlParams] = useState(null);
  const minPriceDefault = 0;
  const maxPriceDefault = 9999;

  const getProperties = async (filtersValue) => {
    try {
      const apiName = "propertiesManager";
      const path = `/properties`;
      const options = {
        queryStringParameters: {},
      };

      Object.keys(filtersValue).forEach((key) => {
        if (
          key !== "isOpen" &&
          key !== "minPrice" &&
          key !== "maxPrice" &&
          key !== "filterSubmit"
        )
          if (Array.isArray(filtersValue[key])) {
            let arrayAsString;
            if (
              key === "prices" &&
              currentDestination !== filtersValue.destination
            ) {
              arrayAsString = [minPriceDefault, maxPriceDefault].join(",");
            } else {
              arrayAsString = filtersValue[key].join(",");
            }

            options.queryStringParameters[key] = arrayAsString;
          } else {
            options.queryStringParameters[key] = filtersValue[key];
          }
      });

      let response;

      if (BUILD_ENV === "localhost") {
        const queryParams = new URLSearchParams(options.queryStringParameters);

        response = await fetch(
          `http://${hostname}:3001/properties?${queryParams.toString()}`
        );
        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      if (response) {
        const minPrice = response.filtersData?.minPrice || minPriceDefault;
        const maxPrice = response.filtersData?.maxPrice || maxPriceDefault;

        setFiltersAd({
          ...filtersValue,
          minPrice,
          maxPrice,
          prices: [minPrice, maxPrice],
          filterSubmit: false,
        });
        setProperties(response.properties);
        setCurrentDestination(filtersValue?.destination);
        setPropertiesLimit(12);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const numberOfChanges = (currentValue, defaultValue) => {
    let changes = 0;

    const newObj = {
      prices: currentValue.prices,
      entireAccomodation: currentValue.entireAccomodation,
      numberOfBeds: currentValue.numberOfBeds,
      numberOfBedrooms: currentValue.numberOfBedrooms,
      categories: currentValue.categories,
      services: currentValue.services,
    };

    Object.keys(newObj).forEach((key) => {
      if (key === "prices") {
        if (
          newObj.prices[0] !== currentValue.minPrice ||
          newObj.prices[1] !== currentValue.maxPrice
        ) {
          changes++;
        }
        return;
      }

      if (key === "services" || key === "categories") {
        if (newObj[key].length > 0) {
          changes++;
        }
        return;
      }

      if (
        defaultValue.hasOwnProperty(key) &&
        newObj[key] !== defaultValue[key]
      ) {
        changes++;
      }
    });

    setNbOfChanges(changes);
  };

  const TravelersText = () => {
    const adultsAndChildren =
      parseInt(filtersAd?.adults) + parseInt(filtersAd?.children);
    const babies = parseInt(filtersAd?.babies);

    const travelersText = `${adultsAndChildren} ${
      adultsAndChildren === 1 ? "voyageur" : "voyageurs"
    }`;
    const babiesText = `${babies} ${babies === 1 ? "bébé" : "bébés"}`;

    return (
      <span className="text-sm  line-clamp-1">
        {travelersText + (babies > 0 ? ", " + babiesText : "")}
      </span>
    );
  };

  const updateSearch = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    let hasChanges = false;

    Object.keys(urlParams).forEach((key) => {
      const currentValue = urlParams[key];
      const newValue = filtersAd[key];

      if (currentValue !== newValue) {
        let value =
          (key === "checkIn" || key === "checkOut") && filtersAd[key] !== ""
            ? format(filtersAd[key], "dd-MM-yyyy")
            : filtersAd[key];

        newSearchParams.set(key, value);
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setSearchParams(newSearchParams);
    }
  };

  function handleNavigateAndRefresh() {
    window.location.href =
      "/search?destination=France&placeId=&checkIn=&checkOut=&adults=1&children=0&babies=0&arePetsAllowed=false";
  }

  useEffect(() => {
    const getParams = async () => {
      const params = {
        destination: searchParams.get("destination") || "",
        placeId: searchParams.get("placeId") || "",
        checkIn: searchParams.get("checkIn")
          ? parse(searchParams.get("checkIn"), "dd-MM-yyyy", new Date())
          : "",
        checkOut: searchParams.get("checkOut")
          ? parse(searchParams.get("checkOut"), "dd-MM-yyyy", new Date())
          : "",
        adults: Number(searchParams.get("adults")) || 1,
        children: Number(searchParams.get("children")) || 0,
        babies: Number(searchParams.get("babies")) || 0,
      };

      let coordinates;

      if (params.destination && params?.destination !== "France") {
        coordinates = await geocodingPlace("address", params?.destination);
      }

      params["lat"] = coordinates?.lat || "";
      params["lng"] = coordinates?.lng || "";

      setFiltersAd({ ...filtersAd, ...params });
      setUrlParams(params);
    };

    getParams();
  }, [searchParams]);

  useEffect(() => {
    if (urlParams || filtersAd.filterSubmit) {
      getProperties(filtersAd);
    }
  }, [urlParams, filtersAd.filterSubmit]);

  useEffect(() => {
    if (filtersAd.filterSubmit) {
      updateSearch();
      numberOfChanges(filtersAd, defaultFiltersAd);
    }
  }, [filtersAd.filterSubmit]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fullSizeMap]);

  return (
    <>
      {filtersAd.isOpen && <Filter />}

      <div className="sticky top-[79px] lg:top-[100px] z-40 bg-white lg:bg-neutral/gray/gray-10 w-full px-6 md:px-10 h-20 flex items-center">
        <div className="hidden lg:flex w-full max-w-4xl mx-auto">
          <SearchBar />
        </div>

        <div className="flex lg:hidden w-full">
          <MobileSearchBar nbOfChanges={nbOfChanges} />
        </div>
      </div>

      <main className="flex grow ">
        {!properties ? (
          <div className="flex w-full grow justify-center items-center">
            <CircularProgress size={60} thickness={4} />
          </div>
        ) : (
          <>
            <section
              className={`${
                !fullSizeMap && "hidden"
              } lg:block w-full lg:w-[40%] xl:w-[35%] 2xl:w-[100%] grow h-[calc(100vh-160px)] lg:h-[calc(100vh-180px)] sticky top-[160px] lg:top-[180px] z-30`}
            >
              <MapContainer properties={properties} filters={filtersAd} />

              <div className="hidden lg:flex absolute bottom-10 justify-center w-full">
                <button
                  className="flex space-x-2 items-center purple-button !text-xs !h-[48px] !w-[210px]"
                  onClick={() => setFullSizeMap(fullSizeMap ? false : true)}
                >
                  {fullSizeMap ? (
                    <img
                      src="/images/grid-icon.svg"
                      alt="grid-icon"
                      className="w-6 h-6"
                    />
                  ) : (
                    <IonIcon
                      icon={fullSizeMap ? contractOutline : expandOutline}
                      className="w-6 h-6 text-white"
                    ></IonIcon>
                  )}

                  <span>
                    {fullSizeMap ? "Retour à la liste" : "Agrandir la carte"}
                  </span>
                </button>
              </div>
            </section>

            <section
              className={`flex flex-col w-full lg:w-[60%] xl:w-[65%] 2xl:max-w-[1200px] lg:space-y-10 flex-none ${
                fullSizeMap && "hidden"
              }`}
            >
              <div className="sticky top-[159px]  lg:top-[180px] z-30 flex lg:items-center lg:justify-between space-x-4 px-6 md:px-10 py-6 bg-white  lg:border-b lg:border-neutral/gray/gray-40">
                <div className="flex space-x-2 items-center whitespace-nowrap overflow-hidden w-full">
                  <p className="hidden lg:block font-semibold text-base">
                    {properties?.length + " biens pour la recherche : "}
                  </p>

                  <p className="block lg:hidden text-sm text-center w-full ">
                    {properties?.length + " biens pour cette recherche"}
                  </p>

                  <div className="hidden lg:flex space-x-2 items-center overflow-hidden">
                    {filtersAd?.destination && (
                      <>
                        <p className="text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                          {filtersAd?.destination}
                        </p>

                        <span className="text-main/main-100">|</span>
                      </>
                    )}

                    {filtersAd?.checkIn && filtersAd?.checkOut && (
                      <>
                        <p className="text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                          {format(new Date(filtersAd?.checkIn), "dd MMMM") +
                            "-" +
                            format(new Date(filtersAd?.checkOut), "dd MMMM")}
                        </p>

                        <span className="text-main/main-100">|</span>
                      </>
                    )}

                    {filtersAd?.adults && <TravelersText />}
                  </div>
                </div>

                <div className="relative">
                  <button
                    className="hidden lg:flex border-2 border-main/main-100 rounded-full px-6 py-2 space-x-2 items-center cursor-pointer"
                    onClick={() =>
                      setFiltersAd({
                        ...filtersAd,
                        isOpen: true,
                      })
                    }
                  >
                    <p className="text-sm uppercase font-medium text-main/main-100 leading-none">
                      Filtres
                    </p>

                    <IonIcon
                      icon={optionsOutline}
                      className="w-5 h-5 text-main/main-100"
                    ></IonIcon>
                  </button>

                  {nbOfChanges > 0 && (
                    <div className="hidden lg:flex absolute -top-0.5 -right-1 justify-center items-center rounded-full p-2 w-[18px] h-[18px] bg-main/main-100">
                      <span className="text-white text-sm leading-none font-semibold">
                        {nbOfChanges}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex space-y-10 px-6 md:px-10 grow">
                {properties?.length === 0 ? (
                  <div className="flex justify-center items-center pb-10">
                    <div className="flex flex-col space-y-8 md:w-2/3 items-center text-justify">
                      <p>
                        Pour le moment, nous n’avons pas de biens disponibles
                        correspondant à votre recherche (pour le moment
                        &#128521;).
                        <br />
                        <br />
                        N’hésitez pas à ajuster les filtres ou bien la
                        destination afin de découvrir les autres logements que
                        nous avons à votre disposition aux alentours.
                      </p>

                      <button
                        className="bg-main/main-100 text-white uppercase py-3.5 px-8 rounded-full text-sm font-semibold"
                        onClick={handleNavigateAndRefresh}
                      >
                        Voir tous nos biens disponibles
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:gap-x-8 lg:gap-x-10 gap-y-8 lg:gap-y-10 pb-10 lg:pb-16">
                      {properties.slice(0, propertiesLimit)?.map((property) => (
                        <PropertyCard
                          key={property?.uid}
                          data={property}
                          FiltersData={filtersAd}
                        />
                      ))}
                    </div>

                    {propertiesLimit < properties?.length && (
                      <div
                        className="pb-10 lg:pb-16 flex justify-center"
                        onClick={() => setPropertiesLimit(propertiesLimit + 12)}
                      >
                        <button className="purple-button !w-[140px] lg:!text-sm lg:!h-[48px] lg:!w-[180px]">
                          AFFICHER PLUS
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </section>

            <div className="flex lg:hidden fixed bottom-[100px] z-[50] justify-center w-full">
              <button
                className="flex space-x-2 items-center purple-button !text-xs !h-[48px] !w-[120px]"
                onClick={() => setFullSizeMap(fullSizeMap ? false : true)}
              >
                {fullSizeMap ? (
                  <img
                    src="/images/grid-icon.svg"
                    alt="grid-icon"
                    className="w-6 h-6"
                  />
                ) : (
                  <IonIcon
                    icon={locationOutline}
                    className="w-6 h-6 text-white"
                  ></IonIcon>
                )}

                <span>{fullSizeMap ? "liste" : "carte"}</span>
              </button>
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default Search;
