import React, { useState, useRef, useEffect } from "react";
import { Calendar as BigCalendar } from "react-big-calendar";
import { Link } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { dayjsLocalizer } from "react-big-calendar";
import Hammer from "hammerjs";
import dayjs from "dayjs";
import fr from "dayjs/locale/fr";
import { getDay, isSameDay } from "date-fns";
import { IonIcon } from "@ionic/react";
import {
  closeOutline,
  trashOutline,
  eye,
  arrowBack,
  arrowForward,
} from "ionicons/icons";

dayjs.locale(fr);
const localizer = new dayjsLocalizer(dayjs);

const Calendar = ({
  events,
  prices,
  onSelectedDate,
  handleDeleteDate,
  setCalendarMenuModalIsOpen,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSwiped, setIsSwiped] = useState("");
  const [cellWidth, setCellWidth] = useState(0);
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const customToolbarText = {
    back: "Précédent",
    next: "Suivant",
    today: "Aujourd'hui",
  };

  useEffect(() => {
    let calendarElement = calendarRef.current;

    if (calendarElement) {
      const mc = new Hammer.Manager(calendarElement, {
        recognizers: [
          [
            Hammer.Swipe,
            { event: "swipe", options: { velocity: 0.1, threshold: 1 } },
          ],
        ],
      });

      mc.on("swipe", (ev) => {
        if (ev.offsetDirection === 2) {
          setIsSwiped("next");
        } else if (ev.offsetDirection === 4) {
          setIsSwiped("prev");
        }
      });

      return () => {
        mc.off("swipe");
        mc.destroy();
      };
    }
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);

      if (calendarRef.current) {
        const calendarWidth = calendarRef.current.getBoundingClientRect().width;

        setCellWidth((calendarWidth - 40) / 7);
      }
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    if (isMobile && selectedDate) {
      setCalendarMenuModalIsOpen(true);
    }
  }, [selectedDate]);

  function isWeekendNight(date) {
    const day = getDay(date);

    return day === 5 || day === 6;
  }

  const eventStyleGetter = (event, isSelected) => {
    const style = {
      backgroundColor: event.color || "#A4A7C9",
      color: event.textColor || "var(--second-color)",
      //width: `calc(100% - ${cellWidth + 1}px)`,
      //marginLeft: cellWidth / 2,
      //marginRight: cellWidth / 2,
    };

    if (prices)
      if (isSelected) {
        style.borderColor = "#8A55FB";
      }
    return {
      style,
      id: "propertyId",
    };
  };

  const normaliserDate = (date) => {
    return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
  };

  const Toolbar = ({ onNavigate, label, isSwiped, setIsSwiped, isMobile }) => {
    if (isSwiped == "next" && isMobile) {
      onNavigate("NEXT");
      setIsSwiped("");
    }
    if (isSwiped == "prev" && isMobile) {
      onNavigate("PREV");
      setIsSwiped("");
    }

    return (
      <div className="flex items-center justify-between w-full pb-6 md:pb-10 lg:pb-16 pt-2 md:pt-4 lg:pt-6">
        <button
          onClick={() => onNavigate("PREV")}
          className="flex lg:space-x-2 lg:items-center"
        >
          <IonIcon
            icon={arrowBack}
            className="w-6 h-6 lg:w-7 lg:h-7 text-main/main-100  "
          ></IonIcon>

          <span className="hidden xl:flex">Mois précédent</span>
        </button>

        <p className="font-normal font-inter text-base lg:text-[32px] text-center capitalize">
          {label}
        </p>

        <button
          onClick={() => onNavigate("NEXT")}
          className="flex lg:space-x-2 lg:items-center"
        >
          <span className="hidden xl:flex">Mois suivant</span>

          <IonIcon
            icon={arrowForward}
            className="w-6 h-6 lg:w-7 lg:h-7 text-main/main-100 "
          ></IonIcon>
        </button>
      </div>
    );
  };

  const DateCell = ({ value, prices, children }) => {
    const normalPrice = prices?.price;
    const weekPrice = prices?.weekPrice || prices?.price;
    let specialPrice;

    const isSpecialPrice = prices?.datesSpecialPrice?.some((event) => {
      const startDate = normaliserDate(new Date(event.startDate));
      const endDate = normaliserDate(new Date(event.endDate));
      const currentDate = normaliserDate(value);

      if (currentDate >= startDate && currentDate < endDate) {
        specialPrice = event.price;
        return true;
      } else {
        return false;
      }
    });

    const isDayBlocked = events?.some((event) => {
      if (event.type !== "price") {
        const startDate = normaliserDate(new Date(event.start));
        const endDate = normaliserDate(new Date(event.end));
        const currentDate = normaliserDate(value);

        return currentDate >= startDate && currentDate <= endDate;
      }
    });

    const handleDayClick = () => {
      if (value && !isSameDay(value, new Date()) && !isDayBlocked) {
        setSelectedDate(value);

        if (onSelectedDate) {
          onSelectedDate(value);
        }
      }
    };

    return (
      <div className={`rbc-date-cell ${children.props.className} h-8 `}>
        <button
          className={`rbc-button-link !z-40 ${
            isSameDay(value, selectedDate) &&
            prices &&
            !isDayBlocked &&
            "!bg-main/main-100 !rounded-[4px] !text-white"
          } ${isSameDay(value, new Date()) && "!text-main/main-100"}`}
          onClick={handleDayClick}
        >
          {("0" + value?.getDate())?.slice(-2)}
        </button>

        {prices && (
          <span className="text-neutral/gray/gray-100 text-xs font-inter rounded-lg p-1">
            {isSpecialPrice
              ? `${specialPrice}€`
              : isWeekendNight(value) && weekPrice
              ? `${weekPrice}€`
              : `${normalPrice}€`}
          </span>
        )}
      </div>
    );
  };

  const CustomEvent = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef(null);

    const handleOpenTooltip = () => {
      setShowTooltip(true);
    };

    const handleCloseTooltip = () => {
      setTimeout(() => setShowTooltip(false), 10);
    };

    return (
      <div
        className={`event-container font-medium  ${
          showTooltip ? "h-auto p-1" : "h-1.5"
        }`}
        ref={ref}
        onClick={handleOpenTooltip}
      >
        <div
          className={`flex flex-col space-y-1 ${
            !showTooltip && "opacity-0 flex"
          }`}
        >
          <div className="flex justify-end space-x-2 items-center">
            {props.event.type === "blocked-dates" &&
              props.event.source === "SlotR" &&
              prices && (
                <button
                  className="flex p-1 rounded-full bg-white"
                  onClick={() => handleDeleteDate(props.event)}
                >
                  <IonIcon
                    icon={trashOutline}
                    className="main/dark-100 w-4 h-4"
                  ></IonIcon>
                </button>
              )}

            {props.event.type === "booking" &&
              props.event.source === "SlotR" && (
                <Link to={`/bookings#${props.event.uid}`}>
                  <div className="flex p-1 rounded-full bg-white">
                    <IonIcon
                      icon={eye}
                      className="main/dark-100 w-4 h-4"
                    ></IonIcon>
                  </div>
                </Link>
              )}

            <button
              className="flex p-1 rounded-full bg-white"
              onClick={handleCloseTooltip}
            >
              <IonIcon
                icon={closeOutline}
                className="main/dark-100 w-4 h-4"
              ></IonIcon>
            </button>
          </div>

          <div className="w-0 space-y-1">
            <p className="text-sm">{props.event.title}</p>

            <p className="text-sm capitalize">{props.event.source}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div ref={calendarRef} className="w-full h-full">
      <BigCalendar
        localizer={localizer}
        events={events}
        style={{
          height: isMobile ? "80vh" : 1000,
          borderRadius: "8px",
        }}
        startAccessor="start"
        endAccessor="end"
        tooltipAccessor={null}
        components={{
          event: (props) => <CustomEvent {...props} />,
          toolbar: (props) => (
            <Toolbar
              {...props}
              customText={customToolbarText}
              isMobile={isMobile}
              isSwiped={isSwiped}
              setIsSwiped={setIsSwiped}
            />
          ),
          dateCellWrapper: (props) => <DateCell {...props} prices={prices} />,
        }}
        views={["month"]}
        selectable
        showAllEvents={true}
        eventPropGetter={eventStyleGetter}
        culture="fr"
      />
    </div>
  );
};

export default Calendar;
