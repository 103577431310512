import React from "react";
import { TextField } from "@mui/material";
import AdModal from "../ad/AdModal";
import NextButton from "../ad/NextButton";
import useAd from "../../hooks/useAd";

const TermsOfCancelationContent = () => {
  const {
    handleNext,
    completeAd,
    setCompleteAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { termsOfCancelation, rulesOfProcedure } = completeAd;
  const isValidate = termsOfCancelation !== "";

  let RadioButtonOptions = [
    {
      value: 7,
      title: (
        <p>
          <span className="font-bold">7 jours </span>avant son séjour
        </p>
      ),
    },
    {
      value: 14,
      title: (
        <p>
          <span className="font-bold">14 jours </span>avant son séjour
        </p>
      ),
    },
    {
      value: 30,
      title: (
        <p>
          <span className="font-bold">30 jours </span>avant son séjour
        </p>
      ),
    },
    {
      value: 0,
      title: "je n'accepte pas d'annulation sans frais",
    },
  ];

  const Option = ({ title, value }) => {
    return (
      <div className="flex items-center">
        <label
          className="relative flex cursor-pointer items-center rounded-full p-3"
          htmlFor={value}
          data-ripple-dark="true"
        >
          <input
            id={value}
            name="type"
            type="radio"
            checked={termsOfCancelation === value}
            onChange={() => {
              setCompleteAd({
                ...completeAd,
                termsOfCancelation: value,
              });
            }}
            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-main/main-100 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-main/main-100 checked:before:bg-main/main-100 hover:before:opacity-10"
          />
          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-main/main-100 opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </div>
        </label>
        <label
          className="mt-px cursor-pointer select-none font-light text-sm"
          htmlFor="html"
        >
          {title}
        </label>
      </div>
    );
  };

  const RadioButtonsGroup = () => {
    return (
      <div className="grid grid-col-1 ">
        {RadioButtonOptions.map((op) => {
          return <Option title={op.title} value={op.value} key={op.value} />;
        })}
      </div>
    );
  };

  const validate = async () => {
    if (isValidate) {
      if (!isEdit) {
        handleNext();
      } else {
        if (
          editAd.termsOfCancelation !== termsOfCancelation ||
          editAd.rulesOfProcedure !== rulesOfProcedure
        ) {
          const newValues = {
            termsOfCancelation,
            rulesOfProcedure,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }
        close();
      }
    }
  };

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-2">
          <h4 className="text-center">Conditions d'annulation</h4>

          <div className="center text-center text-sm px-6">
            Je permets au voyageur d'annuler sa réservation sans frais :
          </div>
        </div>

        <div className="lg:px-6">
          <RadioButtonsGroup />
        </div>

        <div className="space-y-2">
          <h4 className="centerpx-6 text-center">Règlement intérieur</h4>

          <p className="center text-center text-sm">
            Notez ici les informations concernant les règles de vie de votre
            intérieur. Par exemple si l’intérieur est non-fumeur, si vous
            autorisez les fêtes ...
          </p>
        </div>

        <div className="center lg:px-6 customedScrollNoPadding">
          <TextField
            value={rulesOfProcedure}
            id="outlined-multiline-static"
            multiline
            fullWidth
            rows={8}
            label="Description"
            helperText={`${rulesOfProcedure.length}/1000 caractères`}
            onChange={(event) => {
              setCompleteAd({
                ...completeAd,
                rulesOfProcedure: event.target.value,
              });
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "var(--first-color)",
                },
              },
              "& .MuiFormLabel-root": {
                "&.Mui-focused": {
                  color: "var(--first-color)",
                },
              },
            }}
            inputProps={{
              maxLength: 1000,
              style: {
                paddingRight: 8,
              },
            }}
          />
        </div>

        {!isEdit ? (
          <div className="flex w-full justify-end pt-6">
            <NextButton handleClick={validate} isDisabled={!isValidate} />
          </div>
        ) : (
          <div className="flex w-full justify-center pt-10">
            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
              disabled={!isValidate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const TermsOfCancelation = ({}) => {
  return (
    <AdModal
      children={<TermsOfCancelationContent />}
      stepNumber={10}
      height="900px"
      withMdScrolling={true}
    />
  );
};
export default TermsOfCancelation;
