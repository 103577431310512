import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CounterButton from "../components/CounterButton";
import { IonIcon } from "@ionic/react";
import { personOutline } from "ionicons/icons";
import DatePicker from "../components/property-details/DayPicker";
import Toggle from "../components/Toggle";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import useAd from "../hooks/useAd";
import { format } from "date-fns";

const SearchBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { filtersAd, setFiltersAd } = useAd();
  const isHomePage = location.pathname === "/";

  const handleSubmit = (e) => {
    if (isHomePage && filtersAd.destination) {
      const checkIn =
        filtersAd.checkIn && format(filtersAd.checkIn, "dd-MM-yyyy");
      const checkOut =
        filtersAd.checkOut && format(filtersAd.checkOut, "dd-MM-yyyy");

      navigate(
        `/search?destination=${filtersAd.destination}&placeId=${filtersAd.placeId}&checkIn=${checkIn}&checkOut=${checkOut}&adults=${filtersAd.adults}&children=${filtersAd.children}&babies=${filtersAd.babies}&arePetsAllowed=${filtersAd.arePetsAllowed}`
      );
    } else {
      setFiltersAd({ ...filtersAd, filterSubmit: true });
    }
  };

  const handleDatesSelected = (date) => {
    let selectedRange = { checkIn: date?.from, checkOut: date?.to };

    if (filtersAd?.checkIn && filtersAd?.checkOut) {
      if (selectedRange?.checkOut > filtersAd?.checkOut) {
        selectedRange = { checkIn: selectedRange?.checkOut, checkOut: null };
      } else if (selectedRange?.checkIn < filtersAd?.checkIn) {
        selectedRange = { checkIn: selectedRange?.checkIn, checkOut: null };
      }
    } else if (selectedRange?.checkIn && selectedRange?.checkOut) {
      if (
        selectedRange?.checkIn.getTime() === selectedRange?.checkOut.getTime()
      ) {
        selectedRange = { checkIn: selectedRange?.checkIn, checkOut: null };
      }
    }

    setFiltersAd({ ...filtersAd, ...selectedRange });
  };

  return (
    <div
      className={`flex flex-col md:flex-row items-center space-y-6 md:space-y-0 w-full space-x-2`}
    >
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row h-full w-full items-center">
        <div className="w-full md:min-w-[220px] lg:min-w-[300px]">
          <PlacesAutocomplete state={filtersAd} setState={setFiltersAd} />
        </div>

        <DatePicker
          handleDatesSelected={handleDatesSelected}
          selected={{ from: filtersAd?.checkIn, to: filtersAd?.checkOut }}
        />

        <TravelersDropdown />
      </div>

      <button
        type="submit"
        className="bg-main/main-100 text-white uppercase py-3.5 px-8 rounded-full text-sm font-semibold"
        onClick={handleSubmit}
      >
        RECHERCHER
      </button>
    </div>
  );
};

const TravelersDropdown = () => {
  const wrapperRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { filtersAd, setFiltersAd } = useAd();
  const toggleValues = ["Non", "Oui"];

  const handleAddTravelers = (key) => {
    const value = filtersAd[`${key}`];
    const max = maxNbOfTravelers(key);

    if (value < max) {
      setFiltersAd({
        ...filtersAd,
        [`${key}`]: value + 1,
      });
    }
  };

  const handleRemoveTravelers = (key) => {
    const value = filtersAd[`${key}`];
    const min = key === "adults" ? 1 : 0;

    if (value > min) {
      setFiltersAd({
        ...filtersAd,
        [`${key}`]: value - 1,
      });
    }
  };

  const handleCounterOnChange = (e, key) => {
    let newValue = parseInt(e.target.value);
    const min = key === "adults" ? 1 : 0;
    const max = key === "babies" ? "5" : maxNbOfTravelers(key);

    if (newValue >= min && newValue <= max) {
      setFiltersAd({
        ...filtersAd,
        [`${key}`]: newValue,
      });
    }

    if (isNaN(newValue)) {
      setFiltersAd({
        ...filtersAd,
        [`${key}`]: "",
      });
    }
  };

  const onBlur = (key) => {
    const value = filtersAd[`${key}`];

    if (value === "") {
      setFiltersAd({
        ...filtersAd,
        [`${key}`]: 1,
      });
    }
  };

  const maxNbOfTravelers = (key) => {
    let maxNbOfTravelers;

    if (key === "adults") {
      maxNbOfTravelers = 16 - parseInt(filtersAd?.children);
    } else if (key === "children") {
      maxNbOfTravelers = 16 - parseInt(filtersAd?.adults);
    } else {
      maxNbOfTravelers = 5;
    }

    return maxNbOfTravelers;
  };

  const handlePetsValue = (e) => {
    setFiltersAd({
      ...filtersAd,
      arePetsAllowed: e === toggleValues[1] ? true : false,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const TravelersText = () => {
    const adultsAndChildren =
      parseInt(filtersAd?.adults) + parseInt(filtersAd?.children);
    const babies = parseInt(filtersAd?.babies);

    const travelersText = `${adultsAndChildren} ${
      adultsAndChildren === 1 ? "voyageur" : "voyageurs"
    }`;
    const babiesText = `${babies} ${babies === 1 ? "bébé" : "bébés"}`;

    return (
      <p className="text-sm line-clamp-1">
        <span>{travelersText}</span>

        <span>{babies > 0 ? ", " + babiesText : ""}</span>
      </p>
    );
  };

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <div
        className={`flex w-full items-center border px-4 py-3 space-x-2 bg-white cursor-pointer ${
          isDropdownOpen
            ? "border-main/main-100"
            : "border-neutral/gray/gray-40"
        }`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="flex w-full ">
          <TravelersText />
        </div>

        <IonIcon
          icon={personOutline}
          className="w-6 h-6 flex-none text-neutral/gray/gray-60"
        />
      </div>

      {isDropdownOpen && (
        <div className="absolute top-16 left-0 z-30 bg-white rounded-[4px] border border-neutral/gray/gray-40 px-2 w-full min-w-[300px]">
          <div className="flex w-full items-center justify-between p-4 border-b border-main/dark-20">
            <label className="flex flex-col">
              <span>Adultes</span>

              <span className="text-xs text-design-system/highlight">
                13 ans et +
              </span>
            </label>

            <CounterButton
              handleRemove={() => handleRemoveTravelers("adults")}
              handleAdd={() => handleAddTravelers("adults")}
              handleOnChange={(e) => handleCounterOnChange(e, "adults")}
              counter={filtersAd?.adults}
              min={1}
              max={maxNbOfTravelers("adults")}
              onBlur={() => onBlur("adults")}
            />
          </div>

          <div className="flex w-full items-center justify-between p-4 border-b border-main/dark-20">
            <label className="flex flex-col">
              <span>Enfants</span>

              <span className="text-xs text-design-system/highlight">
                2 à 12 ans
              </span>
            </label>

            <CounterButton
              handleRemove={() => handleRemoveTravelers("children")}
              handleAdd={() => handleAddTravelers("children")}
              handleOnChange={(e) => handleCounterOnChange(e, "children")}
              counter={filtersAd?.children}
              min={0}
              max={maxNbOfTravelers("children")}
              onBlur={() => onBlur("children")}
            />
          </div>

          <div className="flex w-full items-center justify-between p-4 border-b border-main/dark-20">
            <label className="flex flex-col">
              <span>Bébés</span>

              <span className="text-xs text-design-system/highlight">
                2 à 12 ans
              </span>
            </label>

            <CounterButton
              handleRemove={() => handleRemoveTravelers("babies")}
              handleAdd={() => handleAddTravelers("babies")}
              handleOnChange={(e) => handleCounterOnChange(e, "babies")}
              counter={filtersAd?.babies}
              min={0}
              max={5}
              onBlur={() => onBlur("babies")}
            />
          </div>

          <div className="space-y-2 p-4">
            <div className="flex w-full items-center justify-between">
              <label>Animaux</label>

              <Toggle
                defaultValue={
                  filtersAd?.arePetsAllowed ? toggleValues[1] : toggleValues[0]
                }
                firstValue={toggleValues[0]}
                secondValue={toggleValues[1]}
                onChange={handlePetsValue}
              />
            </div>

            <p className="text-xs font-inter text-design-system/highlight">
              Les animaux d'assistance ne sont pas concernés par cette
              catégorie.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
