import React from "react";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import { TextField } from "@mui/material";
import SelectCountry from "../login-subscribe/SelectCountry";
import * as validator from "validator";
import NextButton from "./NextButton";
import { geocodingPlace } from "../../utils/maps";

const AddressContent = () => {
  const {
    handleNext,
    setStartAd,
    startAd,
    handleChange,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { streetNumber, street, postalCode, city, country, addressComplement } =
    startAd;
  const isValidate =
    street && validator.isDecimal(postalCode) && city && country;

  const validate = async () => {
    if (isValidate) {
      const address = `${streetNumber} ${street}, ${postalCode} ${city}, ${country}`;
      const location = await geocodingPlace("address", address);

      if (!isEdit) {
        handleNext();
        setStartAd({ ...startAd, ...location });
      } else {
        if (
          editAd.streetNumber !== streetNumber ||
          editAd.street !== street ||
          editAd.postalCode !== postalCode ||
          editAd.city !== city ||
          editAd.country !== country
        ) {
          const newValues = {
            streetNumber,
            street,
            postalCode,
            city,
            country,
            ...location,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    }
  };

  return (
    <>
      <div className="space-y-6 md:px-4">
        <h4 className="text-center">Renseignez l'adresse de votre bien</h4>

        <div className="space-y-4">
          <div className="center grid grid-cols-2 space-x-2 ">
            <TextField
              value={streetNumber}
              label="N°"
              sx={{
                width: "70px",
              }}
              onChange={(event) => handleChange(event, "streetNumber")}
              InputProps={{
                type: "text",
                "aria-labelledby": "input-slider",
              }}
            />

            <TextField
              value={street}
              label="Voie"
              onChange={(event) => handleChange(event, "street")}
              fullWidth
              InputProps={{
                type: "text",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>

          <div className="center">
            <TextField
              value={addressComplement}
              label="Complément d'adresse (facultatif)"
              onChange={(event) => handleChange(event, "addressComplement")}
              fullWidth
              InputProps={{
                type: "text",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>

          <div className="center grid grid-cols-2 space-x-2">
            <TextField
              value={postalCode}
              label="Code postal"
              onChange={(event) => handleChange(event, "postalCode")}
              sx={{
                width: "180px",
              }}
              InputProps={{
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
            <TextField
              value={city}
              label="Ville"
              onChange={(event) => handleChange(event, "city")}
              fullWidth
              InputProps={{
                type: "text",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>
          
          <SelectCountry
            state={startAd}
            handleChange={(event) => {
              handleChange(event, "country");
            }}
          />
        </div>

        {!isEdit ? (
          <div className="flex w-full justify-end pt-6">
            <NextButton isDisabled={!isValidate} handleClick={validate} />
          </div>
        ) : (
          <div className="flex w-full justify-center pt-10">
            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
              disabled={!isValidate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const Address = ({}) => {
  return (
    <AdModal children={<AddressContent />} stepNumber={1} height="670px" />
  );
};
export default Address;
