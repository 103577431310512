import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { IonIcon } from "@ionic/react";
import {
  arrowForwardOutline,
  chevronDownOutline,
  chevronUpOutline,
  ellipse,
  closeOutline,
} from "ionicons/icons";
import ServiceAndCategoryCard from "../components/ServiceAndCategoryCard";
import useWindowsSize from "../hooks/useWindowsSize";
import CircularProgress from "@mui/material/CircularProgress";
import { BUILD_ENV, hostname } from "../App";
import MenuScroll from "../components/MenuScroll";
import HeroTitle from "../components/HeroTitle";
import CarouselSlider from "../components/CarouselSlider";
import useAd from "../hooks/useAd";
import CompleteAnAdModal from "../components/complete-ad/CompleteAnAdModal";
import AdModal from "../components/ad/CreateAnAdModal";
import useAuth from "../hooks/useAuth";
import Calendar from "../components/dashboard/calendar/Calendar";
import DatePicker from "../components/property-details/DayPicker";
import { format, subDays, addDays, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import useBooking from "../hooks/useBooking";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CalendarUrls from "../components/dashboard/CalendarUrls";
import { slotrFeePercentage } from "../App";

const EditPropertyDetails = () => {
  const { currentUser } = useAuth();
  const { deleteBlockedDates, getDatesFromDatabase } = useBooking();
  const navigate = useNavigate();
  const screenSize = useWindowsSize();
  const { propertyUid } = useParams();
  const [limitNbOfServices, setLimitNbOfServices] = useState();
  const [dropdownService, setDropdownService] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState();
  const {
    setActiveStep,
    editAd,
    setEditAd,
    startAd,
    completeAd,
    setStartAd,
    setCompleteAd,
  } = useAd();
  const [calendarMenuModalIsOpen, setCalendarMenuModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [importedCalendars, setImportedCalendars] = useState();
  const [propertyPrice, setPropertyPrice] = useState();

  const sections = [
    { name: "Détails du bien", id: "property-details" },
    { name: "Description", id: "description" },
    { name: "Services", id: "services" },
    { name: "Documents", id: "documents" },
    { name: "Détails du voyage", id: "travel-details" },
    { name: "Politique et règlements", id: "policy" },
    { name: "Calendrier et tarifs", id: "calendar" },
  ];

  useEffect(() => {
    const getProperty = async () => {
      try {
        const apiName = "propertiesManager";
        const path = `/properties/${propertyUid}`;

        let data;

        if (BUILD_ENV === "localhost") {
          data = await fetch(`http://${hostname}:3001${path}`);
          data = await data.json();
        } else {
          data = await API.get(apiName, path);
        }

        if (
          data &&
          (data.userUid === currentUser?.uid ||
            data.coHosts.includes(currentUser?.uid))
        ) {
          let newStartAd = { ...startAd };
          let newCompleteAd = { ...completeAd };

          for (let key in data) {
            if (newStartAd.hasOwnProperty(key)) {
              newStartAd[key] = data[key];
            }

            if (newCompleteAd.hasOwnProperty(key)) {
              newCompleteAd[key] = data[key];
            }
          }

          setStartAd(newStartAd);
          setCompleteAd(newCompleteAd);
          setEditAd(data);
          setImportedCalendars(data?.importedCalendars);
          setPropertyPrice({
            price: data?.price.toString(),
            weekPrice: data?.weekPrice ? data?.weekPrice : "",
            specialPrice: data?.price.toString(),
          });
        } else {
          throw new Error("No matching property.");
        }
      } catch (error) {
        console.log(error);
        navigate("/error");
      }
    };

    if (propertyUid && currentUser) {
      getProperty();
    }
  }, [propertyUid, currentUser]);

  useEffect(() => {
    if (dropdownService) {
      setLimitNbOfServices(editAd?.services.length);
    } else {
      if (screenSize >= 768) {
        setLimitNbOfServices(8);
      } else {
        setLimitNbOfServices(5);
      }
    }
  }, [dropdownService, editAd]);

  useEffect(() => {
    if (currentUser && editAd) {
      getDatesOfAProperty(editAd?.uid);
    }
  }, [currentUser, editAd, importedCalendars]);

  async function hanldeDeleteDate(event) {
    await deleteBlockedDates(event.uid, event.propertyId, editAd?.calendar);
    const newCalendarEvents = calendarEvents.filter((e) => e.uid !== event.uid);
    setCalendarEvents(newCalendarEvents);
    //ajouter gestion d'erreurs
  }

  const getDatesOfAProperty = async (propertiesUid) => {
    function setTitle(type) {
      switch (type) {
        case "blocked-dates":
          return "Dates bloquées";
        case "booking":
          return "Réservation";
        default:
          return "Évènement externe";
      }
    }

    const data = await getDatesFromDatabase("propertyUid", [propertiesUid]);

    if (data) {
      let newDataEvents = [];
      let newDatesSpecialPrices = [];

      data?.forEach((event) => {
        if (event.type === "price") {
          newDatesSpecialPrices.push(event);
        } else {
          newDataEvents.push({
            propertyId: event.propertyUid,
            uid: event.uid,
            title: setTitle(event.type),
            start: new Date(event.startDate),
            end: subDays(new Date(event.endDate), 1),
            type: event.type,
            source: event.source,
            color:
              event.type === "booking"
                ? "var(--first-color-60)"
                : event.type === "blocked-dates"
                ? "#A4A7C9"
                : "#F1E5C3",
          });
        }
      });

      setCalendarEvents(newDataEvents);
      setPropertyPrice({
        ...propertyPrice,
        datesSpecialPrice: newDatesSpecialPrices,
      });
    }
  };

  const TextWithClamp = ({ text }) => {
    const textRef = useRef(null);
    const [isClamped, setIsClamped] = useState(false);

    useEffect(() => {
      if (textRef.current) {
        const originalHeight = textRef.current.clientHeight;

        const numberOfLines = originalHeight / 24;

        setIsClamped(numberOfLines > 6);
      }
    }, [text]);

    return (
      <>
        <pre
          ref={textRef}
          className={`leading-6 ${isClamped && !learnMore && "line-clamp-6"}`}
        >
          {text}
        </pre>

        {!learnMore && isClamped && (
          <button
            className="flex items-center space-x-2"
            onClick={() => setLearnMore(true)}
          >
            <span>En savoir plus</span>

            <IonIcon
              icon={arrowForwardOutline}
              className="w-5 h-5 text-main/main-100"
            ></IonIcon>
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <CompleteAnAdModal />
      <AdModal />

      {!propertyPrice?.datesSpecialPrice ? (
        <div className="flex w-full grow justify-center items-center">
          <CircularProgress size={60} thickness={4} />
        </div>
      ) : (
        <main className="flex flex-col lg:space-y-14">
          {/*Title page */}
          <HeroTitle
            title={"Détails"}
            maxWidth={"max-w-smallScreen"}
            hidden={"hidden lg:flex"}
          />

          <div className="max-w-smallScreen mx-auto w-full lg:px-16">
            {/*Title Dekstop version */}
            <div className="hidden lg:flex px-0 w-full">
              <h1 className="font-bold">{editAd?.title}</h1>
            </div>

            <div className="lg:flex lg:space-x-6 xl:space-x-10 w-full">
              <div className="flex flex-col grow">
                {/*Gallery Dekstop version */}
                <div className="lg:pb-6">
                  <Gallery pictures={editAd?.picturesUrls} />
                </div>

                {/*Gallery Mobile version */}
                <div className="relative lg:hidden pb-6">
                  <div className="w-full h-[310px] md:h-[410px]">
                    <CarouselSlider
                      pictures={editAd?.picturesUrls}
                      backButton={true}
                      editButton={true}
                    />
                  </div>
                </div>

                {/*Title mobile version */}
                <div className="flex px-6 lg:px-0 lg:hidden pb-6">
                  <h5 className="font-bold">{editAd?.title}</h5>
                </div>

                <div className="flex flex-col space-y-7 lg:space-y-12">
                  {/*Navigation and property details section*/}
                  <section className="flex flex-col space-y-4 pl-6 lg:pl-0">
                    <MenuScroll sections={sections} defautlValue="property-details"/>

                    <div
                      className="space-y-4 pr-5 lg:pr-0"
                      id="property-details"
                    >
                      <h5 className="font-semibold">Détails du bien</h5>

                      <div className="grid grid-cols-2 md:grid-cols-3 gap-x-10 w-full">
                        <div className="flex">
                          <div className="flex flex-col items-center space-y-2">
                            <p>
                              {editAd?.entireAccomodation
                                ? "Logement entier"
                                : "Une partie du logement"}
                            </p>

                            <ServiceAndCategoryCard
                              name={editAd?.category}
                              type={"category"}
                            />

                            <button
                              className="flex space-x-2 items-center cursor-pointer"
                              onClick={() => {
                                setActiveStep(0);
                              }}
                            >
                              <img
                                src="/images/pencil-icon.svg"
                                alt="pencil-icon"
                                className="w-6 h-6"
                              />

                              <span className="text-design-system/highlight text-sm ">
                                Modifier
                              </span>
                            </button>
                          </div>
                        </div>

                        <div className="space-y-2">
                          <p>Adresse</p>

                          <div className="leading-7">
                            <p>
                              {editAd?.streetNumber + " " + editAd?.street},
                            </p>

                            <p>{editAd?.addressComplement}</p>

                            <p>
                              {editAd?.postalCode} {editAd?.city}
                            </p>

                            <p>{editAd?.country}</p>
                          </div>

                          <button
                            className="flex space-x-2 items-center cursor-pointer"
                            onClick={() => {
                              setActiveStep(1);
                            }}
                          >
                            <img
                              src="/images/pencil-icon.svg"
                              alt="pencil-icon"
                              className="w-6 h-6"
                            />

                            <span className="text-design-system/highlight text-sm ">
                              Modifier
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/*Desctription section*/}
                  <section className="space-y-4 px-6 lg:px-0 " id="description">
                    <h5 className="font-semibold">Description</h5>

                    <p className="font-semibold">{editAd?.title}</p>

                    <TextWithClamp text={editAd?.description} />

                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={() => {
                        setActiveStep(2);
                      }}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />

                      <span className="text-design-system/highlight text-sm ">
                        Modifier
                      </span>
                    </button>
                  </section>

                  {/*Services section*/}
                  <section className="space-y-4 px-6 lg:px-0" id="services">
                    <h5 className="font-semibold">Services</h5>

                    <div className={`grid grid-cols-4 md:grid-cols-8 gap-4`}>
                      {editAd?.services
                        .slice(0, limitNbOfServices)
                        .map((service, index) => (
                          <ServiceAndCategoryCard
                            key={index}
                            name={service}
                            type={"service"}
                          />
                        ))}
                    </div>

                    {((screenSize >= 768 && editAd?.services.length > 8) ||
                      (screenSize < 768 && editAd?.services.length > 5)) && (
                      <button
                        className="flex items-center space-x-2 cursor-pointer"
                        onClick={() => setDropdownService(!dropdownService)}
                      >
                        <span>Voir tous les services</span>

                        {dropdownService ? (
                          <IonIcon
                            icon={chevronUpOutline}
                            className="w-5 h-5 text-main/main-100"
                          ></IonIcon>
                        ) : (
                          <IonIcon
                            icon={chevronDownOutline}
                            className="w-5 h-5 text-main/main-100"
                          ></IonIcon>
                        )}
                      </button>
                    )}

                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={() => {
                        setActiveStep(9);
                      }}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />

                      <span className="text-design-system/highlight text-sm ">
                        Modifier
                      </span>
                    </button>
                  </section>

                  {/*Documents section*/}
                  <section className="space-y-4 px-6 lg:px-0" id="documents">
                    <h5 className="font-semibold">Documents</h5>

                    <div className="space-y-4">
                      <div className="space-y-2 items-center">
                        <p>N° d’enregistrement de la mairie</p>

                        <p>{editAd?.registrationNumber}</p>

                        <button
                          className="flex space-x-2 items-center cursor-pointer"
                          onClick={() => {
                            setActiveStep(14);
                          }}
                        >
                          <img
                            src="/images/pencil-icon.svg"
                            alt="pencil-icon"
                            className="w-6 h-6"
                          />

                          <span className="text-design-system/highlight text-sm ">
                            Modifier
                          </span>
                        </button>
                      </div>
                    </div>
                  </section>

                  {/*Travel details mobile version*/}
                  <section className="flex lg:hidden" id="travel-details">
                    <OthersDetails />
                  </section>

                  {/*Policy and regulations section*/}
                  <section className="px-6 lg:px-0 space-y-4" id="policy">
                    <h5 className="font-semibold">Politique et règlements</h5>

                    <ul className="space-y-2">
                      <li className="flex space-x-2">
                        <IonIcon
                          icon={ellipse}
                          className="flex-none w-[7px] h-[7px] mt-2 text-main/main-100"
                        ></IonIcon>

                        <p>
                          {parseInt(editAd?.termsOfCancelation) > 0
                            ? "Vous permettez au voyageur d’annuler sa réservation sans frais " +
                              parseInt(editAd?.termsOfCancelation) +
                              (parseInt(editAd?.termsOfCancelation) > 1
                                ? " jours"
                                : " jour") +
                              " avant son séjour."
                            : "Toute annulation de la part du voyageur ne donnera pas droit à un remboursement."}
                        </p>
                      </li>

                      {editAd?.rulesOfProcedure && (
                        <li className="flex space-x-2">
                          <IonIcon
                            icon={ellipse}
                            className="flex-none w-[7px] h-[7px] mt-2 text-main/main-100"
                          ></IonIcon>

                          <p>{editAd?.rulesOfProcedure}</p>
                        </li>
                      )}
                    </ul>

                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={() => {
                        setActiveStep(10);
                      }}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />

                      <span className="text-design-system/highlight text-sm">
                        Modifier
                      </span>
                    </button>
                  </section>
                </div>
              </div>

              {/*Travel details Desktop version*/}
              <section className="hidden lg:inline-block relative w-full lg:max-w-[360px] xl:max-w-[410px] flex-none space-y-10">
                <OthersDetails />
              </section>
            </div>

            {/*Calendar and prices section*/}
            <section
              className="px-6 lg:px-0 space-y-4 my-7 lg:my-12"
              id="calendar"
            >
              <div className="flex justify-between items-center">
                <h5 className="font-semibold">Calendrier et tarifs</h5>

                <button
                  onClick={() => {
                    return setCalendarMenuModalIsOpen(true);
                  }}
                  className="w-8 h-8 z-30 lg:hidden"
                >
                  <img
                    className="block cursor-pointer"
                    src="/images/navigation-signs/menu-icon.svg"
                    alt="sticker menu"
                  />
                </button>
              </div>

              <div className="flex w-full space-x-6">
                <Calendar
                  events={calendarEvents}
                  prices={propertyPrice}
                  onSelectedDate={(value) => setSelectedDate(value)}
                  handleDeleteDate={hanldeDeleteDate}
                  setCalendarMenuModalIsOpen={setCalendarMenuModalIsOpen}
                />

                <div className="hidden lg:flex flex-none">
                  <CalendarMenu
                    propertyData={editAd}
                    selectedDate={selectedDate}
                    calendarEvents={calendarEvents}
                    setCalendarEvents={setCalendarEvents}
                    importedCalendars={importedCalendars}
                    setImportedCalendars={setImportedCalendars}
                    onChangePrice={(value) => setPropertyPrice(value)}
                    defaultPrices={propertyPrice}
                    getDatesOfAProperty={getDatesOfAProperty}
                  />
                </div>
              </div>
            </section>
          </div>
        </main>
      )}

      <Modal
        open={calendarMenuModalIsOpen}
        onClose={() => setCalendarMenuModalIsOpen(false)}
        className={`flex lg:hidden `}
        sx={{ outline: "none" }}
      >
        <div className="flex flex-col bg-white overflow-y-auto w-full h-full scrollbar-hide">
          <CalendarMenu
            propertyData={editAd}
            selectedDate={selectedDate}
            calendarEvents={calendarEvents}
            setCalendarEvents={setCalendarEvents}
            importedCalendars={importedCalendars}
            setImportedCalendars={setImportedCalendars}
            onChangePrice={(value) => setPropertyPrice(value)}
            defaultPrices={propertyPrice}
            getDatesOfAProperty={getDatesOfAProperty}
            setCalendarMenuModalIsOpen={setCalendarMenuModalIsOpen}
          />
        </div>
      </Modal>
    </>
  );
};

const OthersDetails = () => {
  const { getUserInDatabase } = useAuth();
  const { setActiveStep, editAd } = useAd();
  const [coHosts, setCoHosts] = useState(null);

  useEffect(() => {
    const getCoHostsData = async () => {
      const coHostsUids = [...editAd?.coHosts, editAd?.userUid];
      const coHostsData = [];
      let mainContactData = null;

      for (let coHostUid of coHostsUids) {
        const data = await getUserInDatabase(coHostUid);

        if (data) {
          let coHostData = {
            name:
              data.userType === "host" && data.isProfessional
                ? data.companyName
                : `${data.firstName} ${data.lastName}`,
            email: data.email,
            isOwner: data.uid === editAd?.userUid ? true : false,
          };

          if (data.uid === editAd?.mainContact) {
            mainContactData = coHostData;
          } else {
            if (coHostData.isOwner) {
              coHostsData.unshift(coHostData);
            } else {
              coHostsData.push(coHostData);
            }
          }
        }
      }

      setCoHosts({ mainContact: mainContactData, data: coHostsData });
    };

    if (editAd) {
      getCoHostsData();
    }
  }, [editAd]);

  return (
    <div className="flex flex-col w-full lg:space-y-10 sticky top-32">
      <div className="p-6 lg:p-8 xl:p-10 space-y-8 md:rounded-lg bg-[#F5F5F6]">
        <div className="space-y-4">
          <h5 className="">Détails du bien</h5>

          <div className="flex flex-col space-y-4 w-full border-b border-neutral/gray/gray-60 pb-8">
            <div className="flex justify-between space-x-4 items-center">
              <p className="text-sm">Nombre de voyageur maximum</p>

              <span className="font-semibold">{editAd?.numberOfTravelers}</span>
            </div>

            <div className="flex justify-between space-x-4 items-center">
              <p className="text-sm">Nombre de chambres</p>

              <span className="font-semibold">{editAd?.numberOfBedrooms}</span>
            </div>

            <div className="flex justify-between space-x-4 items-center">
              <p className="text-sm">Nombre de lits</p>

              <span className="font-semibold">{editAd?.numberOfBeds}</span>
            </div>

            <button
              className="flex space-x-2 items-center cursor-pointer"
              onClick={() => {
                setActiveStep(3);
              }}
            >
              <img
                src="/images/pencil-icon.svg"
                alt="pencil-icon"
                className="w-6 h-6"
              />

              <span className="text-design-system/highlight text-sm ">
                Modifier
              </span>
            </button>
          </div>
        </div>

        <div className="space-y-6">
          <div className="space-y-4">
            <div className="flex justify-between space-x-4 items-center">
              <p className="text-sm">Animaux</p>

              <span className="font-semibold">
                {editAd?.arePetsAllowed ? "Oui" : "Non"}
              </span>
            </div>

            <button
              className="flex space-x-2 items-center cursor-pointer"
              onClick={() => {
                setActiveStep(12);
              }}
            >
              <img
                src="/images/pencil-icon.svg"
                alt="pencil-icon"
                className="w-6 h-6"
              />

              <span className="text-design-system/highlight text-sm ">
                Modifier
              </span>
            </button>
          </div>

          <div className="space-y-4">
            <h5>Options de réservation</h5>

            <div className="space-y-1">
              <div className="grid grid-cols-4 gap-4">
                <label className="text-sm col-span-1">Arrivée</label>
                <label className="text-sm col-span-1">Départ</label>
              </div>

              <div className="grid grid-cols-4 gap-4">
                <div className="bg-white border border-neutral/gray/gray-40 p-4 col-span-1">
                  <p className="text-sm">{editAd?.timeOfArrival}</p>
                </div>

                <div className="bg-white border border-neutral/gray/gray-40 p-4 col-span-1">
                  <p className="text-sm">{editAd?.timeOfDeparture}</p>
                </div>
              </div>
            </div>

            <div className="flex justify-between space-x-4 items-center">
              <p className="text-sm">Nombre de nuits minimum</p>

              <span className="font-semibold">{editAd?.minNbOfNights}</span>
            </div>

            <div className="flex justify-between space-x-4 items-center">
              <p className="text-sm">Nombre de nuits maximum</p>

              <span className="font-semibold">{editAd?.maxNbOfNights}</span>
            </div>

            <button
              className="flex space-x-2 items-center cursor-pointer col-span-2 justify-end"
              onClick={() => {
                setActiveStep(11);
              }}
            >
              <img
                src="/images/pencil-icon.svg"
                alt="pencil-icon"
                className="w-6 h-6"
              />

              <span className="text-design-system/highlight text-sm ">
                Modifier
              </span>
            </button>
          </div>

          <div className="space-y-4">
            <h5>Frais supplémentaires</h5>

            <div className="flex justify-between space-x-4">
              <p className="text-sm">Ménage</p>

              <span className="font-semibold">
                {editAd?.withCleaningFee
                  ? editAd?.cleaningFeeAmount + "€"
                  : "Aucun"}
              </span>
            </div>

            <div className="flex justify-between space-x-4">
              <p className="text-sm">Voyageur supplémentaire</p>

              {editAd?.withAdditionalTravelerFee ? (
                <p className="font-semibold">
                  {editAd?.additionalTravelerFeeAmount + "€"}
                  <span className="text-neutral/gray/gray-100 text-xs">
                    /voyageur
                  </span>
                </p>
              ) : (
                <p className="font-semibold">Aucun</p>
              )}
            </div>

            {editAd?.withAdditionalTravelerFee && (
              <div className="flex justify-between space-x-4">
                <p className="text-sm">Au delà de</p>

                <p className="font-semibold">
                  {editAd?.additionalTravelerFeeFrom
                    ? editAd?.additionalTravelerFeeFrom
                    : 0}

                  <span className="text-neutral/gray/gray-100 text-xs">
                    {parseInt(editAd?.additionalTravelerFeeFrom) > 1
                      ? " Voyageurs"
                      : " Voyageur"}
                  </span>
                </p>
              </div>
            )}

            <button
              className="flex space-x-2 items-center cursor-pointer"
              onClick={() => {
                setActiveStep(13);
              }}
            >
              <img
                src="/images/pencil-icon.svg"
                alt="pencil-icon"
                className="w-6 h-6"
              />

              <span className="text-design-system/highlight text-sm ">
                Modifier
              </span>
            </button>
          </div>

          <div className="space-y-4">
            <h5>Disponibilités</h5>

            <div className="flex justify-between space-x-4">
              <p className="text-sm">Délai de réservation</p>

              <span className="font-semibold">
                {editAd?.minTimeBeforeBooking !== 0
                  ? "Au moins " +
                    editAd?.minTimeBeforeBooking +
                    (parseInt(editAd?.minTimeBeforeBooking) > 1
                      ? " jours"
                      : " jour")
                  : "Aucun"}
              </span>
            </div>

            <div className="flex justify-between space-x-4">
              <p className="text-sm">Temps de préparation</p>

              <span className="font-semibold">
                {editAd?.timeOfPreparation !== 0
                  ? editAd?.timeOfPreparation +
                    (parseInt(editAd?.timeOfPreparation) > 1
                      ? " jours"
                      : " jour")
                  : "Aucun"}
              </span>
            </div>

            <button
              className="flex space-x-2 items-center cursor-pointer"
              onClick={() => {
                setActiveStep(18);
              }}
            >
              <img
                src="/images/pencil-icon.svg"
                alt="pencil-icon"
                className="w-6 h-6"
              />

              <span className="text-design-system/highlight text-sm">
                Modifier
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 lg:p-8 xl:p-10 space-y-8 md:rounded-lg lg:border lg:border-neutral/gray/gray-40">
        <div className="space-y-4">
          <h5>Gestion du bien</h5>

          {coHosts?.mainContact && (
            <div className="space-y-2 text-sm border-b border-neutral/gray/gray-40 pb-4">
              <p className="font-semibold">Contact principal </p>

              <div className="space-y-2">
                <p className="capitalize">{coHosts?.mainContact.name}</p>

                <p>{coHosts?.mainContact.email}</p>
              </div>
            </div>
          )}

          <div className="space-y-2 text-sm">
            <p className="font-semibold">Co-hôte </p>

            <div className="space-y-4">
              {coHosts?.data?.map((coHost, index) => (
                <div key={index} className="space-y-2">
                  <p className="capitalize">
                    {coHost.name}{" "}
                    <span className="text-neutral/gray/gray-100 normal-case">
                      {coHost.isOwner && "- titulaire du bien"}
                    </span>
                  </p>

                  <p>{coHost.email}</p>
                </div>
              ))}
            </div>
          </div>

          <button
            className="flex space-x-2 items-center cursor-pointer"
            onClick={() => {
              setActiveStep(15);
            }}
          >
            <img
              src="/images/pencil-icon.svg"
              alt="pencil-icon"
              className="w-6 h-6"
            />

            <span className="text-design-system/highlight text-sm">
              Modifier
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const Gallery = ({ pictures }) => {
  const { setActiveStep } = useAd();

  return (
    <>
      <div className="hidden lg:flex px-6 lg:px-0 cursor-pointer">
        <div className="relative w-full">
          <div className="grid grid-cols-3 auto-rows-[500px] w-full gap-4">
            <img
              alt="gallery"
              className="block col-span-2 h-full w-full rounded-l-lg object-cover object-center"
              src={pictures?.[0]}
            />

            <div className="col-span-1 grid grid-cols-1 auto-rows-fr gap-4">
              <img
                alt="gallery"
                className="h-full w-full rounded-tr-lg object-cover object-center"
                src={pictures?.[1]}
              />

              <img
                alt="gallery"
                className="block h-full w-full rounded-br-lg object-cover object-center"
                src={pictures?.[2]}
              />
            </div>
          </div>

          <div className="absolute bottom-4 right-4">
            <button
              className="uppercase text-neutral/gray/gray-100 bg-white text-sm font-semibold rounded-full py-2 px-8"
              onClick={() => {
                setActiveStep(19);
              }}
            >
              modifier
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const CalendarMenu = ({
  propertyData,
  selectedDate,
  setCalendarEvents,
  calendarEvents,
  setCalendarMenuModalIsOpen,
  importedCalendars,
  setImportedCalendars,
  getDatesOfAProperty,
  onChangePrice,
  defaultPrices,
}) => {
  const { updatePropertyFromDatabase } = useAd();
  const {
    addDatesFromDatabase,
    addEventToCalendar,
    updateImportedCalendars,
    updateDatesFromDatabase,
  } = useBooking();
  const normalPriceInputRef = useRef(null);
  const specialPriceInputRef = useRef(null);
  const weekPriceInputRef = useRef(null);
  const [blockedDatesRange, setBlockedDatesRange] = useState({
    from: null,
    to: null,
  });
  const [specialPriceDatesRange, setSpecialPriceDatesRange] = useState({
    from: null,
    to: null,
  });
  const [normalPriceisOpen, setNormalPriceisOpen] = useState(null);
  const [specialPriceisOpen, setSpecialPriceisOpen] = useState(null);
  const [weekPriceIsOpen, setWeekPriceIsOpen] = useState(null);
  const [currentContent, setCurrentContent] = useState("mainContent");
  const [propertyPrice, setPropertyPrice] = useState(defaultPrices);
  const [lastCalendarsUpdate, setLastCalendarsUpdate] = useState(
    propertyData?.lastCalendarsUpdate
  );
  const minPrice = 10;
  const maxPrice = 9999;

  useEffect(() => {
    if (selectedDate) {
      setBlockedDatesRange({ from: selectedDate, to: selectedDate });
      setSpecialPriceDatesRange({ from: selectedDate, to: selectedDate });
    }
  }, [selectedDate]);

  useEffect(() => {
    onChangePrice(propertyPrice);
  }, []);

  const isDayBlocked = useCallback(() => {
    const daysBlocked = [];

    calendarEvents?.forEach((event) => {
      daysBlocked.push({
        from: new Date(event.start),
        to: new Date(event.end),
      });
    });

    return daysBlocked;
  }, [calendarEvents]);

  const handleBlockedDatesSelected = (date) => {
    let selectedRange = { ...date };

    if (blockedDatesRange?.from && blockedDatesRange?.to) {
      if (selectedRange?.to > blockedDatesRange?.to) {
        selectedRange = { from: selectedRange?.to, to: null };
      } else if (selectedRange?.from < blockedDatesRange?.from) {
        selectedRange = { from: selectedRange?.from, to: null };
      }
    } else {
      if (selectedRange?.from && selectedRange?.to) {
        const isCollisionWithBlockedDay = calendarEvents?.some((event) => {
          const blockedDateFrom = new Date(event.start);
          return (
            blockedDateFrom > selectedRange.from &&
            blockedDateFrom < selectedRange.to
          );
        });

        if (isCollisionWithBlockedDay) {
          selectedRange = { from: selectedRange?.to, to: null };
        }
      }
    }

    setBlockedDatesRange(selectedRange);
  };

  const handleSpecialPriceDaySelected = (date) => {
    let selectedRange = date;

    if (specialPriceDatesRange?.from && specialPriceDatesRange?.to) {
      if (selectedRange?.to > specialPriceDatesRange?.to) {
        selectedRange = { from: selectedRange?.to, to: null };
      } else if (selectedRange?.from < specialPriceDatesRange?.from) {
        selectedRange = { from: selectedRange?.from, to: null };
      }
    }

    setSpecialPriceDatesRange(selectedRange);
  };

  const onBlur = (key, price) => {
    if (price === "") {
      setPropertyPrice({
        ...propertyPrice,
        [`${key}`]: Number(propertyData?.price),
      });
    } else if (price < minPrice) {
      setPropertyPrice({ ...propertyPrice, [`${key}`]: 10 });
    }
  };

  const handleOnChange = (key, e) => {
    let newValue = parseInt(e.target.value);

    if (newValue >= 0 && newValue <= maxPrice) {
      setPropertyPrice({
        ...propertyPrice,
        [`${key}`]: newValue,
      });
    }

    if (isNaN(newValue)) {
      setPropertyPrice({
        ...propertyPrice,
        [`${key}`]: "",
      });
    }
  };

  const totalPrice = (price) => {
    const slotrFee = price * (slotrFeePercentage / 100);
    const vatSlotrFee = parseFloat(slotrFee) * 0.2;
    const totalSlotrFee = parseFloat(slotrFee) + parseFloat(vatSlotrFee);
    const totalPrice = parseFloat(price) + parseFloat(totalSlotrFee);

    if (!isNaN(totalPrice)) return totalPrice.toFixed(2);
  };

  const autoInputWidth = (ref, value) => {
    const inputRef = ref?.current ? ref : normalPriceInputRef;
    const valueToString = value.toString();

    if (inputRef?.current) {
      const context = document.createElement("canvas").getContext("2d");
      const style = window.getComputedStyle(inputRef.current);
      context.font = `${style.fontSize} ${style.fontFamily}`;
      const metrics = context.measureText(valueToString);

      return `${metrics.width + 10}px`;
    } else {
      if (valueToString?.length === 2) {
        return "110px";
      } else if (valueToString?.length === 3) {
        return "130px";
      } else if (valueToString?.length === 4) {
        return "170px";
      }
    }
  };

  const formattedDate = (date) => {
    return format(date, "d MMMM", { locale: fr });
  };

  function addTimeToDate(date, timeString) {
    let newDate = new Date(date?.getTime());
    const [hours, minutes] = timeString.split(":");

    newDate?.setHours(parseInt(hours), parseInt(minutes), 0, 0);

    return format(newDate, "yyyy-MM-dd HH:mm:ss");
  }

  const normaliserDate = (date) => {
    return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
  };

  const validateBlockDates = async () => {
    const startDate = addTimeToDate(
      blockedDatesRange?.from,
      propertyData?.timeOfArrival
    );
    const endDate = addTimeToDate(
      blockedDatesRange?.to,
      propertyData?.timeOfDeparture
    );

    if (startDate && endDate) {
      let newDataEvents = [...calendarEvents];

      try {
        const objBlockedDates = {
          startDate: startDate,
          endDate: addTimeToDate(
            addDays(new Date(endDate), 1),
            propertyData?.timeOfDeparture
          ),
          hostUid: propertyData?.userUid,
          propertyUid: propertyData?.uid,
          source: "SlotR",
          type: "blocked-dates",
        };

        const response = await addDatesFromDatabase(objBlockedDates);

        if (response) {
          newDataEvents.push({
            propertyId: propertyData?.uid,
            uid: response,
            title: "Dates bloquées",
            start: startDate,
            end: endDate,
            type: "blocked-dates",
            source: "SlotR",
            color: "#A4A7C9",
          });

          await addEventToCalendar(propertyData?.calendar, {
            summary: "blocked-dates",
            start: startDate,
            end: addTimeToDate(
              addDays(new Date(endDate), 1),
              propertyData?.timeOfDeparture
            ),
            uid: response,
          });

          setCalendarEvents(newDataEvents);
          setBlockedDatesRange({ from: null, to: null });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validatePrice = async (key) => {
    const newPrice = propertyPrice[`${key}`];
    let objPrice = { [`${key}`]: newPrice };

    if (newPrice) {
      const res = await updatePropertyFromDatabase(propertyData?.uid, objPrice);

      if (res) {
        onChangePrice(propertyPrice);
      }
    }

    if (key === "price") {
      setNormalPriceisOpen(false);
    } else {
      setWeekPriceIsOpen(false);
    }
  };

  const validateSpecialPrice = async () => {
    if (specialPriceDatesRange?.from && specialPriceDatesRange?.to) {
      const startDate = addTimeToDate(
        specialPriceDatesRange?.from,
        propertyData?.timeOfArrival
      );
      const endDate = addTimeToDate(
        specialPriceDatesRange?.to,
        propertyData?.timeOfDeparture
      );
      let tempStartDate = new Date(startDate);

      let newDatesSpecialPrice = [...propertyPrice?.datesSpecialPrice];

      for (
        let date = tempStartDate;
        date < addDays(new Date(endDate), 1);
        tempStartDate.setDate(tempStartDate.getDate() + 1)
      ) {
        const currentDate = new Date(date);
        let dateUid;

        const specialPiceAlreadyExisting = newDatesSpecialPrice?.some(
          (event) => {
            if (event.type === "price") {
              const startDate = normaliserDate(new Date(event.startDate));
              const sameDay = isSameDay(normaliserDate(currentDate), startDate);

              if (sameDay) {
                dateUid = event.uid;
                return true;
              }
            }
          }
        );

        if (specialPiceAlreadyExisting) {
          const res = await updateDatesFromDatabase(dateUid, {
            price: propertyPrice?.specialPrice,
          });

          if (res) {
            let index = newDatesSpecialPrice.findIndex(
              (event) => event.uid === dateUid
            );

            if (index !== -1) {
              newDatesSpecialPrice[index].price = propertyPrice?.specialPrice;
            }
          }
        } else {
          const addDayToCurrentDate = addTimeToDate(
            addDays(currentDate, 1),
            propertyData?.timeOfDeparture
          );

          let objBlockedDates = {
            startDate: currentDate,
            endDate: addDayToCurrentDate,
            hostUid: propertyData?.userUid,
            propertyUid: propertyData?.uid,
            source: "SlotR",
            price: propertyPrice?.specialPrice,
            type: "price",
          };

          const res = await addDatesFromDatabase(objBlockedDates);

          if (res) {
            objBlockedDates["uid"] = res;
            newDatesSpecialPrice.push(objBlockedDates);
          }
        }
      }

      onChangePrice({
        ...propertyPrice,
        datesSpecialPrice: newDatesSpecialPrice,
      });

      setPropertyPrice({
        ...propertyPrice,
        datesSpecialPrice: newDatesSpecialPrice,
      });

      setSpecialPriceDatesRange({ from: null, to: null });
      setSpecialPriceisOpen(false);
    } else {
      alert("Veuillez choisir une date !");
    }
  };

  return (
    <div className="flex flex-col w-full lg:w-[360px] xl:w-[410px] h-auto lg:max-h-[1000px] lg:overflow-y-auto lg:scrollbar-hide flex-none rounded-lg bg-white lg:border lg:border-neutral/gray/gray-40">
      <div className="grid grid-cols-2 w-full px-8 lg:px-10 pt-5">
        <div className="w-6 h-6">
          {currentContent !== "mainContent" && (
            <button onClick={() => setCurrentContent("mainContent")}>
              <img
                src={"/images/arrowBack.svg"}
                className="w-full h-full text-main/dark-100 "
              />
            </button>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={() => setCalendarMenuModalIsOpen(false)}
            className="flex lg:hidden"
          >
            <IonIcon
              icon={closeOutline}
              className="w-8 h-8 text-main/dark-100 "
            />
          </button>
        </div>
      </div>

      <div className="flex justify-center pt-4 pb-6 border-b border-neutral/gray/gray-40">
        <h4 className="text-main/dark-100">
          {selectedDate ? `${formattedDate(selectedDate)}` : "À propos"}
        </h4>
      </div>

      {currentContent === "importCalendar" ? (
        <ImportCalendarContent
          propertyUid={propertyData?.uid}
          importedCalendars={importedCalendars}
          setImportedCalendars={setImportedCalendars}
          setCurrentContent={setCurrentContent}
          setLastCalendarsUpdate={setLastCalendarsUpdate}
        />
      ) : currentContent === "exportCalendar" ? (
        <ExportCalendarContent propertyData={propertyData} />
      ) : (
        <div className="flex flex-col space-y-6 py-6 px-8 lg:px-10">
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col space-y-1.5">
              <div className="">
                <label className="font-semibold">Fixer un prix de base</label>

                <p className="text-xs text-neutral/gray/gray-100 font-inter">
                  Ce prix s'applique à toutes les nuits, sauf celles avec un
                  tarif modifié.
                </p>
              </div>

              <div className="flex flex-col space-y-3 border border-neutral/gray/gray-40 p-4">
                <div className="flex space-x-5 items-end">
                  <div className="flex items-end">
                    <TextField
                      disabled={!normalPriceisOpen}
                      inputRef={normalPriceInputRef}
                      value={propertyPrice?.price}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#0C0318",
                        },
                        width: autoInputWidth(
                          normalPriceInputRef,
                          propertyPrice?.price
                        ),
                      }}
                      onChange={(e) => handleOnChange("price", e)}
                      onBlur={() => onBlur("price", propertyPrice?.price)}
                      inputProps={{
                        type: "number",
                        sx: {
                          fontSize: "64px",
                          padding: 0,
                          height: 60,
                          fontWeight: 700,
                          color: "var(--second-color)",
                        },
                      }}
                    />

                    <span className="text-sm text-design-system/highlight leading-none">
                      €/nuit
                    </span>
                  </div>

                  {!normalPriceisOpen && (
                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={() => setNormalPriceisOpen(true)}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />
                    </button>
                  )}
                </div>

                {normalPriceisOpen && (
                  <div className="space-y-6">
                    <p className="text-neutral/gray/gray-100 text-xs">
                      Prix à payer par le voyageur
                      {" " + totalPrice(propertyPrice?.price)}
                    </p>

                    <button
                      className="purple-button !w-32"
                      onClick={() => validatePrice("price")}
                    >
                      ENREGISTRER
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col space-y-1.5">
              <label className="font-semibold">Fixer un prix par période</label>

              <div className="flex flex-col space-y-3 border border-neutral/gray/gray-40 p-4">
                <div className="flex space-x-5 items-end">
                  <div className="flex items-end">
                    <TextField
                      disabled={!specialPriceisOpen}
                      inputRef={specialPriceInputRef}
                      value={propertyPrice?.specialPrice}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#0C0318",
                        },
                        width: autoInputWidth(
                          specialPriceInputRef,
                          propertyPrice?.specialPrice
                        ),
                      }}
                      onChange={(e) => handleOnChange("specialPrice", e)}
                      onBlur={() =>
                        onBlur("specialPrice", propertyPrice?.specialPrice)
                      }
                      inputProps={{
                        type: "number",
                        sx: {
                          fontSize: "64px",
                          padding: 0,
                          height: 60,
                          fontWeight: 700,
                          color: "var(--second-color)",
                        },
                      }}
                    />

                    <span className="text-sm text-design-system/highlight leading-none">
                      €/nuit
                    </span>
                  </div>

                  {!specialPriceisOpen && (
                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={() => setSpecialPriceisOpen(true)}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />
                    </button>
                  )}
                </div>

                {specialPriceisOpen && (
                  <div className="space-y-6">
                    <p className="text-neutral/gray/gray-100 text-xs">
                      Prix à payer par le voyageur
                      {" " + totalPrice(propertyPrice?.specialPrice)}
                    </p>

                    <div className="space-y-1.5">
                      <label>Appliqué le</label>

                      <div>
                        <DatePicker
                          handleDatesSelected={handleSpecialPriceDaySelected}
                          selected={specialPriceDatesRange}
                        />
                      </div>
                    </div>

                    <button
                      className="purple-button !w-32"
                      onClick={validateSpecialPrice}
                    >
                      ENREGISTRER
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col space-y-1.5">
              <div className="">
                <label className="font-semibold">Prix spécial week-end</label>

                <p className="text-xs text-neutral/gray/gray-100 font-inter">
                  Le prix sera appliqué pour les nuits du vendredi et samedi.
                </p>
              </div>

              <div className="flex flex-col space-y-3 border border-neutral/gray/gray-40 p-4">
                <div className="flex space-x-5 items-end">
                  {weekPriceIsOpen || propertyData?.weekPrice ? (
                    <div className="flex items-end">
                      <TextField
                        disabled={!weekPriceIsOpen}
                        inputRef={weekPriceInputRef}
                        value={propertyPrice?.weekPrice || propertyPrice?.price}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          },
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "var(--second-color)",
                          },
                          width: autoInputWidth(
                            weekPriceInputRef,
                            propertyPrice?.weekPrice || propertyPrice?.price
                          ),
                        }}
                        onChange={(e) => handleOnChange("weekPrice", e)}
                        onBlur={() =>
                          onBlur("weekPrice", propertyPrice?.weekPrice)
                        }
                        inputProps={{
                          type: "number",
                          sx: {
                            fontSize: "64px",
                            padding: 0,
                            height: 60,
                            fontWeight: 700,
                            color: "var(--second-color)",
                          },
                        }}
                      />

                      <span className="text-sm text-design-system/highlight leading-none">
                        €/nuit
                      </span>
                    </div>
                  ) : (
                    <button
                      className="text-sm"
                      onClick={() => setWeekPriceIsOpen(true)}
                    >
                      Ajoutez un prix
                    </button>
                  )}

                  {!weekPriceIsOpen && (
                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={() => setWeekPriceIsOpen(true)}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />
                    </button>
                  )}
                </div>

                {weekPriceIsOpen && (
                  <div className="space-y-6">
                    <p className="text-neutral/gray/gray-100 text-xs">
                      Prix à payer par le voyageur
                      {" " + totalPrice(propertyPrice?.weekPrice)}
                    </p>

                    <button
                      className="purple-button !w-32"
                      onClick={() => validatePrice("weekPrice")}
                    >
                      ENREGISTRER
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              <div className="space-y-1.5">
                <label className="font-semibold">Bloquer des dates</label>

                <div className="max-w-[190px]">
                  <DatePicker
                    isDayBlocked={isDayBlocked}
                    handleDatesSelected={handleBlockedDatesSelected}
                    selected={blockedDatesRange}
                  />
                </div>
              </div>

              {blockedDatesRange?.from && blockedDatesRange?.to && (
                <button
                  className="purple-button !w-32"
                  onClick={validateBlockDates}
                >
                  ENREGISTRER
                </button>
              )}
            </div>
          </div>

          <div className="space-y-6 border-t border-neutral/gray/gray-40 py-6">
            <CalendarUrls
              importedCalendars={importedCalendars}
              setImportedCalendars={setImportedCalendars}
              propertyData={propertyData}
              setCurrentContent={setCurrentContent}
              lastCalendarsUpdate={lastCalendarsUpdate}
            />

            {importedCalendars?.length > 0 ? (
              <button
                className="secondary-purple-button flex justify-center space-x-4 text-sm !h-[40px] !w-[300px] !font-medium "
                onClick={() => {
                  updateImportedCalendars(propertyData?.uid).then(
                    async (res) => {
                      await getDatesOfAProperty(propertyData?.uid);
                      setLastCalendarsUpdate(res.lastCalendarsUpdate);
                    }
                  );
                }}
              >
                <img
                  className="w-6 h-6"
                  src="/images/navigation-signs/update.svg"
                  alt="sticker update"
                />

                <p>ACTUALISER MES CALENDRIERS</p>
              </button>
            ) : (
              <button
                className="secondary-purple-button flex justify-center space-x-4 items-center transition-none !text-sm !h-[40px] !w-[300px] !font-medium "
                onClick={() => setCurrentContent("importCalendar")}
              >
                <img
                  className="w-6 h-6"
                  src="/images/navigation-signs/download.svg"
                  alt="sticker import"
                />

                <p>IMPORTER UN CALENDRIER</p>
              </button>
            )}

            <button
              className="secondary-purple-button flex justify-center space-x-4 items-center transition-none !text-sm !h-[40px] !w-[300px] !font-medium "
              onClick={() => setCurrentContent("exportCalendar")}
            >
              <img
                className="w-6 h-6"
                src="/images/navigation-signs/export.svg"
                alt="sticker export"
              />

              <p>EXPORTER MON CALENDRIER</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ImportCalendarContent = ({
  propertyUid,
  importedCalendars,
  setImportedCalendars,
  setCurrentContent,
  setLastCalendarsUpdate,
}) => {
  const { updatePropertyFromDatabase } = useAd();
  const { updateImportedCalendars } = useBooking();
  const [calendarUrl, setCalendarUrl] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  async function isICalendarType(url) {
    try {
      const apiName = "bookingsManager";
      const path = "/calendar/calendarType";
      const options = {
        queryStringParameters: {
          calendarUrl: url,
        },
      };

      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(
          `http://${hostname}:3003${path}?calendarUrl=${url}`
        );

        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      if (response === "valid") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  const addCalendar = async () => {
    if (!calendarName) {
      setErrorMessage("Veuillez rentrer un nom de calendrier.");
    } else if (!isValidHttpUrl(calendarUrl)) {
      setErrorMessage("Veuillez rentrer un lien de calendrier valide.");
    } else if (
      importedCalendars.findIndex((obj) => obj["url"] === calendarUrl) != -1
    ) {
      setErrorMessage("Ce calendrier a déjà été importé.");
    } else {
      const isValidType = await isICalendarType(calendarUrl);

      if (!isValidType) {
        setErrorMessage(
          "Ce fichier est au mauvais format, seul le format iCalendar est accepté"
        );
      } else {
        const newImportedCalendars = [...importedCalendars];
        const newCalendar = {
          url: calendarUrl,
          name: calendarName,
        };

        newImportedCalendars.push(newCalendar);

        const res = await updatePropertyFromDatabase(propertyUid, {
          importedCalendars: newImportedCalendars,
        });

        if (res) {
          updateImportedCalendars(propertyUid).then(async (res) => {
            setImportedCalendars(newImportedCalendars);
            setLastCalendarsUpdate(res.lastCalendarsUpdate);
            setCurrentContent("mainContent");
          });
        }
      }
    }
  };

  return (
    <div className="flex flex-col space-y-6 py-6 px-8 lg:px-10">
      <h5>Importer mon calendrier</h5>

      <div className="flex flex-col space-y-1">
        <label className="text-sm">Url du calendrier</label>

        <TextField
          value={calendarUrl}
          onChange={(event) => setCalendarUrl(event.target.value)}
          fullWidth
          InputProps={{
            type: "text",
            sx: { fontSize: "14px" },
          }}
        />
      </div>

      <div className="flex flex-col space-y-1">
        <label className="text-sm">Nom du calendrier</label>

        <TextField
          value={calendarName}
          onChange={(event) => setCalendarName(event.target.value)}
          fullWidth
          InputProps={{
            type: "text",
            sx: { fontSize: "14px" },
          }}
        />

        {errorMessage && <p className="text-semantic/error">{errorMessage}</p>}
      </div>

      <button
        className="purple-button center transition-none text-[12px] !h-[40px] !w-[136px] !font-medium "
        onClick={addCalendar}
      >
        <div className="center">ENREGISTRER</div>
      </button>
    </div>
  );
};

const ExportCalendarContent = ({ propertyData }) => {
  const calendarUrl = propertyData?.calendar;

  return (
    <div className="flex flex-col space-y-6 py-6 px-8 lg:px-10">
      <h5>Exporter mon calendrier SlotR</h5>

      <div className="flex flex-col space-y-1">
        <label className="text-sm">Url du calendrier</label>

        <TextField
          value={calendarUrl}
          InputProps={{
            type: "text",
          }}
          inputProps={{
            sx: { fontSize: "14px" },
          }}
        />
      </div>

      <CopyToClipboard text={calendarUrl}>
        <button className="purple-button center transition-none text-[12px] !h-[40px] !w-[97px]   !font-medium ">
          <div className="center">COPIER</div>
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default EditPropertyDetails;
