import React from "react";
import { Link } from "react-router-dom";
import Toggle from "./Toggle";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const languages = { fr: "FR", en: "EN" };
  const footerYear = new Date().getFullYear();
  const location = useLocation();
  const isSearchPage = location.pathname === "/search";

  const handleChangeLanguage = (e) => {
    /*if (e === languages.fr) {
      window.Weglot.switchTo(languages.fr.toLowerCase());
    } else {
      window.Weglot.switchTo(languages.en.toLowerCase());
    }*/
  };

  return (
    <footer
      className={`bg-white border-t border-neutral/gray/gray-40 pt-6 pb-[103px] lg:pb-6  ${
        !isSearchPage && "mt-10 lg:mt-16"
      }`}
    >
      <div className="space-y-10 lg:space-y-0 px-6 md:px-10 lg:px-16 max-w-bigScreen mx-auto">
        {/* Traduction Toggle mobile version */}
        <div className="hidden">
          <Toggle
            defaultValue={languages.fr}
            firstValue={languages.fr}
            secondValue={languages.en}
            onChange={handleChangeLanguage}
          />
        </div>

        <div className="flex lg:flex-col lg:space-y-5">
          <nav className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:justify-center w-1/2 lg:w-auto">
            <h5 className="flex lg:hidden font-semibold text-base">À propos</h5>

            <ul className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between w-full">
              <li>
                <Link
                  className="text-sm"
                  to="/documents/CGUS_Voyageurs.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  CGUS Voyageurs
                </Link>
              </li>

              <li>
                <Link
                  className="text-sm"
                  to="/documents/CGVS_Hôtes.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  CGVS Hôtes
                </Link>
              </li>

              <li>
                <Link
                  className="text-sm"
                  to="/documents/Mentions_légales.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mentions légales
                </Link>
              </li>

              <li>
                <Link
                  className="text-sm"
                  to="/documents/Politique_de_confidentialité.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Politique de confidentialité
                </Link>
              </li>

              <li>
                <Link
                  className="text-sm"
                  to="/documents/Gestion_Des_Cookies.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gestion des cookies
                </Link>
              </li>
            </ul>
          </nav>

          <div className="flex w-1/2 lg:justify-between lg:items-center lg:w-full">
            {/* Copyright paragraph -  1280px minimum version */}
            <div className="hidden lg:flex">
              <p className="text-neutral/gray/gray-100 text-sm">
                Copyright © {footerYear} SlotR. Tous droits réservés.
              </p>
            </div>

            <nav className="flex flex-col space-y-6 lg:space-y-0">
              <h5 className="flex lg:hidden md:font-semibold text-base">
                Suis-nous
              </h5>

              <ul className="flex flex-wrap self-stretch gap-y-8 xs:gap-x-4 gap-x-6 lg:gap-x-1 xl:gap-x-2">
                <li>
                  <a
                    href="https://www.linkedin.com/company/slotr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="lg:p-1 rounded-full">
                      <img
                        src="/images/linkedin-icon.svg"
                        alt="linkedin-icon"
                        className="w-8 h-8 lg:w-6 lg:h-6 object-contain shrink-0"
                      />
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    href="https://twitter.com/slotr_io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="lg:p-1 rounded-full">
                      <img
                        src="/images/x-icon.svg"
                        alt="x-icon"
                        className="w-8 h-8 lg:w-6 lg:h-6 object-contain shrink-0"
                      />
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    href="https://discord.gg/VCs3mKKeSe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="lg:p-1 rounded-full">
                      <img
                        src="/images/discord-icon.svg"
                        alt="discord-icon"
                        className="w-8 h-8 lg:w-6 lg:h-6 object-contain shrink-0"
                      />
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/slotr.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="lg:p-1 rounded-full">
                      <img
                        src="/images/facebook-icon.svg"
                        alt="facebook-icon"
                        className="w-8 h-8 lg:w-6 lg:h-6 object-contain shrink-0"
                      />
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/slotr.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="lg:p-1 rounded-full">
                      <img
                        src="/images/instagram-icon.svg"
                        alt="instagram-s"
                        className="w-8 h-8 lg:w-6 lg:h-6 object-contain shrink-0"
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* Copyright paragraph -  1280px maximum version */}
        <div className="flex lg:hidden w-full justify-center pt-2 border-t border-neutral/gray/gray-40">
          <p className=" text-neutral/gray/gray-100 text-sm text-center">
            Copyright © {footerYear} SlotR. Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
