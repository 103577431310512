import React, { useEffect, useRef } from "react";

const Navdots = ({
  totalSteps,
  activePicture,
  visibleSteps = 7,
  setActivePicture,
}) => {
  const stepperRef = useRef(null);

  useEffect(() => {
    if (stepperRef.current) {
      const containerWidth = stepperRef.current.offsetWidth;
      const scrollWidth = stepperRef.current.scrollWidth;
      const maxScrollLeft = scrollWidth - containerWidth;
      const indicatorWidth = scrollWidth / totalSteps;

      const centerPosition =
        (activePicture + 0.5) * indicatorWidth - containerWidth / 2;
      const scrollPosition = Math.min(
        Math.max(0, centerPosition),
        maxScrollLeft
      );

      stepperRef.current.scrollLeft = scrollPosition;
    }
  }, [activePicture, totalSteps]);

  const renderIndicators = () => {
    return Array.from({ length: totalSteps }, (_, i) => (
      <div
        key={i}
        onClick={() => setActivePicture(i)}
        className={`flex-none mx-0.5 rounded-full cursor-pointer ${
          activePicture === i ? "w-3 h-3 bg-main/main-100" : "w-2 h-2 bg-white"
        }`}
      ></div>
    ));
  };

  return (
    <div className="flex justify-center w-28">
      <div
        ref={stepperRef}
        className="flex overflow-x-auto scroll-smooth scrollbar-hide items-center gap-1"
      >
        {renderIndicators()}
      </div>
    </div>
  );
};

export default Navdots;
