import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./hooks/usePrivateRoute";
import { setupIonicReact } from "@ionic/react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MobileNavBar from "./components/MobileNavBar";
import Home from "./pages/Home";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Bookings from "./pages/Bookings";
import PropertyDetails from "./pages/PropertyDetails";
import EditPropertyDetails from "./pages/EditPropertyDetails";
import BookingSummary from "./pages/BookingSummary";
import Error from "./pages/Error";
import TransactionResponse from "./pages/TransactionResponse";
import StripeResponse from "./pages/StripeResponse";
import { AdProvider } from "./hooks/useAd";
import { BookingProvider } from "./hooks/useBooking";
import { ThemeProvider } from "@mui/material/styles";
import { THEME } from "./MUITheme";
import bookingsManager from "./aws-exports";
import Properties from "./pages/Properties";
import useAuth from "./hooks/useAuth";

setupIonicReact();

export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
export const notify_url =
  bookingsManager.aws_cloud_logic_custom.find(
    ({ name }) => name === "bookingsManager"
  ).endpoint + "/notify";
export const hostname = window.location.hostname;
export const slotrFeePercentage = 5;

export default function App() {
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <ThemeProvider theme={THEME}>
        <div className="relative w-full min-h-screen flex flex-col">
          <Header />

          <Routes>
            <Route
              path="/"
              element={
                <AdProvider>
                  <Home />
                </AdProvider>
              }
            />
            <Route
              path="/search"
              element={
                <AdProvider>
                  <Search />
                </AdProvider>
              }
            />
            <Route
              path="/property/:propertyUid"
              element={
                <BookingProvider>
                  <PropertyDetails />
                </BookingProvider>
              }
            />

            <Route element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/dashboard"
                element={
                  <AdProvider>
                    <BookingProvider>
                      <Dashboard />
                    </BookingProvider>
                  </AdProvider>
                }
              />
              <Route
                path="/bookings"
                element={
                  <BookingProvider>
                    <Bookings />
                  </BookingProvider>
                }
              />
              <Route
                path="/properties"
                element={
                  <BookingProvider>
                    <AdProvider>
                      <Properties />
                    </AdProvider>
                  </BookingProvider>
                }
              />
              <Route
                path="/property/:propertyUid/edit"
                element={
                  <AdProvider>
                    <BookingProvider>
                      <EditPropertyDetails />
                    </BookingProvider>
                  </AdProvider>
                }
              />
              <Route
                path="/property/:propertyUid/transactions/:transactionUid"
                element={<TransactionResponse />}
              />
              <Route
                path="/property/:propertyUid/paymentResponse/:status"
                element={<StripeResponse />}
              />
              <Route
                path="/booking-summary"
                element={
                  <AdProvider>
                    <BookingProvider>
                      <BookingSummary />
                    </BookingProvider>
                  </AdProvider>
                }
              />
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>

          <Footer />
        </div>

        <MobileNavBar />
      </ThemeProvider>
    </>
  );
}
