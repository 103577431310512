import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import useAuth from "../hooks/useAuth";
import Toggle from "./Toggle";
import LoginSubscribeModal from "./login-subscribe/LoginSubscribeModals";
import {
  chevronDownOutline,
  chevronUpOutline,
  personOutline,
} from "ionicons/icons";
import useScrollPosition from "../hooks/useScrollPosition";

function Header() {
  const location = useLocation();
  const { currentUser, loginSubscribeDispatch, Logout } = useAuth();
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const isTraveler = currentUser?.userType === "traveler";
  const languages = { fr: "FR", en: "EN" };
  const scrollPosition = useScrollPosition();
  const navigate = useNavigate();

  function handleDropdown() {
    return setDropdownMenu(!dropdownMenu);
  }

  const handleChangeLanguage = (e) => {
    /*if (e === languages.fr) {
      window.Weglot.switchTo(languages.fr.toLowerCase());
    } else {
      window.Weglot.switchTo(languages.en.toLowerCase());
    }*/
  };

  const handleLogOut = () => {
    Logout();
    handleDropdown();
  };

  return (
    <header
      className={`sticky top-0 z-50 bg-white ${scrollPosition > 0 && "shadow"}`}
    >
      <div
        className={`flex max-w-bigScreen mx-auto px-6 md:px-10 lg:px-16 w-full h-[80px] lg:h-[100px] justify-between items-center ${
          location.pathname.includes("/property") && "hidden lg:flex"
        }`}
      >
        <div className="">
          <Link to="/" className="">
            <img
              src="/images/logo-dark.svg"
              alt="slotr-logo-dark"
              className="w-[120px] h-[44px] lg:h-[54px] lg:w-[140px] shrink-0"
            />
          </Link>
        </div>

        <ul className="lg:flex lg:space-x-12 lg:items-center">
          <li className="hidden lg:flex text-base leading-none">
            <a href="https://about.slotr.io" target="_blank" rel="noreferrer">
              À propos de SlotR
            </a>
          </li>

          <li className="hidden text-base space-x-2 items-center">
            <Toggle
              defaultValue={languages.fr}
              firstValue={languages.fr}
              secondValue={languages.en}
              onChange={handleChangeLanguage}
            />
          </li>

          <li className="flex">
            {currentUser ? (
              <div className="relative">
                {/* Profile icon desktop version */}
                <div
                  className="hidden lg:flex items-center space-x-1 bg-neutral/gray/gray-0 rounded-full py-2 pl-2 pr-4 cursor-pointer"
                  onClick={handleDropdown}
                >
                  <img
                    className="w-10 h-10 object-contain flex-shrink-0 rounded-full"
                    src={
                      currentUser?.userType === "host"
                        ? "/images/host-profile.svg"
                        : "/images/traveler-profile.svg"
                    }
                    alt="profile-icon"
                  />

                  <p className="px-3 capitalize">
                    {currentUser?.userType === "host" &&
                    currentUser?.isProfessional
                      ? currentUser?.companyName
                      : currentUser?.firstName}
                  </p>

                  <IonIcon
                    icon={dropdownMenu ? chevronUpOutline : chevronDownOutline}
                    className="w-7 h-7 text-main/dark-100"
                  ></IonIcon>
                </div>

                {/* Profile icon mobile version */}
                <Link to="/profile">
                  <button className="flex lg:hidden p-2 rounded-full bg-neutral/gray/gray-10">
                    <img
                      src={
                        currentUser?.userType === "host"
                          ? "/images/host-profile.svg"
                          : "/images/traveler-profile.svg"
                      }
                      alt="profile-icon"
                      className="w-8 h-8"
                    />
                  </button>
                </Link>

                {dropdownMenu && (
                  <div className="absolute top-[70px] right-0 w-[210px] flex flex-col bg-neutral/gray/gray-10 border border-[#EFEFEF] rounded-lg p-4 space-y-2">
                    <Link to="/profile">
                      <button className="text-start" onClick={handleDropdown}>
                        Mon profil
                      </button>
                    </Link>
                    <hr />

                    {currentUser && isTraveler ? (
                      <>
                        <Link to="/bookings">
                          <button
                            className="text-start"
                            onClick={handleDropdown}
                          >
                            Mes séjours
                          </button>
                        </Link>
                        <hr />
                      </>
                    ) : (
                      <>
                        <Link to="/dashboard">
                          <button
                            className="text-start"
                            onClick={handleDropdown}
                          >
                            Mon tableau de bord
                          </button>
                        </Link>
                        <hr />

                        <Link to="/bookings">
                          <button
                            className="text-start"
                            onClick={handleDropdown}
                          >
                            Mes réservations
                          </button>
                        </Link>
                        <hr />

                        <Link to="/properties">
                          <button
                            className="text-start"
                            onClick={handleDropdown}
                          >
                            Mes annonces
                          </button>
                        </Link>
                        <hr />
                      </>
                    )}

                    <Link to="/">
                      <button className="text-start" onClick={handleDropdown}>
                        Accueil
                      </button>
                    </Link>
                    <hr />

                    <button className="text-start" onClick={handleLogOut}>
                      Déconnexion
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="flex p-2 rounded-full bg-neutral/gray/gray-0"
                onClick={() =>
                  loginSubscribeDispatch({
                    type: "GO TO",
                    to: "LoginOrSubscribe",
                  })
                }
              >
                <IonIcon
                  icon={personOutline}
                  className="w-7 h-7 text-main/dark-100"
                ></IonIcon>
              </button>
            )}
          </li>
        </ul>
      </div>

      <LoginSubscribeModal />
    </header>
  );
}

export default Header;
