import React from "react";
import useAuth from "../hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";

const usePrivateRoute = () => {
  const { currentUser, isLoading } = useAuth();

  return currentUser || isLoading ? <Outlet /> : <Navigate to="/" />;
};

export default usePrivateRoute;
