import { useState, useEffect, useRef } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { IonIcon } from "@ionic/react";
import {
  chevronBackOutline,
  chevronForwardOutline,
  arrowBackOutline,
  trashOutline,
} from "ionicons/icons";
import useAd from "../hooks/useAd";
import { useNavigate } from "react-router-dom";
import Navdots from "./Navdots";

const CarouselSlider = ({
  pictures,
  deleteButton = false,
  backButton = false,
  editButton = false,
  onClickPath,
  moreStyle,
  defaultPicture = 0,
  arrowsIsInside = true,
}) => {
  const { setStartAd, startAd, setActiveStep } = useAd();
  const [activePicture, setActivePicture] = useState(defaultPicture);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const carousel = useRef(null);
  const navigate = useNavigate();

  function handleDelete() {
    setStartAd({
      ...startAd,
      picturesUrls: startAd.picturesUrls.filter(
        (img, i) => i !== activePicture
      ),
      picturesFiles: startAd.picturesFiles.filter(
        (img, i) => i !== activePicture
      ),
    });

    back();
  }

  const next = () => {
    if (activePicture < pictures.length - 1) {
      setActivePicture(activePicture + 1);
    }
  };

  const back = () => {
    if (activePicture > 0) {
      setActivePicture(activePicture - 1);
    }
  };

  useEffect(() => {
    if (carousel?.current) {
      carousel.current.scrollLeft =
        carousel.current.offsetWidth * activePicture;
    }
  }, [activePicture]);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      next();
    } else if (touchStart - touchEnd < -50) {
      back();
    }
  };

  if (pictures?.length > 0) {
    return (
      <div
        className={`relative w-full h-full ${!arrowsIsInside && "px-24 pb-14"}`}
      >
        <div
          className={`carousel w-full h-full ${moreStyle}`}
          ref={carousel}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {pictures?.map((img, index) => (
            <button
              className="center w-full h-full relative carousel-item"
              key={index}
              onClick={onClickPath}
            >
              <img
                src={img}
                className={`w-full h-full cursor-pointer ${
                  arrowsIsInside ? "object-cover" : "object-contain"
                }`}
                alt=""
              />
            </button>
          ))}
        </div>

        {backButton && (
          <button
            className="absolute z-50 top-4 left-4 flex justify-center items-center rounded-full p-1.5 bg-main/main-100"
            onClick={() => navigate(-1)}
          >
            <IonIcon
              icon={arrowBackOutline}
              className="w-[20px] h-[20px] text-white"
            ></IonIcon>
          </button>
        )}

        {deleteButton && (
          <button className="absolute z-50 right-4 top-4 rounded-full p-1.5 bg-main/main-100 justify-center items-center flex">
            <IonIcon
              icon={trashOutline}
              className="w-5 h-5 text-white rounded-full"
              onClick={handleDelete}
            />
          </button>
        )}

        {editButton && (
          <div className="absolute top-4 right-4">
            <button
              className="uppercase text-neutral/gray/gray-100 bg-white text-sm font-semibold rounded-full py-2 px-8"
              onClick={() => {
                setActiveStep(19);
              }}
            >
              modifier
            </button>
          </div>
        )}

        <div className="absolute transform z-50 left-3 -translate-y-1/2 top-1/2 flex items-center cursor-pointer">
          <IonIcon
            icon={chevronBackOutline}
            className={`w-9 h-9 text-white hover:bg-white/40 rounded-full p-1 ${
              activePicture === 0 && "invisible"
            }`}
            onClick={back}
          />
        </div>

        <div className="absolute transform z-50 right-3 -translate-y-1/2 top-1/2 flex items-center cursor-pointer">
          <IonIcon
            icon={chevronForwardOutline}
            className={`w-9 h-9 text-white hover:bg-white/40 rounded-full p-1 ${
              activePicture === pictures?.length - 1 && "invisible"
            }`}
            onClick={next}
          />
        </div>

        <div className="absolute bottom-3 flex w-full justify-center">
          <Navdots
            totalSteps={pictures.length}
            activePicture={activePicture}
            setActivePicture={setActivePicture}
          />
        </div>
      </div>
    );
  }
};
export default CarouselSlider;
