import React from "react";
import AdModal from "../ad/AdModal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useAd from "../../hooks/useAd";

const TimeOfPreparationContent = () => {
  const {
    completeAd,
    setCompleteAd,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { minTimeBeforeBooking, timeOfPreparation } = completeAd;

  const minTimeBeforeBookingValues = [
    { value: 0, label: "Le jour même" },
    { value: 1, label: "Au moins 1 jour avant la réservation" },
    { value: 2, label: "Au moins 2 jours avant la réservation" },
    { value: 3, label: "Au moins 3 jours avant la réservation" },
    { value: 4, label: "Au moins 4 jours avant la réservation" },
    { value: 5, label: "Au moins 5 jours avant la réservation" },
    { value: 6, label: "Au moins 6 jours avant la réservation" },
    { value: 7, label: "Au moins 7 jours avant la réservation" },
  ];

  const timeOfPreparationValues = [
    { value: 0, label: "Aucun" },
    { value: 1, label: "1 jour avant et après chaque réservation" },
    { value: 2, label: "2 jours avant et après chaque réservation" },
    { value: 3, label: "3 jours avant et après chaque réservation" },
  ];

  const validate = async () => {
    if (
      editAd.minTimeBeforeBooking !== minTimeBeforeBooking ||
      editAd.timeOfPreparation !== timeOfPreparation
    ) {
      const newValues = {
        minTimeBeforeBooking,
        timeOfPreparation,
      };

      const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

      if (res) {
        setEditAd({
          ...editAd,
          ...newValues,
        });
      }
    }

    close();
  };

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-14">
          <div className="lg:px-4 space-y-6">
            <div className="space-y-2">
              <h4 className="text-center">Délai de réservation</h4>

              <div className="center text-center text-sm px-6">
                Indiquez la durée minimum qu’il vous faut pour recevoir une
                réservation.
              </div>
            </div>

            <div className="space-y-1">
              <p className="text-sm">J’accepte une réservation reçue :</p>

              <Select
                fullWidth
                value={minTimeBeforeBooking}
                onChange={(event) => {
                  setCompleteAd({
                    ...completeAd,
                    minTimeBeforeBooking: event.target.value,
                  });
                }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--first-color)",
                  },
                }}
              >
                {minTimeBeforeBookingValues?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="lg:px-4 space-y-6">
            <div className="space-y-2">
              <h4 className="center  px-6 text-center">Temps de préparation</h4>

              <p className="center text-center text-sm">
                Indiquez la durée que vous souhaitez bloquer avant et après
                chaque réservation.
              </p>
            </div>

            <div className="space-y-1">
              <p className="text-sm">Temps nécessaire :</p>

              <Select
                fullWidth
                value={timeOfPreparation}
                onChange={(event) => {
                  setCompleteAd({
                    ...completeAd,
                    timeOfPreparation: event.target.value,
                  });
                }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--first-color)",
                  },
                }}
              >
                {timeOfPreparationValues?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-center pt-10">
          <button
            className="purple-button center relative left-0 !w-[111px]"
            onClick={validate}
          >
            VALIDER
          </button>
        </div>
      </div>
    </>
  );
};

const TimeOfPreparation = ({}) => {
  return (
    <AdModal
      children={<TimeOfPreparationContent />}
      stepNumber={18}
      height="780px"
      withMdScrolling={true}
    />
  );
};
export default TimeOfPreparation;
