import React from "react";
import Categories from "./Categories";
import Address from "./Address";
import Title from "./Title";
import NumberOfTravelers from "./NumberOfTravelers";
import AddPictures from "./AddPictures";
import Pictures from "./Pictures";
import Price from "./Price";
import Verify from "./Verify";
import AddWellCreated from "./AdWellCreated";
import Error from "./Error";

const AdModal = () => {
  return (
    <>
      <Categories />
      <Address />
      <Title />
      <NumberOfTravelers />
      <AddPictures />
      <Pictures />
      <Price />
      <Verify />
      <AddWellCreated />
      <Error />
    </>
  );
};
export default AdModal;
