import React from "react";
import useAd from "../../hooks/useAd";
import AdModal from "../ad/AdModal";

const ErrorContent = () => {
  const { completeAd, setActiveStep } = useAd();

  return (
    <>
      <div className="flex flex-col justify-center h-full space-y-24">
        <div className="space-y-10 px-0 md:px-10">
          <h2 className="leading-10 text-center xs:leading-8">
            Erreur !!
            <br />
            <span className="text-main/main-100">{completeAd?.title}</span>
            <br />
            n'a pas pu être créé
          </h2>

          <div>
            <div className="center">
              <img
                src="/images/error-sloth.svg"
                alt="sloth-error"
                className="  h-[140px] object-contain"
              />
            </div>

            <div className="center">
              <button
                className="purple-button"
                onClick={() => setActiveStep(-1)}
              >
                ALLER {"\u00C0"} L'ACCUEIL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Error = ({}) => {
  return <AdModal children={<ErrorContent />} stepNumber={17} height="650px" />;
};
export default Error;
