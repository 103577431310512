import React from "react";

const HeroTitle = ({ title, maxWidth, hidden }) => {
  return (
    <div
      className={`w-full bg-hero-title h-[70px] items-center bg-cover bg-no-repeat shrink-0 ${hidden}`}
    >
      <div className={`mx-auto w-full px-6 md:px-10 lg:px-16  ${maxWidth}`}>
        <h1 className="text-white font-bold">{title}</h1>
      </div>
    </div>
  );
};

export default HeroTitle;
