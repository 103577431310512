import React from "react";
import LoginOrSubsribe from "./LoginOrSubscribe";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import TravelOrHostAccount from "./TravelerOrHostAccount";
import PrivateHostOrProfessionnal from "./PrivateHostOrProfessional";
import AccountWellCreated from "./AccountWellCreated";
import ProfessionalHostAccountDetails from "./ProfessionalHostAccountDetails";
import VerifyEmail from "./VerifyEmail";
import {
  TravelerAccountDetails,
  PrivateHostAccountDetails,
} from "./PrivateAccountDetails";
import {
  PrivateHostAccount,
  ProfessionalHostAccount,
  TravelerAccount,
} from "./UserAccount";

const LoginSubscribeModal = () => {
  return (
    <>
      <LoginOrSubsribe />
      <Login />
      <ResetPassword />
      <TravelOrHostAccount />
      <PrivateHostOrProfessionnal />
      <TravelerAccount />
      <ProfessionalHostAccount />
      <PrivateHostAccount />
      <TravelerAccountDetails />
      <PrivateHostAccountDetails />
      <ProfessionalHostAccountDetails />
      <AccountWellCreated />
      <VerifyEmail />
    </>
  );
};
export default LoginSubscribeModal;
