import React, { useRef, useEffect } from "react";
import { InputAdornment, TextField } from "@mui/material";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import NextButton from "./NextButton";
import { slotrFeePercentage } from "../../App";

const PriceContent = () => {
  const inputRef = useRef(null);
  const { isEdit, startAd, setStartAd } = useAd();
  const minPrice = 10;
  const maxPrice = 9999;

  const totalPrice = (price) => {
    const slotrFee = price * (slotrFeePercentage / 100);
    const vatSlotrFee = parseFloat(slotrFee) * 0.2;
    const totalSlotrFee = parseFloat(slotrFee) + parseFloat(vatSlotrFee);
    const totalPrice = parseFloat(price) + parseFloat(totalSlotrFee);

    return totalPrice.toFixed(2);
  };

  const onBlur = () => {
    if (startAd?.price === "" || startAd?.price < 10) {
      setStartAd({ ...startAd, price: 10 });
    }
  };

  const handleOnChange = (e) => {
    let newValue = parseInt(e.target.value);

    if (newValue >= 0 && newValue <= maxPrice) {
      setStartAd({ ...startAd, price: newValue });
    }

    if (isNaN(newValue)) {
      setStartAd({ ...startAd, price: "" });
    }
  };

  const autoInputWidth = (ref, value) => {
    const valueToString = value.toString();
    
    if (ref?.current) {
      const context = document.createElement("canvas").getContext("2d");
      const style = window.getComputedStyle(ref.current);
      context.font = `${style.fontSize} ${style.fontFamily}`;
      const metrics = context.measureText(valueToString);

      return `${metrics.width + 100}px`;
    } else {
      if (valueToString?.length === 3) {
        return "160px";
      } else if (valueToString?.length === 4) {
        return "180px";
      } else {
        return "140px";
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-10">
        <div className="space-y-2">
          <h4 className="text-center">Fixez un prix</h4>

          <p className="text-sm text-design-system/highlight center">
            Vous pourrez toujours modifier ce prix plus tard
          </p>
        </div>

        <div className="center">
          <div className="center space-x-4">
            <TextField
              inputRef={inputRef}
              value={startAd?.price}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },

                width: autoInputWidth(inputRef, startAd?.price),
              }}
              onChange={handleOnChange}
              onBlur={onBlur}
              inputProps={{ style: { textAlign: "center", fontSize: "36px" } }}
              InputProps={{
                type: "number",
                "aria-labelledby": "input-slider",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    component="div"
                    disablePointerEvents
                  >
                    <div
                      style={{
                        marginLeft: "0px",
                        paddingLeft: "0px",
                        paddingRight:
                          startAd?.price.length < 3 ? "14px" : "5px",
                        color: "black",
                      }}
                    >
                      &#8364;
                    </div>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#F9F9F9",
                  fontSize: "36px",
                  paddingLeft: 2,
                  fontWeight: 700,
                  color: "var(--second-color)",
                },
              }}
            />

            <span className="pt-[70px] ">/nuit</span>
          </div>
        </div>

        <div className="space-y-3 text-sm">
          <p className="center text-center">
            La transparence c’est important chez SlotR.
          </p>

          <p className=" text-center">
            Nous ne prendrons que {slotrFeePercentage}% de frais.
            <br />
            Soit un total de{" "}
            <span className="font-semibold inline-block">
              {totalPrice(startAd?.price)}
              &#8364;/nuit
            </span>{" "}
            pour le voyageur comprenant 20% de TVA sur les frais.
          </p>
        </div>
      </div>

      {!isEdit && (
        <div className="flex w-full justify-end pt-6">
          <NextButton />
        </div>
      )}
    </div>
  );
};
const Price = ({}) => {
  return <AdModal children={<PriceContent />} stepNumber={5} height="700px" />;
};
export default Price;
