import React from "react";
import useAd from "../../hooks/useAd";
import AdModal from "./AdModal";
import { useNavigate, useLocation } from "react-router-dom";

const AddWellCreatedContent = () => {
  const { startAd, setActiveStep } = useAd();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="space-y-12 ">
      <div className="space-y-8  px-0 md:px-10">
        <h2 className="leading-10 text-center">
          Votre annonce
          <br />
          <span className="text-main/main-100">{startAd.title}</span>
          <br />a bien été créée !
        </h2>

        <div className="center  py-4">
          <img
            src="/images/login.svg"
            alt="slotr-login-star"
            className="  h-[80px] object-contain"
          />
        </div>
        <p className=" text-center text-zinc-700 leading-relaxed">
          Pour <span className="font-semibold ">publier votre annonce, </span>
          il nous manque quelques informations.
          <br />
          Vous pouvez dès maintenant finaliser votre annonce ou le faire plus
          tard.
        </p>

        <div className="space-y-3">
          <div className="center ">
            <button
              className="purple-button !w-[230px] !font-medium"
              onClick={() => {
                setActiveStep(9);
              }}
            >
              FINALISER MON ANNONCE
            </button>
          </div>

          <div className="center ">
            <button
              className="white-button !w-[230px] !font-medium"
              onClick={() => {
                if (location.pathname === "/dashboard") {
                  window.location.reload();
                } else {
                  navigate("/dashboard");
                }
              }}
            >
              VOIR MON TABLEAU DE BORD
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const AddWellCreated = () => {
  return (
    <AdModal
      children={<AddWellCreatedContent />}
      stepNumber={7}
      height="736px"
      withHeader={false}
    />
  );
};
export default AddWellCreated;
