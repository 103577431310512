import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import { TextField } from "@mui/material";
import AdModal from "../ad/AdModal";
import useAd from "../../hooks/useAd";
import NextButton from "../ad/NextButton";

const FeesContent = () => {
  const {
    handleNext,
    completeAd,
    setCompleteAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const {
    withCleaningFee,
    withAdditionalTravelerFee,
    cleaningFeeAmount,
    additionalTravelerFeeAmount,
    additionalTravelerFeeFrom,
  } = completeAd;
  const isValidate = !(
    (withCleaningFee && cleaningFeeAmount === "") ||
    (withAdditionalTravelerFee &&
      (additionalTravelerFeeAmount === "" || additionalTravelerFeeFrom === ""))
  );

  const handleClick = (keyChecked, checked, keyAmount) => {
    let newValues = { ...completeAd, [keyChecked]: !checked, [keyAmount]: "" };

    if (keyChecked === "withAdditionalTravelerFee") {
      newValues["additionalTravelerFeeFrom"] = "";
    }

    setCompleteAd(newValues);
  };

  const handleChange = (event, key, checked) => {
    let value = parseInt(event.target.value);

    if (checked && value > 0) {
      setCompleteAd({ ...completeAd, [key]: value.toString() });
    } else if (isNaN(value)) {
      setCompleteAd({ ...completeAd, [key]: "" });
    }
  };

  const validate = async (value, key) => {
    if (isValidate) {
      if (!isEdit) {
        handleNext();
      } else {
        if (
          editAd.withCleaningFee !== withCleaningFee ||
          editAd.withAdditionalTravelerFee !== withAdditionalTravelerFee ||
          editAd.cleaningFeeAmount !== cleaningFeeAmount ||
          editAd.additionalTravelerFeeAmount !== additionalTravelerFeeAmount ||
          editAd.additionalTravelerFeeFrom !== additionalTravelerFeeFrom
        ) {
          const newValues = {
            withCleaningFee,
            withAdditionalTravelerFee,
            cleaningFeeAmount,
            additionalTravelerFeeAmount,
            additionalTravelerFeeFrom,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }
        close();
      }
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center space-y-6">
        <div className="space-y-14">
          <div className="flex flex-col space-y-6">
            <h4 className="text-center">Frais de ménage</h4>

            <div className="flex flex-col space-y-5 md:px-12">
              <FormControlLabel
                label={
                  <FormLabel>
                    <label className="text-sm pl-4">
                      <span className="font-semibold">Inclus</span> dans le prix
                    </label>
                  </FormLabel>
                }
                control={
                  <Checkbox
                    color="default"
                    size="small"
                    checked={!withCleaningFee}
                    onChange={() =>
                      handleClick(
                        "withCleaningFee",
                        withCleaningFee,
                        "cleaningFeeAmount"
                      )
                    }
                    sx={{
                      color: "black",
                      padding: 0,
                    }}
                  />
                }
              />

              <FormControlLabel
                sx={{ alignItems: "start" }}
                label={
                  <FormLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "black",
                      },
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    <div className="flex items-center space-x-2 pl-4">
                      <label>
                        <span className="font-semibold">Supplément</span> de
                      </label>

                      <TextField
                        disabled={!withCleaningFee}
                        value={cleaningFeeAmount}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 30,
                            width:
                              cleaningFeeAmount.toString().length > 3 ? 70 : 55,
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        onChange={(e) =>
                          handleChange(e, "cleaningFeeAmount", withCleaningFee)
                        }
                        inputProps={{ style: { textAlign: "right" } }}
                        InputProps={{
                          type: "number",
                          sx: {
                            backgroundColor: "#98949E33",
                            borderRadius: 2,
                            fontSize: "14px",
                            fontWeight: 400,
                          },
                        }}
                      />

                      <span>{"€ /séjour"}</span>
                    </div>
                  </FormLabel>
                }
                control={
                  <Checkbox
                    color="default"
                    size="small"
                    checked={withCleaningFee}
                    onChange={() =>
                      handleClick(
                        "withCleaningFee",
                        withCleaningFee,
                        "cleaningFeeAmount"
                      )
                    }
                    sx={{
                      color: "black",
                      padding: 0,
                      paddingTop: "4px",
                    }}
                  />
                }
              />
            </div>
          </div>

          <div className="flex flex-col space-y-6 ">
            <h4 className="text-center">Voyageur supplémentaire</h4>

            <div className="flex flex-col space-y-5 md:px-12">
              <FormControlLabel
                label={
                  <FormLabel>
                    <label className="text-sm pl-4">
                      <span className="font-semibold">Aucun</span> frais
                      supplémentaire
                    </label>
                  </FormLabel>
                }
                control={
                  <Checkbox
                    color="default"
                    size="small"
                    checked={!withAdditionalTravelerFee}
                    onChange={() =>
                      handleClick(
                        "withAdditionalTravelerFee",
                        withAdditionalTravelerFee,
                        "additionalTravelerFeeAmount"
                      )
                    }
                    sx={{
                      color: "black",
                      padding: 0,
                    }}
                  />
                }
              />

              <FormControlLabel
                sx={{ alignItems: "start" }}
                label={
                  <FormLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "black",
                      },
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    <div className="space-y-2 pl-4">
                      <div className="flex items-center space-x-2">
                        <label>
                          <span className="font-semibold">Supplément</span> de
                        </label>

                        <TextField
                          disabled={!withAdditionalTravelerFee}
                          value={additionalTravelerFeeAmount}
                          sx={{
                            "& .MuiInputBase-root": {
                              height: 30,
                              width:
                                additionalTravelerFeeAmount.toString().length >
                                3
                                  ? 70
                                  : 55,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          onChange={(e) =>
                            handleChange(
                              e,
                              "additionalTravelerFeeAmount",
                              withAdditionalTravelerFee
                            )
                          }
                          inputProps={{ style: { textAlign: "right" } }}
                          InputProps={{
                            type: "number",
                            sx: {
                              backgroundColor: "#98949E33",
                              borderRadius: 2,
                              fontSize: "14px",
                              fontWeight: 400,
                            },
                          }}
                        />

                        <span>{"€ /séjour"}</span>
                      </div>

                      <div className="flex items-center space-x-2 justify-end">
                        <label>au delà de</label>

                        <TextField
                          disabled={!withAdditionalTravelerFee}
                          value={additionalTravelerFeeFrom}
                          sx={{
                            "& .MuiInputBase-root": {
                              height: 30,
                              width:
                                additionalTravelerFeeFrom.toString().length > 3
                                  ? 70
                                  : 55,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          onChange={(e) =>
                            handleChange(
                              e,
                              "additionalTravelerFeeFrom",
                              withAdditionalTravelerFee
                            )
                          }
                          inputProps={{ style: { textAlign: "right" } }}
                          InputProps={{
                            type: "number",
                            sx: {
                              backgroundColor: "#98949E33",
                              borderRadius: 2,
                              fontSize: "14px",
                              fontWeight: 400,
                            },
                          }}
                        />

                        <span>voyageurs</span>
                      </div>
                    </div>
                  </FormLabel>
                }
                control={
                  <Checkbox
                    color="default"
                    size="small"
                    checked={withAdditionalTravelerFee}
                    onChange={() =>
                      handleClick(
                        "withAdditionalTravelerFee",
                        withAdditionalTravelerFee,
                        "additionalTravelerFeeAmount"
                      )
                    }
                    sx={{
                      color: "black",
                      padding: 0,
                      paddingTop: "4px",
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>

        {!isEdit ? (
          <div className="flex w-full justify-end pt-6">
            <NextButton handleClick={validate} isDisabled={!isValidate} />
          </div>
        ) : (
          <div className="flex w-full justify-center pt-10">
            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
              disabled={!isValidate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const Fees = ({}) => {
  return (
    <AdModal children={<FeesContent />} stepNumber={13} height={"760px"} />
  );
};
export default Fees;
