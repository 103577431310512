/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "bookingsManager",
            "endpoint": "https://6qi8497ybf.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        },
        {
            "name": "propertiesManager",
            "endpoint": "https://uf3j4lss65.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        },
        {
            "name": "usersManager",
            "endpoint": "https://ddn28nqyl4.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        },
        {
            "name": "web3Manager",
            "endpoint": "https://2cl6nume79.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
