import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import {
  closeOutline,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";
import Slider from "./Slider";
import useAd from "../../hooks/useAd";
import CounterButton from "../../components/CounterButton";
import PlacesAutocomplete from "../../components/PlacesAutocomplete";
import DatePicker from "../../components/property-details/DayPicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ServiceAndCategoryCard, {
  listOfCategories,
  listOfServices,
} from "../../components/ServiceAndCategoryCard";
import Toggle from "../../components/Toggle";
import { useLocation } from "react-router-dom";

function Filter() {
  const location = useLocation();
  const isSearchPage = location.pathname === "/search";
  const { filtersAd, setFiltersAd, defaultFiltersAd } = useAd();
  const [filterState, setfilterState] = useState(filtersAd);
  const [dropdownService, setDropdownService] = useState(false);
  const toggleValues = ["Non", "Oui"];
  const defaultValueLocation = {
    placeId: filtersAd.placeId,
    destination: filtersAd.destination,
    lat: filtersAd.lat,
    lng: filtersAd.lng,
  };

  const handleCountAdd = (key) => {
    const value = filterState[`${key}`];
    const max = maxNb(key);

    if (value < max) {
      setfilterState({
        ...filterState,
        [`${key}`]: value + 1,
      });
    }
  };

  const handleCountRemove = (key) => {
    const value = filterState[`${key}`];
    const min = key === "adults" ? 1 : 0;

    if (value > min) {
      setfilterState({
        ...filterState,
        [`${key}`]: value - 1,
      });
    }
  };

  const handleCounterOnChange = (e, key) => {
    let newValue = parseInt(e.target.value);
    const min = key === "adults" ? 1 : 0;
    const max = maxNb(key);

    if (newValue >= min && newValue <= max) {
      setfilterState({
        ...filterState,
        [`${key}`]: newValue,
      });
    }

    if (isNaN(newValue)) {
      setfilterState({
        ...filterState,
        [`${key}`]: "",
      });
    }
  };

  const onBlur = (key) => {
    const value = filterState[`${key}`];

    if (value === "") {
      setfilterState({
        ...filterState,
        [`${key}`]: 1,
      });
    }
  };

  const maxNb = (key) => {
    let max;

    if (key === "adults") {
      max = 16 - parseInt(filterState?.children);
    } else if (key === "children") {
      max = 16 - parseInt(filterState?.adults);
    } else if (key === "babies") {
      max = 5;
    } else if (key === "numberOfBeds") {
      max = 20;
    } else if (key === "numberOfBedrooms") {
      max = 20;
    }

    return max;
  };

  const handlePetsValue = (e) => {
    setFiltersAd({
      ...filtersAd,
      arePetsAllowed: e === toggleValues[1] ? true : false,
    });
  };

  const handleDatesSelected = (date) => {
    let selectedRange = { checkIn: date?.from, checkOut: date?.to };

    if (filterState?.checkIn && filterState?.checkOut) {
      if (selectedRange?.checkOut > filterState?.checkOut) {
        selectedRange = { checkIn: selectedRange?.checkOut, checkOut: null };
      } else if (selectedRange?.checkIn < filterState?.checkIn) {
        selectedRange = { checkIn: selectedRange?.checkIn, checkOut: null };
      }
    }

    setfilterState({ ...filterState, ...selectedRange });
  };

  const handleServiceChange = (name) => {
    let newServices = [...filterState?.services];

    if (newServices.includes(name)) {
      newServices = newServices.filter((service) => service !== name);
    } else {
      newServices.push(name);
    }

    setfilterState({ ...filterState, services: newServices });
  };

  return (
    <Modal
      open={filtersAd.isOpen}
      onClose={() => setFiltersAd({ ...filtersAd, isOpen: false })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="justify-end flex bg-[#D9D9D9]/60 relative "
    >
      <Box
        className="absolute h-full w-full z-50 bg-white sm:w-[500px] outline-none sm:border-white overflow-y-auto scrollbar-hide"
        sx={{ border: 0, borderColor: "white" }}
      >
        <div className="space-y-10  overflow-y-auto scrollbar-hide h-full pb-32">
          <div className="flex flex-col space-y-5 py-4 px-10 border-b border-neutral/gray/gray-40">
            <div className="flex w-full justify-end">
              <IonIcon
                onClick={() => setFiltersAd({ ...filtersAd, isOpen: false })}
                icon={closeOutline}
                className="w-8 h-8  text-main/dark-100 cursor-pointer"
              />
            </div>

            <h5 className="text-center">Filtres</h5>
          </div>

          <div className="space-y-12 sm:space-y-8 px-10 sm:px-12">
            <div
              className={`space-y-4 border-b border-neutral/gray/gray-40 pb-6 ${
                isSearchPage ? "flex flex-col lg:hidden" : "hidden"
              }`}
            >
              {/*------------------------------Destination---------------------------------- */}
              <div className="flex flex-col space-y-1 w-full">
                <label className="text-sm">Destination</label>

                <PlacesAutocomplete
                  state={filterState}
                  setState={setfilterState}
                />
              </div>

              {/*------------------------------Dates---------------------------------- */}
              <div className="flex flex-col space-y-1 w-full ">
                <label className="text-sm">Dates</label>

                <DatePicker
                  handleDatesSelected={handleDatesSelected}
                  selected={{
                    from: filterState?.checkIn,
                    to: filterState?.checkOut,
                  }}
                />
              </div>
            </div>

            <div
              className={`space-y-6 border-b border-neutral/gray/gray-40 pb-6 ${
                isSearchPage ? "flex flex-col lg:hidden" : "hidden"
              }`}
            >
              <p className="font-semibold">Qui voyage ?</p>

              {/*------------------------------Adults---------------------------------- */}
              <div className="flex w-full items-center justify-between">
                <label className="text-sm">Adultes</label>

                <CounterButton
                  handleRemove={() => handleCountRemove("adults")}
                  handleAdd={() => handleCountAdd("adults")}
                  handleOnChange={(e) => handleCounterOnChange(e, "adults")}
                  counter={filterState?.adults}
                  min={1}
                  max={maxNb("adults")}
                  onBlur={() => onBlur("adults")}
                />
              </div>

              {/*------------------------------Children---------------------------------- */}
              <div className="flex w-full items-center justify-between">
                <label className="text-sm">Enfants</label>

                <CounterButton
                  handleRemove={() => handleCountRemove("children")}
                  handleAdd={() => handleCountAdd("children")}
                  handleOnChange={(e) => handleCounterOnChange(e, "children")}
                  counter={filterState?.children}
                  min={0}
                  max={maxNb("children")}
                  onBlur={() => onBlur("children")}
                />
              </div>

              {/*------------------------------Babies---------------------------------- */}
              <div className="flex w-full items-center justify-between">
                <label className="text-sm">Bébés</label>

                <CounterButton
                  handleRemove={() => handleCountRemove("babies")}
                  handleAdd={() => handleCountAdd("babies")}
                  handleOnChange={(e) => handleCounterOnChange(e, "babies")}
                  counter={filterState?.babies}
                  min={0}
                  max={5}
                  onBlur={() => onBlur("babies")}
                />
              </div>

              {/*------------------------------Pets---------------------------------- */}
              <div className="flex w-full items-center justify-between">
                <label className="text-sm">Animaux</label>

                <Toggle
                  defaultValue={
                    filtersAd?.arePetsAllowed
                      ? toggleValues[1]
                      : toggleValues[0]
                  }
                  firstValue={toggleValues[0]}
                  secondValue={toggleValues[1]}
                  onChange={handlePetsValue}
                />
              </div>
            </div>

            {/*--------------------------Price range-------------------------------- */}
            <div className="space-y-4 border-b border-neutral/gray/gray-40 pb-6">
              <p className="font-semibold">Fourchettes de prix</p>

              <Slider
                filterState={filterState}
                setfilterState={setfilterState}
              />
            </div>

            {/*--------------------------Type de logement-------------------------------- */}
            <div className="space-y-4 border-b border-neutral/gray/gray-40 pb-6">
              <p className="font-semibold">Type de logement</p>

              <RadioGroup
                value={filterState?.entireAccomodation}
                onChange={(e) =>
                  setfilterState({
                    ...filterState,
                    entireAccomodation: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={"*"}
                  control={<Radio />}
                  label="Tous les types"
                />

                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Logement entier"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Une partie du logement"
                />
              </RadioGroup>
            </div>

            <div className="space-y-4 border-b border-neutral/gray/gray-40 pb-6">
              <p className="font-semibold">Pour dormir</p>

              {/*--------------------------Number of beds-------------------------------- */}
              <div className="flex w-full items-center justify-between">
                <span className="text-sm">Lits</span>

                <CounterButton
                  handleRemove={() => handleCountRemove("numberOfBeds")}
                  handleAdd={() => handleCountAdd("numberOfBeds")}
                  handleOnChange={(e) =>
                    handleCounterOnChange(e, "numberOfBeds")
                  }
                  counter={filterState?.numberOfBeds}
                  min={0}
                  max={20}
                  onBlur={() => onBlur("numberOfBeds")}
                />
              </div>

              {/*--------------------------Number of bedrooms-------------------------------- */}
              <div className="flex w-full items-center justify-between">
                <span className="text-sm">Chambres</span>

                <CounterButton
                  handleRemove={() => handleCountRemove("numberOfBedrooms")}
                  handleAdd={() => handleCountAdd("numberOfBedrooms")}
                  handleOnChange={(e) =>
                    handleCounterOnChange(e, "numberOfBedrooms")
                  }
                  counter={filterState?.numberOfBedrooms}
                  min={0}
                  max={20}
                  onBlur={() => onBlur("numberOfBedrooms")}
                />
              </div>
            </div>

            {/*--------------------------Type de propriété-------------------------------- */}
            <div className="space-y-4 border-b border-neutral/gray/gray-40 pb-6">
              <span className="font-semibold">Type de propriété</span>

              <div className="grid grid-cols-3 gap-x-2 gap-y-6">
                {listOfCategories?.map(({ name }) => (
                  <button
                    key={name}
                    onClick={() => {
                      setfilterState((prevState) => {
                        let updatedCategories;
                        if (prevState?.categories.includes(name)) {
                          updatedCategories = prevState?.categories.filter(
                            (item) => item !== name
                          );
                        } else {
                          updatedCategories =
                            prevState?.categories.concat(name);
                        }

                        return {
                          ...prevState,
                          categories: updatedCategories,
                        };
                      });
                    }}
                  >
                    <ServiceAndCategoryCard
                      name={name}
                      type={"category"}
                      isActive={filterState?.categories.includes(name)}
                    />
                  </button>
                ))}
              </div>
            </div>

            {/*--------------------------Équipements-------------------------------- */}
            <div className="space-y-4">
              <span className="font-semibold">Équipements</span>

              <div className="grid grid-cols-2 gap-x-6 gap-y-2">
                {listOfServices
                  .slice(0, dropdownService ? listOfServices?.length : 6)
                  .map(({ name }) => (
                    <FormControlLabel
                      key={name}
                      control={
                        <Checkbox
                          onChange={() => handleServiceChange(name)}
                          checked={filterState?.services.includes(name)}
                        />
                      }
                      label={name}
                    />
                  ))}
              </div>

              <button
                className="flex items-center space-x-2 cursor-pointer"
                onClick={() => setDropdownService(!dropdownService)}
              >
                <span className="text-sm">
                  {dropdownService ? "Voir moins " : "Voir tous les services"}
                </span>

                <IonIcon
                  icon={dropdownService ? chevronUpOutline : chevronDownOutline}
                  className="w-5 h-5 text-main/main-100"
                ></IonIcon>
              </button>
            </div>
          </div>

          {/*--------------------------Validate-------------------------------- */}
          <div className="absolute bottom-0 left-0 right-0 px-10 sm:px-12 py-5 flex justify-between items-center bg-neutral/gray/gray-10">
            <button
              className="text-sm"
              onClick={() =>
                setFiltersAd({ ...defaultFiltersAd, filterSubmit: true })
              }
            >
              Effacer tout
            </button>

            <button
              className="text-sm bg-main/main-100 text-white font-semibold rounded-full py-2 px-3 xl:px-6 w-[128px] h-[52px] tracking-widest"
              onClick={() =>
                setFiltersAd({
                  ...filterState,
                  filterSubmit: true,
                  isOpen: false,
                })
              }
            >
              {"VALIDER"}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default Filter;
