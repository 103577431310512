import React from "react";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import { RemoveAdd } from "../filter/Filter";
import NextButton from "./NextButton";
import CounterButton from "../../components/CounterButton";

const NumberOfTravelersContent = () => {
  const {
    handleNext,
    startAd,
    setStartAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { numberOfTravelers, numberOfBedrooms, numberOfBeds } = startAd;

  const handleAdd = (key) => {
    const value = startAd[`${key}`];
    const max = 999;

    if (value < max) {
      setStartAd({
        ...startAd,
        [`${key}`]: value + 1,
      });
    }
  };

  const handleRemove = (key) => {
    const value = startAd[`${key}`];
    const min = key === "numberOfBedrooms" ? 0 : 1;

    if (value > min) {
      setStartAd({
        ...startAd,
        [`${key}`]: value - 1,
      });
    }
  };

  const handleCounterOnChange = (e, key) => {
    let newValue = parseInt(e.target.value);
    const min = 1;
    const max = 999;

    if (newValue >= min && newValue <= max) {
      setStartAd({
        ...startAd,
        [`${key}`]: newValue,
      });
    }

    if (isNaN(newValue)) {
      setStartAd({
        ...startAd,
        [`${key}`]: "",
      });
    }
  };

  const onBlur = (key) => {
    const value = startAd[`${key}`];

    if (value === "") {
      setStartAd({
        ...startAd,
        [`${key}`]: 1,
      });
    }
  };

  const validate = async () => {
    if (!isEdit) {
      handleNext();
    } else {
      if (
        editAd.numberOfTravelers !== numberOfTravelers ||
        editAd.numberOfBedrooms !== numberOfBedrooms ||
        editAd.numberOfBeds !== numberOfBeds
      ) {
        const newValues = {
          numberOfTravelers,
          numberOfBedrooms,
          numberOfBeds,
        };

        const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

        if (res) {
          setEditAd({
            ...editAd,
            ...newValues,
          });
        }
      }

      close();
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-6 md:space-y-8">
        <h4 className="text-center">Indiquez les informations suivantes</h4>

        <div className="space-y-6 ">
          <h6 className="center text-center">Le maximum de voyageurs</h6>

          <div className="center">
            <CounterButton
              handleRemove={() => handleRemove("numberOfTravelers")}
              handleAdd={() => handleAdd("numberOfTravelers")}
              handleOnChange={(e) =>
                handleCounterOnChange(e, "numberOfTravelers")
              }
              counter={numberOfTravelers}
              min={1}
              max={999}
              onBlur={() => onBlur("numberOfTravelers")}
            />
          </div>
        </div>

        <div className="space-y-6">
          <h6 className="center text-center">Le nombre de chambres</h6>

          <div className="center">
            <CounterButton
              handleRemove={() => handleRemove("numberOfBedrooms")}
              handleAdd={() => handleAdd("numberOfBedrooms")}
              handleOnChange={(e) =>
                handleCounterOnChange(e, "numberOfBedrooms")
              }
              counter={numberOfBedrooms}
              min={0}
              max={999}
              onBlur={() => onBlur("numberOfBedrooms")}
            />
          </div>
        </div>

        <div className="space-y-6">
          <h6 className="center text-center">Le nombre de lits</h6>

          <div className="center">
            <CounterButton
              handleRemove={() => handleRemove("numberOfBeds")}
              handleAdd={() => handleAdd("numberOfBeds")}
              handleOnChange={(e) => handleCounterOnChange(e, "numberOfBeds")}
              counter={numberOfBeds}
              min={1}
              max={999}
              onBlur={() => onBlur("numberOfBeds")}
            />
          </div>
        </div>
      </div>

      {!isEdit ? (
        <div className="flex w-full justify-end pt-6">
          <NextButton handleClick={validate} />
        </div>
      ) : (
        <div className="flex w-full justify-center pt-10">
          <button
            className="purple-button center relative left-0 !w-[111px]"
            onClick={validate}
          >
            VALIDER
          </button>
        </div>
      )}
    </div>
  );
};
const NumberOfTravelers = ({}) => {
  return (
    <AdModal
      children={<NumberOfTravelersContent />}
      stepNumber={3}
      height="766px"
    />
  );
};
export default NumberOfTravelers;
