import React from "react";
import AuthModal from "./AuthModal";
import useAuth from "../../hooks/useAuth";

const TravelOrHostAccountContent = () => {
  const { loginSubscribeDispatch } = useAuth();

  return (
    <div className="flex flex-col justify-center h-full space-y-32 md:space-y-14">
      <div className="space-y-16">
        <div className="center">
          <h2 className="font-bold center xs:text-[28px]">Créer un compte</h2>
        </div>

        <div className="space-y-4">
          <div className="center">
            <button
              className="purple-button !w-[200px] "
              onClick={() =>
                loginSubscribeDispatch({ type: "GO TO", to: "TravelerAccount" })
              }
            >
              JE SUIS UN VOYAGEUR
            </button>
          </div>

          <div className="center">
            <button
              className="white-button !w-[200px]"
              onClick={() =>
                loginSubscribeDispatch({
                  type: "GO TO",
                  to: "PrivateHostOrProfessional",
                })
              }
            >
              JE SUIS UN HÔTE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const TravelOrHostAccount = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={TravelOrHostAccountContent()}
      isOpen={loginSubscribeState.openModal == "TravelerOrHostAccount"}
    />
  );
};
export default TravelOrHostAccount;
