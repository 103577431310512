import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format } from "date-fns";
import { IonIcon } from "@ionic/react";
import { linkOutline, menu } from "ionicons/icons";
import useBooking from "../../hooks/useBooking";
import useAd from "../../hooks/useAd";
import useAuth from "../../hooks/useAuth";

const CalendarUrls = ({
  setCurrentContent,
  propertyData,
  importedCalendars,
  setImportedCalendars,
  lastCalendarsUpdate,
}) => {
  const { updatePropertyFromDatabase } = useAd();
  const { deleteAllDatesOfImportedCalendar } = useBooking();
  const { currentUser } = useAuth();

  //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function CalendarUrl({ url, name, index }) {
    const [title, setTitle] = useState(name);
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    const handleRenameClick = () => {
      setIsEditing(true);
    };

    const handleInputChange = (e) => {
      setTitle(e.target.value);
    };

    const handleInputBlur = async () => {
      const newImportedCalendars = [...importedCalendars];

      const indexToRename = newImportedCalendars.findIndex(
        (obj) => obj["url"] === url
      );
      newImportedCalendars[indexToRename] = { url: url, name: title };

      const res = await updatePropertyFromDatabase(propertyData?.uid, {
        importedCalendars: newImportedCalendars,
      });

      if (res) {
        setImportedCalendars(newImportedCalendars);
      }

      setIsEditing(false);
    };

    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const handleMouseOut = () => {
      setIsHovering(false);
    };

    const deleteCalendar = async () => {
      const newImportedCalendars = [...importedCalendars];

      const indexToDelete = newImportedCalendars.findIndex(
        (obj) => obj["url"] === url
      );

      if (indexToDelete !== -1) {
        newImportedCalendars.splice(indexToDelete, 1);
      }

      const res = await updatePropertyFromDatabase(propertyData?.uid, {
        importedCalendars: newImportedCalendars,
      });

      if (res) {
        deleteAllDatesOfImportedCalendar(url, currentUser.uid).then(() => {
          setImportedCalendars(newImportedCalendars);
        });
      }
    };

    return (
      <div
        className={`relative dropdown ${
          index < 2 ? "dropdown-bottom" : "dropdown-top"
        } `}
      >
        <div tabIndex={index} role="button">
          <div
            className="grid grid-cols-9 xl:grid-cols-4 gap-2 bg-[#F9F9F9] rounded-full "
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div
              className={` flex jutify-center rounded-full col-span-7 xl:col-span-3  px-2 bg-[#F1E5C3]`}
            >
              <div className="center pt-1 ">
                <IonIcon
                  icon={linkOutline}
                  className="w-5 h-5 pr-3 pl-1 transform rotate-45 "
                ></IonIcon>
              </div>

              {isEditing ? (
                <input
                  type="text"
                  value={title}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  className="outline-none p-2 rounded-full"
                  autoFocus
                />
              ) : (
                <p className="text-black center text-left ">{title}</p>
              )}
            </div>

            <div className="center">
              <div className="w-[48px] h-[48px] center pl-4 xl:p-0">
                {!isEditing &&
                  (isHovering ? (
                    <>
                      <div className=" ">
                        <IonIcon
                          icon={menu}
                          className="w-6 h-6 xl:pr-[15px] "
                        />
                      </div>
                    </>
                  ) : (
                    <div className="text-[10px]  text-center xl:pr-2">
                      {lastCalendarsUpdate &&
                        format(new Date(lastCalendarsUpdate), "dd/MM HH:mm")}
                    </div>
                  ))}
              </div>
            </div>

            <ul
              tabIndex={index}
              className="fixed dropdown-content z-50 menu p-2 shadow bg-white rounded-box w-52 "
            >
              <li>
                <button onClick={deleteCalendar}>Supprimer</button>
              </li>

              <li>
                <button onClick={handleRenameClick}>Renommer</button>
              </li>

              <li>
                <CopyToClipboard text={url}>
                  <button>Copier le lien</button>
                </CopyToClipboard>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      {importedCalendars?.length > 0 ? (
        <div className="space-y-4 customedScrollNoPadding p-6 rounded-lg border-[6px] border-[#F9F9F9]">
          <div className="flex justify-between items-center space-x-2">
            <h5>Mes calendriers importés</h5>

            <button
              onClick={() => setCurrentContent("importCalendar")}
              className="flex flex-none"
            >
              <img
                src="/images/navigation-signs/button-increment.svg"
                alt="button-increment"
                className="object-cover w-9 h-9"
              />
            </button>
          </div>

          <div
            className={`flex flex-col pr-6 space-y-2  ${
              importedCalendars?.length > 4 &&
              "overflow-y-auto h-full max-h-[200px]"
            }`}
          >
            {importedCalendars?.map(({ url, name }, index) => (
              <CalendarUrl key={index} index={index} url={url} name={name} />
            ))}
          </div>
        </div>
      ) : (
        <p>Aucun calendrier importé</p>
      )}
    </>
  );
};

export default CalendarUrls;
