import React, { useState, useEffect } from "react";
import AuthModal from "./AuthModal";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

const AccountWellCreatedContent = () => {
  const {
    currentUser,
    loginSubscribeDispatch,
    sendEmailForVerification,
    timeActive,
    setTimeActive,
    UserReload,
  } = useAuth();

  const [time, setTime] = useState(60);

  useEffect(() => {
    let interval = null;

    if (timeActive && time > 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
        UserReload();
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timeActive, time]);

  useEffect(() => {
    if (currentUser?.emailVerified) {
      loginSubscribeDispatch({ type: "CLOSE" });
      setTime(60);
    }
  }, [currentUser?.emailVerified]);

  return (
    <div className="py-16 space-y-32 md:space-y-10">
      <div className="space-y-6 xs:space-y-4 px-0 md:px-10">
        <h2 className="font-bold center xs:text-[28px] leading-10 flex-wrap">
          Votre compte{" "}
          <span className="pl-2 text-main/main-100">
            {currentUser?.userType === "host" ? "hôte" : "voyageur"}
          </span>{" "}
          a bien été créé !
        </h2>

        <div className="center">
          <img
            src="/images/login.svg"
            alt="slotr-login-star"
            className=" w-[63px] h-[63px] object-contain"
          />
        </div>

        <div className="space-y-7">
          <div>
            Un <span className="font-semibold">lien de confirmation</span> a été
            envoyé sur votre adresse mail.
          </div>

          <div>
            Merci de <span className="font-semibold">cliquer</span> dessus afin
            de confirmer la création de votre compte.
          </div>

          <button
            className="text-neutral/gray/gray-100 text-[12px] !bg-transparent disabled:text-neutral/gray/gray-60 underline"
            onClick={sendEmailForVerification}
            disabled={timeActive}
          >
            Je n’ai pas reçu de mail {timeActive && "(" + time + ")"}
          </button>

          <div className="flex flex-col items-center space-y-3">
            <Link to={currentUser?.userType === "host" ? "/dashboard" : "/"}>
              <button
                className="purple-button !w-[220px]"
                onClick={() => loginSubscribeDispatch({ type: "CLOSE" })}
              >
                {currentUser?.userType === "host"
                  ? "VOIR MON TABLEAU DE BORD"
                  : "ALLER \u00C0 L'ACCUEIL"}
              </button>
            </Link>

            <Link to="/profile">
              <button
                className="white-button !w-[220px]"
                onClick={() => loginSubscribeDispatch({ type: "CLOSE" })}
              >
                Compléter mon profil
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountWellCreated = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={AccountWellCreatedContent()}
      isOpen={loginSubscribeState.openModal === "AccountWellCreated"}
      height="736px"
      withHeader={false}
    />
  );
};

export default AccountWellCreated;
