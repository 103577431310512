import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import AdModal from "../ad/AdModal";
import useAd from "../../hooks/useAd";
import NextButton from "../ad/NextButton";
import CounterButton from "../../components/CounterButton";

const TimeTextField = ({ value, handleChange, textAlign }) => {
  const [inputFocus, setInputFocus] = useState(false);
  return (
    <TextField
      value={value}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      }}
      onChange={handleChange}
      onFocus={() => setInputFocus(true)}
      onBlur={() => setInputFocus(false)}
      fullWidth
      inputProps={{
        type: "parseInt",
        "aria-labelledby": "input-slider",
        style: {
          textAlign: textAlign,
          fontSize: "16px",
          width: "34px",
          height: "50px",
          backgroundColor: inputFocus && "#8A55FB99",
          color: inputFocus && "white",
          borderRadius: "6px",
          padding: "0px 4px",
        },
      }}
    />
  );
};

const TimeOfArrivalAndDepartureContent = () => {
  const {
    handleNext,
    completeAd,
    setCompleteAd,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
  } = useAd();
  const { timeOfArrival, timeOfDeparture, maxNbOfNights, minNbOfNights } =
    completeAd;
  const minNights = 1;
  const maxNights = 120;

  const [HourOfArrival, setHourOfArrival] = useState(
    timeOfArrival.split(":")[0]
  );

  const [MinuteOfArrival, setMinuteOfArrival] = useState(
    timeOfArrival.split(":")[1]
  );

  const [HourOfDeparture, setHourOfDeparture] = useState(
    timeOfDeparture.split(":")[0]
  );

  const [MinuteOfDeparture, setMinuteOfDeparture] = useState(
    timeOfDeparture.split(":")[1]
  );

  const handleChangeHour = (event, set) => {
    let newHour = event.target.value;
    if (
      (parseInt(newHour) || parseInt(newHour) === 0) &&
      parseInt(newHour) <= 24 &&
      parseInt(newHour) >= 0
    ) {
      let newHourNumber = parseInt(newHour, 10);
      set(newHourNumber.toString().padStart(2, "0"));
    }

    if (newHour === "") {
      set("00");
    }
  };

  const handleChangeMinute = (event, set) => {
    let newMinutes = event.target.value;
    if (
      (parseInt(newMinutes) || parseInt(newMinutes) === 0) &&
      parseInt(newMinutes) < 60 &&
      parseInt(newMinutes) >= 0
    ) {
      let newMinutesNumber = parseInt(newMinutes, 10);
      set(newMinutesNumber.toString().padStart(2, "0"));
    }

    if (newMinutes === "") {
      set("00");
    }
  };

  const handleAddNbOfNights = (key) => {
    const StateValue = parseInt(completeAd[key]);
    let condition;

    if (key === "minNbOfNights") {
      condition =
        StateValue <= maxNbOfNights && StateValue < parseInt(maxNbOfNights);
    } else {
      condition = StateValue <= maxNbOfNights;
    }

    if (condition) {
      const newValue = StateValue + 1;
      setCompleteAd({ ...completeAd, [`${key}`]: newValue });
    }
  };

  const handleRemoveNbOfNights = (key) => {
    const StateValue = parseInt(completeAd[key]);
    let condition;

    if (key === "maxNbOfNights") {
      condition = StateValue > 1 && StateValue > parseInt(minNbOfNights);
    } else {
      condition = StateValue > 1;
    }

    if (condition) {
      const newValue = StateValue - 1;
      setCompleteAd({ ...completeAd, [`${key}`]: newValue });
    }
  };

  const handleOnChange = (e, key) => {
    let newValue = parseInt(e.target.value);

    if (newValue >= 1 && newValue <= maxNights) {
      setCompleteAd({ ...completeAd, [`${key}`]: newValue });
    }

    if (isNaN(newValue)) {
      setCompleteAd({ ...completeAd, [`${key}`]: "" });
    }
  };

  const onBlur = (key) => {
    const valueKey = completeAd[key];

    if (
      key === "maxNbOfNights" &&
      (valueKey === "" || valueKey < minNbOfNights)
    ) {
      setCompleteAd({ ...completeAd, [`${key}`]: minNbOfNights });
    } else if (
      key === "minNbOfNights" &&
      (valueKey === "" || valueKey > maxNbOfNights)
    ) {
      setCompleteAd({ ...completeAd, [`${key}`]: 1 });
    }
  };

  useEffect(() => {
    setCompleteAd({
      ...completeAd,
      timeOfArrival:
        HourOfArrival.toString() + ":" + MinuteOfArrival.toString(),
      timeOfDeparture:
        HourOfDeparture.toString() + ":" + MinuteOfDeparture.toString(),
    });
  }, [HourOfArrival, MinuteOfArrival, HourOfDeparture, MinuteOfDeparture]);

  const validate = async () => {
    if (!isEdit) {
      handleNext();
    } else {
      if (
        editAd.timeOfArrival !== timeOfArrival ||
        editAd.timeOfDeparture !== timeOfDeparture ||
        editAd.maxNbOfNights !== maxNbOfNights ||
        editAd.minNbOfNights !== minNbOfNights
      ) {
        const newValues = {
          timeOfArrival,
          timeOfDeparture,
          maxNbOfNights,
          minNbOfNights,
        };

        const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

        if (res) {
          setEditAd({
            ...editAd,
            ...newValues,
          });
        }
      }
      close();
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-16 ">
        <div className="space-y-6">
          <div className="space-y-2 flex flex-col items-center">
            <h4 className="center text-center">Nombre de nuits</h4>

            <p className="text-sm center text-center w-[90%]">
              Saisissez le nombre de nuits minimum et maximum pour effectuer une
              réservation.
            </p>
          </div>

          <div className="flex space-x-8 justify-center w-full">
            <div className="flex flex-col items-center space-y-4">
              <h6>Minimum</h6>

              <CounterButton
                handleRemove={() => handleRemoveNbOfNights("minNbOfNights")}
                handleAdd={() => handleAddNbOfNights("minNbOfNights")}
                handleOnChange={(e) => handleOnChange(e, "minNbOfNights")}
                counter={minNbOfNights}
                min={minNights}
                max={maxNbOfNights}
                onBlur={() => onBlur("minNbOfNights")}
              />
            </div>

            <div className="flex flex-col items-center space-y-4">
              <h6>Maximum</h6>

              <CounterButton
                handleRemove={() => handleRemoveNbOfNights("maxNbOfNights")}
                handleAdd={() => handleAddNbOfNights("maxNbOfNights")}
                handleOnChange={(e) => handleOnChange(e, "maxNbOfNights")}
                counter={maxNbOfNights}
                min={minNbOfNights}
                max={maxNights}
                onBlur={() => onBlur("maxNbOfNights")}
              />
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div className="space-y-1 flex flex-col items-center">
            <h4 className="text-center">Heure d’arrivée et de départ</h4>

            <p className="text-sm text-center w-full lg:w-[90%]">
              Avertissez les voyageurs de l’heure d’arrivée et de départ de
              votre logement.
            </p>
          </div>

          <div className="flex space-x-8 justify-center">
            <div className="flex flex-col items-center space-y-4">
              <h6 className="text-center">Heure d’arrivée</h6>

              <div className="flex">
                <TimeTextField
                  handleChange={(event) =>
                    handleChangeHour(event, setHourOfArrival)
                  }
                  value={HourOfArrival}
                  textAlign="end"
                />

                <div className="center font-bold">:</div>

                <TimeTextField
                  handleChange={(event) =>
                    handleChangeMinute(event, setMinuteOfArrival)
                  }
                  value={MinuteOfArrival}
                  textAlign="start"
                />
              </div>
            </div>

            <div className="flex flex-col items-center space-y-4">
              <h6 className="text-center">Heure de départ</h6>

              <div className="flex">
                <TimeTextField
                  handleChange={(event) =>
                    handleChangeHour(event, setHourOfDeparture)
                  }
                  value={HourOfDeparture}
                  textAlign="end"
                />

                <div className="center font-bold">:</div>

                <TimeTextField
                  handleChange={(event) =>
                    handleChangeMinute(event, setMinuteOfDeparture)
                  }
                  value={MinuteOfDeparture}
                  textAlign="start"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isEdit ? (
        <div className="flex w-full justify-end pt-6">
          <NextButton handleClick={validate} />
        </div>
      ) : (
        <div className="flex w-full justify-center pt-10">
          <button
            className="purple-button center relative left-0 !w-[111px]"
            onClick={validate}
          >
            VALIDER
          </button>
        </div>
      )}
    </div>
  );
};
const TimeOfArrivalAndDeparture = () => {
  return (
    <AdModal
      children={<TimeOfArrivalAndDepartureContent />}
      stepNumber={11}
      height="769px"
    />
  );
};
export default TimeOfArrivalAndDeparture;
