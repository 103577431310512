import React from "react";
import Services from "./Services";
import Error from "./Error";
import TermsOfCancelation from "./TermsOfCancelation";
import TimeOfArrivalAndDeparture from "./TimeOfArrivalAndDeparture";
import TimeOfPreparation from "./TimeOfPreparation";
import Pets from "./Pets";
import Fees from "./Fees";
import PropertyRegistrationNumber from "./PropertyRegistrationNumber";
import AddWellCreated from "./AdWellCreated";
import CoHost from "./CoHost";

const CompleteAnAdModal = () => {
  return (
    <>
      <Services />
      <TermsOfCancelation />
      <TimeOfArrivalAndDeparture />
      <TimeOfPreparation />
      <Pets />
      <Fees />
      <PropertyRegistrationNumber />
      <CoHost />
      <AddWellCreated />
      <Error />
    </>
  );
};
export default CompleteAnAdModal;
