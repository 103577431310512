import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import HostNavBar from "../components/HostNavBar";
import CircularProgress from "@mui/material/CircularProgress";
import HeroTitle from "../components/HeroTitle";
import { useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { ellipse } from "ionicons/icons";

const Verification = ({ field }) => {
  const { title, description, status, actionName, action } = field;
  return (
    <div className="flex items-start xl:items-center space-x-4">
      <div className="flex-none">
        {status === "verified" ? (
          <img
            className="w-10 h-10"
            src="/images/navigation-signs/green-checked-mark.svg"
            alt="sloth host profile picture"
          />
        ) : status === "pending" ? (
          <img
            className="w-10 h-10"
            src="/images/navigation-signs/timer-icon.svg"
            alt="sloth host profile picture"
          />
        ) : (
          <img
            className="w-10 h-10"
            src="/images/navigation-signs/exclamation-mark.svg"
            alt="sloth host profile picture"
          />
        )}
      </div>

      <div className="flex flex-col space-y-3 xl:space-y-0 w-full xl:justify-between xl:items-center xl:flex-row xl:space-x-4">
        <div>
          <p>{title}</p>

          {status === "unverified" && (
            <p className="text-xs text-neutral/gray/gray-100">{description}</p>
          )}

          {status === "pending" && (
            <p className="text-xs text-neutral/gray/gray-100">
              En cours de verification
            </p>
          )}
        </div>

        {status === "unverified" && (
          <button
            className="purple-button !h-[32px] !w-[109px] flex-none"
            onClick={action}
          >
            {actionName}
          </button>
        )}
      </div>
    </div>
  );
};

const ProfileHeaderTraveler = () => {
  return (
    <HeroTitle
      title={"Mon profil"}
      maxWidth={"max-w-smallScreen"}
      hidden={"hidden lg:flex"}
    />
  );
};

const Profile = () => {
  const { currentUser, Logout, loginSubscribeDispatch } = useAuth();

  useEffect(() => {
    if (currentUser) {
      let data = { ...currentUser };
      delete data.creationTime;
      delete data.updateTime;
      data.updateAccountInfos = true;
      data.rcsSirenSiret = currentUser.rcsSirenSiret;

      loginSubscribeDispatch({
        type: "SET ALL",
        target: data,
      });
    }
  }, [currentUser]);

  const openUpdateInfosModal = () => {
    if (currentUser !== null) {
      const next =
        currentUser.userType === "traveler"
          ? "TravelerAccountDetails"
          : currentUser.userType === "host" && currentUser.isProfessional
          ? "ProfessionalHostAccountDetails"
          : "PrivateHostAccountDetails";

      loginSubscribeDispatch({
        type: "GO TO",
        to: next,
      });
    }
  };
  const navigate = useNavigate();

  const standarRequirements = [
    {
      title: "E-mail",
      description:
        "Afin de valider votre compte, la validation de l'e-mail est nécessaire",
      status: currentUser?.emailVerified ? "verified" : "unverified",
      actionName: "Envoyer",
      action: () =>
        loginSubscribeDispatch({
          type: "GO TO",
          to: "AccountWellCreated",
        }),
    },
  ];

  const hostRequirements = [
    ...standarRequirements,
    {
      title: "Identité",
      description:
        "Afin de valider votre identité, une CNI ou un passeport est nécessaire",
      status:
        currentUser?.dataOfMerchant?.compliance.requirements.identityStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.identityVerificationUrl),
    },
    {
      title: "Coordonnées bancaires",
      description:
        "Afin de publier vos annonces, nous aurions besoin de tes coordonnées bancaires",
      status:
        currentUser?.dataOfMerchant?.compliance.requirements.bankAccountStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.bankAccountVerificationUrl),
    },
  ];

  const hostProRequirements = [
    ...hostRequirements,
    {
      title: "Extrait de la chambre de commerce (KBIS)",
      description:
        "Afin de publier vos annonces, un extrait de la chambre des commerces est nécessaire",
      status:
        currentUser?.dataOfMerchant?.compliance.requirements.cocExtractStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.cocExtractVerificationUrl),
    },
    {
      title: "Registre UBO",
      description:
        "Afin de publier vos annonces, un registre UBO est nécessaire",
      status: currentUser?.dataOfMerchant?.compliance.requirements.uboStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.uboVerificationUrl),
    },
  ];

  const travelerRequirements = [
    ...standarRequirements,
    {
      title: "Identité",
      description:
        "Afin de valider votre identité, une CNI ou un passeport est nécessaire",
      status: false,
      actionName: "Envoyer",
      action: () => navigate(""),
    },
  ];

  const othersRequirements = {
    title: "Autres documents à renseigner",
    description:
      "Documents pouvant être demandés par notre prestataire de paiement pour des vérifications approfondies",
    status: currentUser?.dataOfMerchant ? "unverified" : false,
    actionName: "Voir",
    action: () =>
      (window.location.href =
        currentUser?.dataOfMerchant?.compliance.overviewUrl),
  };

  if (currentUser) {
    const {
      firstName,
      lastName,
      phoneNumber,
      userType,
      email,
      personInChargeName,
      isProfessional,
      companyName,
      rcsSirenSiret,
      street,
      streetNumber,
      postalCode,
      addressComplement,
      city,
      country,
      birthDate,
    } = currentUser;
    const isTraveler = userType === "traveler";
    const isPro = isProfessional;
    const profilStatus = currentUser?.dataOfMerchant?.status;
    const profileComplianceStatus =
      currentUser?.dataOfMerchant?.compliance.status;

    let requirements = [];
    requirements = standarRequirements;

    /*if (currentUser?.emailVerified) {
      if (isTraveler) {
        requirements = travelerRequirements;
      } else {
        if (isPro) {
          requirements = hostProRequirements;
          requirements.push(othersRequirements);
        } else {
          requirements = hostRequirements;
          requirements.push(othersRequirements);
        }
      }
    } else {
      requirements = standarRequirements;
    }*/

    function userBirthDate() {
      const date_new = new Date(birthDate);
      const datePart = new Intl.DateTimeFormat("fr-FR").format(date_new);

      return datePart;
    }

    function userCreationDate() {
      const dateObj = new Date(currentUser?.creationTime._seconds * 1000);
      const datePart = dateObj.toLocaleDateString();

      return datePart;
    }

    return (
      <div className="flex-grow">
        {isTraveler ? (
          <ProfileHeaderTraveler />
        ) : (
          <HostNavBar focused="profile" />
        )}

        <div className="text-black lg:p-0 lg:flex lg:justify-center lg:items-center ">
          <div
            className={`lg:w-[1440px] px-6 md:px-10 lg:px-16 ${
              isTraveler && "lg:pt-[109px]"
            }`}
          >
            {!isTraveler && (
              <h1 className="text-[28px] lg:text-4xl font-bold pb-5 lg:pb-10 lg:pt-[61px] ">
                Mon profil
              </h1>
            )}

            <div className="flex flex-col xl:flex xl:flex-row xl:gap-14">
              <div className="flex-none space-y-6 lg:space-y-10">
                <div className="w-full lg:w-full bg-neutral/gray/gray-10 rounded-2xl relative">
                  <div className="px-6 md:px-10 xl:px-14 py-4 md:py-6 lg:py-8">
                    <div className="flex flex-row gap-6 lg:gap-8 ">
                      <div className="center lg:block">
                        <img
                          className=" w-[80px] h-[80px] lg:w-[160px] lg:h-[160px] "
                          src={`/images/${
                            isTraveler
                              ? "sloth-profile-traveler-picture.svg"
                              : "sloth-profile-host-picture.svg"
                          }`}
                          alt="sloth host profile picture"
                        />
                      </div>

                      <div className="center z-10">
                        <div className="flex flex-col xs:text-sm space-y-2 lg:space-y-4">
                          <h3 className="lg:leading-8 capitalize">
                            {isTraveler || (!isTraveler && !isPro)
                              ? firstName + " " + lastName
                              : companyName}
                          </h3>

                          <div className="space-y-2">
                            <div className="flex flex-row gap-2 lg:gap-4 items-center ">
                              {isTraveler ? (
                                <span>Voyageur</span>
                              ) : (
                                <span> Hôte</span>
                              )}

                              {isPro && (
                                <span className="text-main/main-100 text-xs font-semibold rounded-full border-2 border-main/main-100 w-[63px] h-[32px] center">
                                  PRO
                                </span>
                              )}
                            </div>

                            <div>{"Inscription le " + userCreationDate()}</div>

                            {/*currentUser?.emailVerified && !isTraveler && (
                              <div>
                                <div className="flex space-x-2.5 items-center">
                                  <IonIcon
                                    icon={ellipse}
                                    className={`shrink-0 w-2 h-2 ${
                                      profilStatus === "live"
                                        ? "text-semantic/succes"
                                        : profilStatus === "pending"
                                        ? "text-semantic/break"
                                        : "text-semantic/error"
                                    }`}
                                  ></IonIcon>

                                  <p className="text-sm">
                                    {"Statut du Profil : " +
                                      (profilStatus === "Nouveau"
                                        ? ""
                                        : profilStatus === "live"
                                        ? "Vérifié "
                                        : profilStatus === "pending"
                                        ? "En attente"
                                        : "suspendu")}
                                  </p>
                                </div>

                                <div className="flex space-x-2.5 items-center">
                                  <IonIcon
                                    icon={ellipse}
                                    className={`shrink-0 w-2 h-2 ${
                                      profileComplianceStatus === "verified"
                                        ? "text-semantic/succes"
                                        : profileComplianceStatus === "pending"
                                        ? "text-semantic/break"
                                        : "text-semantic/error"
                                    }`}
                                  ></IonIcon>

                                  <p className="text-sm">
                                    {"Statut de conformité : " +
                                      (profileComplianceStatus === "verified"
                                        ? "Vérifié "
                                        : profileComplianceStatus === "pending"
                                        ? "En attente"
                                        : "Non vérifié")}
                                  </p>
                                </div>
                              </div>
                            )*/}
                            <button
                              className="flex lg:hidden text-sm text-main/dark-70"
                              onClick={Logout}
                            >
                              Se déconnecter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -top-3 right-0 lg:-top-2 md:right-4">
                    <img
                      className="w-16 h-16 lg:w-[124px] lg:h-[124px] "
                      src="/images/stickers/sticker-cloud.svg"
                      alt="sticker cloud"
                    />
                  </div>
                  <div className="absolute top-6 -left-3 ">
                    <img
                      className="w-10 h-10 lg:w-[104px] lg:h-[92px]"
                      src="/images/stickers/sticker-green.svg"
                      alt="sticker green"
                    />
                  </div>
                  <div className="absolute bottom-4 lg:bottom-0 right-4 lg:right-12 z-0">
                    <img
                      className="w-16 h-16 lg:w-[134px] lg:h-[134px] "
                      src="/images/stickers/sticker-strike.svg"
                      alt="sticker strike"
                    />
                  </div>
                </div>

                <div className="px-6 md:px-10 xl:px-14 lg:w-full py-4 md:py-6 lg:py-8 rounded-2xl border border-[#D9D9D9]">
                  <div className="flex flex-col gap-2 lg:gap-5 ">
                    <h4 className=" text-[16px] lg:text-2xl font-bold ">
                      Vérifications de mon compte
                    </h4>

                    <div className="space-y-4">
                      {requirements.map((field) => {
                        return <Verification field={field} key={field.title} />;
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <br className="w-6 xl:hidden" />

              <div
                className={`px-6 md:px-10 xl:px-14 h-full py-4 md:py-6 lg:py-8 rounded-2xl border border-#98949E`}
              >
                <h4 className="text-[16px] lg:text-2xl font-bold pb-4">
                  Informations personnelles et professionnelles
                </h4>

                {isPro && (
                  <h6 className="text-lg font-semibold pb-[19px] hidden lg:block">
                    Contact responsable
                  </h6>
                )}

                <div className="lg:flex lg:space-x-28 xl:space-x-14 2xl:space-x-28 space-y-4 lg:space-y-0">
                  <div className="flex-col flex">
                    <p className="capitalize">
                      {isTraveler || (!isTraveler && !isPro)
                        ? firstName + " " + lastName
                        : companyName}
                    </p>

                    {isPro && <p>{personInChargeName}</p>}

                    <p>{userBirthDate()}</p>

                    <p>{email}</p>

                    {isPro && <p>{rcsSirenSiret}</p>}
                  </div>

                  <hr className="my-[24px] lg:hidden"></hr>

                  <div className="flex-col flex">
                    <p>{phoneNumber}</p>

                    <p className="capitalize">
                      {streetNumber} {street}
                    </p>

                    <p>{addressComplement}</p>

                    <p className="capitalize">
                      {postalCode} {city}
                    </p>

                    <p className="capitalize">{country}</p>

                    <button
                      className="flex space-x-2 items-center cursor-pointer"
                      onClick={openUpdateInfosModal}
                    >
                      <img
                        src="/images/pencil-icon.svg"
                        alt="pencil-icon"
                        className="w-6 h-6"
                      />

                      <span className="text-design-system/highlight text-sm ">
                        Modifier
                      </span>
                    </button>
                  </div>
                </div>

                <div className="border-t-neutral/gray/gray-20 border-t space-y-4 mt-6 pt-6">
                  {!isTraveler && (
                    <div className="space-y-4">
                      <h4 className="text-[16px] lg:text-2xl font-bold ">
                        Coordonnées bancaires
                      </h4>

                      <p className="text-sm">
                        Dès qu’une première réservation sera effectuée sur vos
                        biens, nous vous contacterons pour procéder à une
                        vérification de votre identité ainsi que de vos
                        coordonnées bancaires. Soyez assuré(e) que cette étape
                        est mise en place dans un souci de sécurité et de
                        protection des utilisateurs de la plateforme.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex w-full grow justify-center items-center">
        <CircularProgress size={60} thickness={4} />
      </div>
    );
  }
};

export default Profile;
