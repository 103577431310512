import React from "react";
import GenericModal from "../Modal";

const CancelationModalContent = (setCancelationModalStep, firstName) => {
  return (
    <>
      <div className=" md:py-[10px] space-y-12 ">
        <div />
        <div className="space-y-8  px-0 md:px-10">
          <h2 className="leading-10 !text-[32px] text-center">
            <p className="font-bold ">
              Êtes-vous sûr de vouloir
              <span className="font-bold  text-center text-main/main-100">
                {" "}
                annuler{" "}
              </span>
              la réservation de {firstName} ?
            </p>
          </h2>

          <div className=" text-center text-zinc-700 leading-relaxed">
            En cliquant sur Confirmer le voyageur sera remboursé et la
            réservation sera supprimée de votre calendrier.
          </div>

          <div className="space-y-3">
            <div className="center ">
              <button
                className="purple-button !w-[70px] !font-medium center"
                onClick={() => {
                  setCancelationModalStep(2);
                }}
              >
                oui
              </button>
            </div>

            <div className="center ">
              <button
                className="white-button !w-[70px] !font-medium center"
                onClick={() => {
                  setCancelationModalStep(0);
                }}
              >
                non
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const CancelationModal = ({
  cancelationModalStep,
  setCancelationModalStep,
  firstName,
}) => {
  return (
    <GenericModal
      children={CancelationModalContent(setCancelationModalStep, firstName)}
      isOpen={cancelationModalStep == 1}
      handleClose={() => {
        setCancelationModalStep(0);
      }}
      withHeader={false}
      height="598px"
    />
  );
};
export default CancelationModal;
