import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { IonIcon } from "@ionic/react";
import { ellipse } from "ionicons/icons";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const customMapStyle = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#878787",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
];

function MapContainer({ properties, filters = false }) {
  const [activeMarker, setActiveMarker] = useState(false);
  const mapRef = useRef(null);

  const onLoad = (map) => {
    if (window.google) {
      mapRef.current = map;

      if (properties?.length > 1) {
        const bounds = new window.google.maps.LatLngBounds();

        properties?.forEach(({ lat, lng }) => {
          if (lat && lng) bounds?.extend({ lat, lng });
        });

        map.fitBounds(bounds);
      } else {
        let position;

        if (properties?.length === 1) {
          position = { lat: properties[0]?.lat, lng: properties[0]?.lng };
        } else {
          position = { lat: filters?.lat, lng: filters?.lng };
        }

        map.setCenter(position);
        map.setZoom(10);
      }
    }
  };

  useEffect(() => {
    const map = mapRef?.current;

    if (window?.google && map) {
      onLoad(map);
    }
  }, [properties, mapRef]);

  const navigateTo = (propertyUid) => {
    const numberOfTravelers = filters?.adults + filters?.children;
    const checkIn = filters?.checkIn;
    const checkOut = filters?.checkOut;
    const arePetsAllowed = filters?.arePetsAllowed;

    window.open(
      `/property/${propertyUid}?travelers=${numberOfTravelers}&checkIn=${checkIn}&checkOut=${checkOut}&arePetsAllowed=${arePetsAllowed}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: customMapStyle,
        gestureHandling: "greedy",
      }}
    >
      {properties?.map(
        (property) =>
          property?.lat &&
          property?.lng && (
            <Marker
              key={property?.uid}
              position={{ lat: property.lat, lng: property.lng }}
              onClick={() => setActiveMarker(property?.uid)}
              icon={{
                url: "/images/slotr-dark-pin.svg",
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            >
              {activeMarker === property?.uid && filters && (
                <InfoWindow onCloseClick={() => setActiveMarker(false)}>
                  <div
                    className="flex h-full items-center bg-white pr-2 cursor-pointer"
                    onClick={() => filters && navigateTo(property?.uid)}
                  >
                    <div className="w-[150px] h-[125px] rounded-l-[4px] flex-none">
                      <img
                        src={property?.picturesUrls[0]}
                        alt={"ad-image"}
                        className="object-cover w-full h-full rounded-l-[4px]"
                      />
                    </div>

                    <div className="space-y-0.5 w-full p-4">
                      <h5 className="line-clamp-1">{property?.title}</h5>

                      <div className=" text-xs font-inter line-clamp-1">
                        <p>{property?.city}</p>
                      </div>

                      <div className="flex space-x-2 items-center text-xs font-inter ">
                        <span className="line-clamp-1">
                          {property?.category}
                        </span>

                        <IonIcon
                          icon={ellipse}
                          className="flex-none w-[3px] h-[3px] text-[#262C36]"
                        ></IonIcon>

                        <span className="line-clamp-1 ">
                          {property?.entireAccomodation
                            ? "Logement entier"
                            : "Une partie du logement"}
                        </span>
                      </div>

                      <p className="text-base font-semibold">
                        {property?.price + "€ "}
                        <span className="text-sm font-normal text-design-system/highlight">
                          /nuit
                        </span>
                      </p>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )
      )}
    </GoogleMap>
  );
}

export default MapContainer;
