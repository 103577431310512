import React from "react";
import useAd from "../../hooks/useAd";
import AdModal from "../ad/AdModal";
import { useNavigate } from "react-router-dom";

const AddWellCreatedContent = () => {
  const { completeAd } = useAd();
  const navigate = useNavigate();

  return (
    <div className="space-y-12">
      <div className="space-y-8  px-0 md:px-10">
        <h2 className="leading-10 text-center">
          Votre annonce
          <br />
          <span className=" text-main/main-100">{completeAd?.title}</span>
          <br />a bien été publiée !
        </h2>

        <div className="center  py-2">
          <img
            src="/images/login.svg"
            alt="slotr-login-star"
            className="  h-[80px] object-contain"
          />
        </div>
        <p className=" text-center text-zinc-700 leading-relaxed text-sm">
          Les voyageurs peuvent maintenant réserver !
          <br />
          <br />
          Complétez votre profil{" "}
          <span className="font-semibold ">
            afin de recevoir les fonds liés aux réservations de vos biens.
          </span>{" "}
          Pour se faire, veuillez renseigner les informations indiquées sur
          votre page profil.
        </p>

        <div className="space-y-3">
          <div className="center">
            <button
              className="white-button !w-[280px] !font-medium center uppercase "
              onClick={() => {
                navigate(`/property/${completeAd?.uid}/edit#calendar`);
              }}
            >
              synchroniser mes calendriers
            </button>
          </div>

          <div className="center ">
            <button
              className="purple-button !w-[280px] !font-medium center uppercase "
              onClick={() => {
                navigate(`/property/${completeAd?.uid}/edit`);
              }}
            >
              VOIR MON ANNONCE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const AddWellCreated = () => {
  return (
    <AdModal
      children={<AddWellCreatedContent />}
      stepNumber={16}
      height="800px"
      withHeader={false}
    />
  );
};
export default AddWellCreated;
