import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useBooking from "../../hooks/useBooking";
import { notify_url } from "../../App";
import Toggle from "../Toggle";
import CompanyCard from "../CompanyCard";
import { API } from "aws-amplify";
import { BUILD_ENV, hostname } from "../../App";
import {
  format,
  differenceInDays,
  subDays,
  getDay,
  isSameDay,
  addDays,
} from "date-fns";
import DatePicker from "./DayPicker";
import "react-day-picker/dist/style.css";
import { calculerTaxeSejour } from "../../utils/calculateTax";
import CounterButton from "../../components/CounterButton";
import { slotrFeePercentage } from "../../App";

const BookingDetails = ({ property, host, onChangePriceNight }) => {
  const { currentUser, loginSubscribeDispatch, getUserInDatabase, UserReload } =
    useAuth();
  const { addEventToCalendar, addDatesFromDatabase, updateImportedCalendars } =
    useBooking();
  const toggleValues = ["Non", "Oui"];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const travelersParams = parseInt(params.get("travelers"));
  const animalsParams = params.get("animals");
  const checkInParams = params.get("checkIn");
  const checkOutParams = params.get("checkOut");
  const [blockedDates, setBlockedDates] = useState(null);
  const defaultSelected = {
    from: checkInParams,
    to: checkOutParams,
  };
  const [reservationSummary, setReservationSummary] = useState({
    merchantUid: host?.dataOfMerchant?.uid,
    hostUid: host?.uid,
    contactEmail: "",
    propertyUid: property?.uid,
    title: property?.title,
    dateRange: { from: null, to: null },
    nbOfNights: null,
    animals: animalsParams === "true" ? "Oui" : "Non",
    travelers:
      !travelersParams ||
      travelersParams > property?.numberOfTravelers ||
      travelersParams < 0
        ? 1
        : travelersParams,
    priceNight: "",
    cleaningFee: property?.withCleaningFee
      ? parseFloat(property?.cleaningFeeAmount)
      : 0,
    additionalTravelerFeeAmount: property?.withAdditionalTravelerFee
      ? parseFloat(property?.additionalTravelerFeeAmount)
      : 0,
    additionalTravelerFeeFrom: property?.withAdditionalTravelerFee
      ? parseFloat(property?.additionalTravelerFeeFrom)
      : 0,
    slotrFeePercentage: slotrFeePercentage / 100,
    vatSlotrFee: 0.2,
  });

  useEffect(() => {
    if (
      reservationSummary?.dateRange?.from &&
      reservationSummary?.dateRange?.to
    ) {
      const totalPricePerNight = calculatePricePerNight(
        reservationSummary?.dateRange?.from,
        reservationSummary?.dateRange?.to
      );

      setReservationSummary({
        ...reservationSummary,
        nbOfNights: differenceInDays(
          reservationSummary?.dateRange?.to,
          reservationSummary?.dateRange?.from
        ),
        priceNight: totalPricePerNight,
      });

      onChangePriceNight(totalPricePerNight);
    }
  }, [reservationSummary?.dateRange]);

  useEffect(() => {
    const getMainContact = async () => {
      let contactEmail = "";

      if (property?.mainContact) {
        const data = await getUserInDatabase(property?.mainContact);

        if (data) {
          contactEmail = data.email;
        }
      } else if (host?.email) {
        contactEmail = host?.email;
      }

      setReservationSummary({
        ...reservationSummary,
        contactEmail: contactEmail,
      });
    };
    if (property) {
      updateImportedCalendars(property.uid);
      getBlockedDates();
      getMainContact();
    }
  }, [property]);

  const handleDatesSelected = (date) => {
    let selectedRange = { ...date };

    if (
      reservationSummary?.dateRange?.from &&
      reservationSummary?.dateRange?.to
    ) {
      if (selectedRange?.to > reservationSummary?.dateRange?.to) {
        selectedRange = { from: selectedRange?.to, to: null };
      } else if (selectedRange?.from < reservationSummary?.dateRange?.from) {
        selectedRange = { from: selectedRange?.from, to: null };
      }
    } else {
      const dateOfArrival = addTimeToDate(
        selectedRange?.from,
        property?.timeOfArrival
      );
      const nbOfDays = differenceInDays(new Date(dateOfArrival), new Date());

      if (selectedRange?.from && selectedRange?.to) {
        const nbOfNights = differenceInDays(
          selectedRange?.to,
          selectedRange?.from
        );

        if (selectedRange?.from.getTime() === selectedRange?.to.getTime()) {
          selectedRange = { from: selectedRange?.from, to: null };
        } else if (nbOfNights > property?.maxNbOfNights) {
          selectedRange = { from: selectedRange?.from, to: null };
          alert(
            `Durée maximale du séjour : ${property?.maxNbOfNights} ${
              property?.maxNbOfNights > 1 ? "nuits" : "nuit"
            }`
          );
        } else if (nbOfNights < property?.minNbOfNights) {
          selectedRange = { from: selectedRange?.from, to: null };
          alert(
            `Durée minimale du séjour : ${property?.minNbOfNights} ${
              property?.minNbOfNights > 1 ? "nuits" : "nuit"
            }`
          );
        } else if (
          selectedRange?.from &&
          nbOfDays < parseInt(property?.minTimeBeforeBooking)
        ) {
          selectedRange = { from: selectedRange?.to, to: null };
          alert(
            `La réservation doit être faite au moins ${
              property?.minTimeBeforeBooking +
              (parseInt(property?.minTimeBeforeBooking) > 1
                ? " jours"
                : " jour")
            } avant`
          );
        } else {
          const isCollisionWithBlockedDay = blockedDates.some((event) => {
            if (event.type !== "price") {
              const blockedDateFrom = new Date(event.startDate);
              return (
                blockedDateFrom > selectedRange.from &&
                blockedDateFrom < selectedRange.to
              );
            }
          });

          if (isCollisionWithBlockedDay) {
            selectedRange = { from: null, to: null };
          }
        }
      } else if (
        selectedRange?.from &&
        nbOfDays < parseInt(property?.minTimeBeforeBooking)
      ) {
        selectedRange = { from: null, to: null };
        alert(
          `La réservation doit être faite au moins ${
            property?.minTimeBeforeBooking +
            (parseInt(property?.minTimeBeforeBooking) > 1 ? " jours" : " jour")
          } avant`
        );
      }
    }

    setReservationSummary({
      ...reservationSummary,
      dateRange: selectedRange,
    });
  };

  const handleAddTravelers = () => {
    if (reservationSummary?.travelers < property?.numberOfTravelers) {
      setReservationSummary({
        ...reservationSummary,
        travelers: reservationSummary?.travelers + 1,
      });
    }
  };

  const handleRemoveTravelers = () => {
    if (reservationSummary?.travelers > 1) {
      setReservationSummary({
        ...reservationSummary,
        travelers: reservationSummary?.travelers - 1,
      });
    }
  };

  const handleCounterOnChange = (e) => {
    let newValue = parseInt(e.target.value);

    if (newValue >= 1 && newValue <= property?.numberOfTravelers) {
      setReservationSummary({
        ...reservationSummary,
        travelers: newValue,
      });
    }

    if (isNaN(newValue)) {
      setReservationSummary({
        ...reservationSummary,
        travelers: "",
      });
    }
  };

  const onBlur = () => {
    if (reservationSummary.travelers === "") {
      setReservationSummary({
        ...reservationSummary,
        travelers: 1,
      });
    }
  };

  const handleAnimalsValue = (e) => {
    setReservationSummary({
      ...reservationSummary,
      animals: e,
    });
  };

  const totalTravelerFee = () => {
    let totalTravelerFee = 0;
    const additionalTravelerFeeFrom = parseInt(
      property?.additionalTravelerFeeFrom
    );

    if (
      property?.withAdditionalTravelerFee &&
      reservationSummary?.travelers > additionalTravelerFeeFrom
    ) {
      const nbOfAdditionalTraveler =
        reservationSummary?.travelers - additionalTravelerFeeFrom;

      totalTravelerFee =
        reservationSummary?.additionalTravelerFeeAmount *
        nbOfAdditionalTraveler;
    }

    return totalTravelerFee.toFixed(2);
  };

  const totalPricePerNight = () => {
    const feesPerNight =
      (reservationSummary?.cleaningFee + parseFloat(totalTravelerFee())) /
      reservationSummary?.nbOfNights;
    const totalPricePerNight =
      feesPerNight + parseInt(reservationSummary?.priceNight);

    return totalPricePerNight.toFixed(2);
  };

  const totalPartnerFee = () => {
    const totalPartnerFee =
      parseFloat(totalSlotrFee()) +
      parseFloat(totalVatSlotrFee()) +
      parseFloat(totalTaxe());

    return totalPartnerFee.toFixed(2);
  };

  const totalPrice = () => {
    const totalPrice =
      reservationSummary?.priceNight * reservationSummary?.nbOfNights +
      reservationSummary?.cleaningFee +
      parseFloat(totalTravelerFee()) +
      parseFloat(totalPartnerFee());

    return totalPrice.toFixed(2);
  };

  const totalTaxe = () => {
    const totalTaxePerNigth = calculerTaxeSejour(
      reservationSummary?.travelers,
      reservationSummary?.travelers,
      reservationSummary?.priceNight,
      property?.postalCode
    );

    const totalTaxe = totalTaxePerNigth * reservationSummary?.nbOfNights;

    return totalTaxe.toFixed(2);
  };

  const totalSlotrFee = () => {
    const totalSlotrFee =
      parseFloat(totalPricePerNight()) *
      reservationSummary?.nbOfNights *
      reservationSummary?.slotrFeePercentage;

    return totalSlotrFee.toFixed(2);
  };

  const totalVatSlotrFee = () => {
    const totalVatSlotrFee =
      parseFloat(totalSlotrFee()) * reservationSummary?.vatSlotrFee;

    return totalVatSlotrFee.toFixed(2);
  };

  const getBlockedDates = async () => {
    try {
      const apiName = "bookingsManager";
      const path = `/dates`;
      const options = {
        queryStringParameters: {
          key: "propertyUid",
          value: property?.uid,
        },
      };

      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(
          `http://${hostname}:3003${path}?key=propertyUid&value=${property?.uid}`
        );
        data = await data.json();
      } else {
        data = await API.get(apiName, path, options);
      }

      if (data) {
        setBlockedDates(data);
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const createTransaction = async () => {
    try {
      const apiName = "bookingsManager";
      const path = `/transactions`;
      let params = {
        merchant_uid: reservationSummary?.merchantUid,
        products: [
          {
            name: reservationSummary.title,
            price: parseFloat(totalPricePerNight()) * 100,
            quantity: reservationSummary.nbOfNights,
          },
          {
            name: "Frais de service et taxes",
            price: parseFloat(totalPartnerFee()) * 100,
            quantity: 1,
          },
        ],
        locale: "fr", //dynamiser avec la langue choisi par l'user
        total_price: parseFloat(totalPrice()) * 100,
        partner_fee: parseFloat(totalPartnerFee()) * 100,
        metadata: {
          travelerUid: currentUser?.uid,
          hostUid: reservationSummary?.hostUid,
          propertyUid: reservationSummary?.propertyUid,
          propertyCountry: property?.country,
          propertyCadastralNumber: property?.registrationNumber,
          propertyUrl: hostname + location.pathname,
        },
        notify_url: notify_url,
      };

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3003${path}`, options);
        data = await data.json();
      } else {
        options["body"] = params;
        data = await API.post(apiName, path, options);
      }

      if (data.uid) {
        const transactionUid = data.metadata.find(
          ({ key }) => key === "slotrTransactionUid"
        ).value;
        const res = await createBooking(transactionUid);

        await addEventToCalendar(property?.calendar, {
          summary: "booking",
          start: new Date(reservationSummary?.dateRange?.from),
          end: new Date(reservationSummary?.dateRange?.to),
          uid: res?.bookingUid,
        });

        window.location.href = data.redirect_url;
      } else {
        throw new Error("Error when creating transaction.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCheckoutSession = async () => {
    try {
      const apiName = "bookingsManager";
      const path = `/createCheckoutSession`;
      let params = {
        title: reservationSummary?.title,
        quantity: reservationSummary?.nbOfNights,
        propertyUid: reservationSummary?.propertyUid,
        price: parseFloat(totalPrice()),
      };

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3003${path}`, options);
        data = await data.json();
      } else {
        options["body"] = params;
        data = await API.post(apiName, path, options);
      }

      if (data?.id) {
        const res = await createBooking(data.id);

        if (res) {
          let bookingData = {
            bookingUid: res?.bookingUid,
            transactionUid: data.id,
            dateUid: res?.dateUid,
            walletAddress: currentUser?.walletAddress,
            propertyUid: reservationSummary?.propertyUid,
            checkIn: addTimeToDate(
              reservationSummary?.dateRange?.from,
              property?.timeOfArrival
            ),
            checkOut: addTimeToDate(
              reservationSummary?.dateRange?.to,
              property?.timeOfDeparture
            ),
          };

          await addEventToCalendar(property?.calendar, {
            summary: "booking",
            start: bookingData.checkIn,
            end: bookingData.checkOut,
            uid: res?.bookingUid,
          });

          if (parseInt(property?.timeOfPreparation) > 0) {
            const objBlockedDatesBeforeBooking = {
              startDate: addTimeToDate(
                subDays(
                  new Date(bookingData.checkIn),
                  parseInt(property?.timeOfPreparation)
                ),
                property?.timeOfArrival
              ),
              endDate: addTimeToDate(
                bookingData.checkIn,
                property?.timeOfDeparture
              ),
              hostUid: reservationSummary.hostUid,
              propertyUid: reservationSummary.propertyUid,
              source: "SlotR",
              type: "blocked-dates",
            };

            const objBlockedDatesAfterBooking = {
              startDate: addTimeToDate(
                bookingData.checkOut,
                property?.timeOfArrival
              ),
              endDate: addTimeToDate(
                addDays(
                  new Date(bookingData.checkOut),
                  parseInt(property?.timeOfPreparation)
                ),
                property?.timeOfDeparture
              ),
              hostUid: reservationSummary.hostUid,
              propertyUid: reservationSummary.propertyUid,
              source: "SlotR",
              type: "blocked-dates",
            };

            const res = await addDatesFromDatabase(
              objBlockedDatesBeforeBooking
            );

            const res2 = await addDatesFromDatabase(
              objBlockedDatesAfterBooking
            );

            bookingData["dateAfterBookingUid"] = res;
            bookingData["dateBeforeBookingUid"] = res2;
          }

          window.localStorage.setItem(
            "bookingData",
            JSON.stringify(bookingData)
          );
          window.location.href = data.url;
        }
      } else {
        throw new Error("Error when creating checkout session");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createBooking = async (transactionUid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/bookings`;
      let params = {
        travelerUid: currentUser?.uid,
        hostUid: reservationSummary?.hostUid,
        propertyUid: reservationSummary?.propertyUid,
        status: "pending",
        checkIn: addTimeToDate(
          reservationSummary?.dateRange?.from,
          property?.timeOfArrival
        ),
        checkOut: addTimeToDate(
          reservationSummary?.dateRange?.to,
          property?.timeOfDeparture
        ),
        nbOfNights: reservationSummary?.nbOfNights,
        numberOfTravelers: reservationSummary?.travelers,
        animals: reservationSummary?.animals,
        price: {
          priceNight: reservationSummary?.priceNight,
          cleaningFee: reservationSummary?.cleaningFee,
          travelerFee: parseFloat(totalTravelerFee()),
          slotrFee: parseFloat(totalSlotrFee()),
          vatSlotrFee: parseFloat(totalVatSlotrFee()),
          tourismTax: parseFloat(totalTaxe()),
          totalPrice: parseFloat(totalPrice()),
        },
        contactEmail: reservationSummary?.contactEmail,
        transactionUid: transactionUid,
        walletAddress: currentUser?.walletAddress,
        updateTime: null,
      };

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3003${path}`, options);
        data = await data.json();
      } else {
        options["body"] = params;
        data = await API.post(apiName, path, options);
      }

      if (!data) {
        throw new Error("Error when creating booking.");
      }

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const isDayBlocked = () => {
    const daysBlocked = [];

    blockedDates?.forEach((event) => {
      if (event.type !== "price") {
        daysBlocked.push({
          from: new Date(event.startDate),
          to: subDays(new Date(event.endDate), 1),
        });
      }
    });

    return daysBlocked;
  };

  const addTimeToDate = (date, timeString) => {
    const newDate = new Date(date);
    const [hours, minutes] = timeString?.split(":");

    newDate?.setHours(parseInt(hours), parseInt(minutes), 0, 0);

    return format(newDate, "yyyy-MM-dd HH:mm:ss");
  };

  const normaliserDate = (date) => {
    return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
  };

  const isWeekendNight = (date) => {
    const day = getDay(date);

    return day === 5 || day === 6;
  };

  const calculatePricePerNight = (startDate, endDate) => {
    let totalPrice = 0;
    const nbOfNights = differenceInDays(endDate, startDate);
    let tempDate = new Date(startDate);

    for (
      let date = tempDate;
      date < endDate;
      tempDate.setDate(tempDate.getDate() + 1)
    ) {
      let appliedPrice = property?.price;
      const currentDate = normaliserDate(date);
      let isWeekend = isWeekendNight(currentDate);

      const specialPriceApplied = blockedDates.some((event) => {
        if (event.type === "price") {
          const startDate = normaliserDate(new Date(event.startDate));
          const sameDay = isSameDay(currentDate, startDate);

          if (sameDay) {
            appliedPrice = event.price;
            return true;
          }
        }
      });

      if (!specialPriceApplied && isWeekend && property?.weekPrice) {
        appliedPrice = property?.weekPrice;
      }

      totalPrice += parseInt(appliedPrice);
    }

    const totalPricePerNight = parseFloat(totalPrice / nbOfNights);

    return Math.floor(totalPricePerNight);
  };

  const propertyBooking = async () => {
    await UserReload();
    if (!currentUser) {
      loginSubscribeDispatch({
        type: "GO TO",
        to: "LoginOrSubscribe",
      });
    } else if (!currentUser?.emailVerified) {
      loginSubscribeDispatch({
        type: "GO TO",
        to: "VerifyEmail",
      });

      //sendEmailForVerification();
    } else if (currentUser?.userType === "traveler") {
      updateImportedCalendars(property?.uid).then(async () => {
        const blockedDates = await getBlockedDates();

        if (blockedDates) {
          const isReserved = blockedDates.some((event) => {
            if (event.type !== "price") {
              const sameDayOfArrival = isSameDay(
                new Date(event.startDate),
                reservationSummary?.dateRange?.from
              );

              const sameDayOfDeparture = isSameDay(
                new Date(event.endDate),
                reservationSummary?.dateRange?.to
              );

              if (sameDayOfArrival && sameDayOfDeparture) {
                return true;
              }
            }
          });

          if (!isReserved) {
            //await createTransaction();
            await createCheckoutSession();
          } else {
            alert(
              "Les dates sélectionnées sont désormais réservées. Veuillez choisir d'autres dates"
            );
          }
        }
      });
    }
  };

  const AdditionalTravelerText = () => {
    let nbOfAdditionalTraveler = 0;
    const additionalTravelerFeeFrom =
      reservationSummary?.additionalTravelerFeeFrom;

    if (
      property?.withAdditionalTravelerFee &&
      reservationSummary?.travelers > additionalTravelerFeeFrom
    ) {
      nbOfAdditionalTraveler =
        reservationSummary?.travelers - additionalTravelerFeeFrom;
    }

    const isPlural = nbOfAdditionalTraveler !== 1;
    const travelerFee = parseInt(totalTravelerFee());

    if (nbOfAdditionalTraveler > 0) {
      return (
        <p className="text-sm">
          {`+ ${nbOfAdditionalTraveler} ${
            isPlural ? "voyageurs" : "voyageur"
          } ${isPlural ? "supplémentaires" : "supplémentaire"} pour ` +
            travelerFee +
            "€"}
        </p>
      );
    }
  };

  return (
    <div className="flex flex-col w-full space-y-10 sticky top-32">
      <div
        className="p-6 lg:p-8 xl:p-10 space-y-8 md:rounded-lg bg-[#F5F5F6]"
        id="travel-details"
      >
        <div className="space-y-4">
          <h5 className="">Détails du voyage</h5>

          <div className="flex flex-col space-y-6 w-full border-b border-neutral/gray/gray-60 pb-8">
            <div className="space-y-1.5">
              <label className="text-sm">Dates</label>

              <div className="max-w-[190px]">
                <DatePicker
                  isDayBlocked={isDayBlocked}
                  handleDatesSelected={handleDatesSelected}
                  selected={reservationSummary?.dateRange}
                />
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between space-x-4 items-center">
                <label className="text-sm">Voyageurs</label>

                <CounterButton
                  handleRemove={handleRemoveTravelers}
                  handleAdd={handleAddTravelers}
                  handleOnChange={(e) => handleCounterOnChange(e)}
                  counter={reservationSummary?.travelers}
                  min={1}
                  max={property?.numberOfTravelers}
                  onBlur={onBlur}
                />
              </div>
              {property?.withAdditionalTravelerFee && (
                <AdditionalTravelerText />
              )}
            </div>

            <div className="flex justify-between space-x-4 items-center">
              <label className="text-sm">Animaux</label>

              <div className="flex items-center">
                <Toggle
                  defaultValue={
                    property?.arePetsAllowed
                      ? reservationSummary?.animals
                      : "Non"
                  }
                  disabled={!property?.arePetsAllowed}
                  firstValue={toggleValues[0]}
                  secondValue={toggleValues[1]}
                  onChange={handleAnimalsValue}
                />
              </div>
            </div>
          </div>
        </div>

        {reservationSummary?.dateRange?.from &&
        reservationSummary?.dateRange?.to &&
        reservationSummary?.priceNight ? (
          <div className="">
            <h5 className="px-2 pb-2">Détail du prix</h5>

            <div className="flex justify-between items-center space-x-4 p-2 border-b border-neutral/gray/gray-40">
              <p className="text-sm font-semibold">
                {reservationSummary?.priceNight + "€"} x{" "}
                {reservationSummary?.nbOfNights +
                  (reservationSummary?.nbOfNights > 1 ? " nuits" : " nuit")}
              </p>

              <p className="text-sm font-semibold">
                {(
                  reservationSummary?.priceNight *
                  reservationSummary?.nbOfNights
                ).toFixed(2) + "€"}
              </p>
            </div>

            {property?.withAdditionalTravelerFee &&
              reservationSummary?.travelers >
                reservationSummary?.additionalTravelerFeeFrom && (
                <div className="flex justify-between items-center space-x-4 p-2 border-b border-neutral/gray/gray-40">
                  <p className="text-sm">Frais par voyageur</p>

                  <p className="text-sm font-semibold">
                    {totalTravelerFee() + "€"}
                  </p>
                </div>
              )}

            {property.withCleaningFee && (
              <div className="flex justify-between items-center space-x-4 p-2 border-b border-neutral/gray/gray-40">
                <p className="text-sm">Frais de ménage</p>

                <p className="text-sm font-semibold">
                  {reservationSummary?.cleaningFee.toFixed(2) + "€"}
                </p>
              </div>
            )}

            <div className="flex justify-between items-center space-x-4 p-2 border-b border-neutral/gray/gray-40">
              <p className="text-sm">
                Frais de service ({slotrFeePercentage}%)
              </p>

              <p className="text-sm font-semibold">{totalSlotrFee() + "€"}</p>
            </div>

            <div className="flex justify-between items-center space-x-4 p-2 border-b border-neutral/gray/gray-40">
              <p className="text-sm">TVA sur frais de service (20%)</p>

              <p className="text-sm font-semibold">
                {totalVatSlotrFee() + "€"}
              </p>
            </div>

            {totalTaxe() > 0 && (
              <div className="flex justify-between items-center space-x-4 p-2 border-b border-neutral/gray/gray-40">
                <p className="text-sm">Taxe de séjour</p>

                <p className="text-sm font-semibold">{totalTaxe() + "€"}</p>
              </div>
            )}

            <div className="flex justify-between items-center space-x-4 p-2 bg-main/main-100/10">
              <p className="text-sm">Prix à payer TTC</p>

              <p className="text-sm font-semibold">{totalPrice() + "€"}</p>
            </div>
          </div>
        ) : (
          <div className="space-y-4 px-2">
            <h5>Détail du prix</h5>

            <p className="text-sm">
              En moyenne{" "}
              <span className="text-base font-semibold">
                {property?.price + "€"}
              </span>{" "}
              /nuit
            </p>
          </div>
        )}

        <div className="space-y-6">
          {reservationSummary?.dateRange?.from &&
            reservationSummary?.dateRange?.to &&
            reservationSummary?.priceNight && (
              <p className="text-xs font-inter">
                En cliquant sur le bouton réserver et payer, vous acceptez les{" "}
                <Link
                  className="underline"
                  to="/documents/Mentions_légales.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  CGU SlotR
                </Link>
                {" et "}
                <a
                  className="underline"
                  href="https://stripe.com/fr/legal/consumer"
                  target="_blank"
                  rel="noreferrer"
                >
                  les termes et les conditions{" "}
                </a>
                de notre prestataire de paiement Stripe
              </p>
            )}

          <div className="flex space-x-2 w-full justify-center">
            <button className="!hidden white-button !bg-transparent !w-1/2">
              Contacter l'hôte
            </button>

            <button
              className="purple-button !w-1/2"
              disabled={
                currentUser?.userType === "host" ||
                !reservationSummary?.dateRange?.from ||
                !reservationSummary?.dateRange?.to ||
                !reservationSummary?.priceNight
                  ? true
                  : false
              }
              onClick={propertyBooking}
            >
              Réserver et payer
            </button>
          </div>
        </div>
      </div>

      {property?.competition?.length > 0 && (
        <div className="px-6 lg:px-10 space-y-6" id="competition">
          <div className="">
            <p className="font-semibold text-center">
              Le même bien sur les autres sites web
            </p>

            <p className="text-sm text-center">depuis le 16 septembre 2023*</p>
          </div>

          <div className="flex py-4 rounded-lg shadow-[0px_0px_10px_0px] shadow-[#EBE7F3]">
            {property?.competition?.map((company) => (
              <CompanyCard
                key={company.companyName}
                data={company}
                nbOfCompanies={property?.competition.length}
              />
            ))}
          </div>

          <p className="text-xs text-center text-design-system/highlight font-inter">
            Ces prix ont été calculés sur base de moyennes quotidiens. Pour plus
            de détails pour référer à nos CGU
          </p>
        </div>
      )}
    </div>
  );
};

export default BookingDetails;
