import React from "react";
import GenericModal from "../Modal";
import useAuth from "../../hooks/useAuth";

const AuthModal = ({
  children,
  isOpen,
  width = "480px",
  height = "580px",
  withHeader = true,
  withLogo = true,
}) => {
  const { loginSubscribeDispatch } = useAuth();

  const handleClose = () => {
    loginSubscribeDispatch({ type: "CLOSE" });
  };
  const handleBack = () => {
    loginSubscribeDispatch({ type: "BACK" });
  };
  return (
    <GenericModal
      children={children}
      isOpen={isOpen}
      handleClose={handleClose}
      handleBack={handleBack}
      width={width}
      height={height}
      withHeader={withHeader}
      withLogo={withLogo}
    ></GenericModal>
  );
};

export default AuthModal;
