const taxes = require("../json/taxes.json");

function calculerTaxeSejour(
  numberOfPersons,
  numberOfAdults,
  priceNight,
  postalCode
) {
  const deliberation = extract(taxes, parseInt(postalCode));
  const processObj = process(deliberation);

  const taxPlafond = processObj.plafond;
  const votedTax = processObj.tarif;

  // Calcul du coût par personne par nuitée
  const coutParPersonneParNuitee = priceNight / numberOfPersons;

  // Calcul de la taxe calculée
  const taxeCalculee = coutParPersonneParNuitee * votedTax;

  // Vérification si la taxe calculée dépasse le plafond
  let taxeReelle;
  if (taxeCalculee <= taxPlafond) {
    taxeReelle = taxeCalculee;
  } else {
    taxeReelle = taxPlafond;
  }

  // Calcul de la taxe de séjour par nuitée
  let taxeSejourParNuitee = taxeReelle * numberOfAdults;

  if (isNaN(taxeSejourParNuitee)) {
    taxeSejourParNuitee = 0;
  }

  return taxeSejourParNuitee;
}

const extract = (json, postal) => {
  const objArr = json?.ocsitan?.deliberations?.deliberation;
  const cp = handleArrondissements(postal);
  //1 ere boucle :

  for (let i = 0; i < objArr.length; i++) {
    let deliberation = objArr[i];
    if (deliberation?.saisie?.collectiviteDeliberante?.codePostal === cp) {
      return deliberation;
    }
    if (objArr[i].collectivites !== "") {
      let villes = objArr[i].collectivites?.collectivite;
      //2 eme boucle :
      for (let j = 0; j < villes.length; j++) {
        if (villes[j].codePostal === cp) {
          return deliberation;
        }
      }
    }
  }

  return 0;
};

const handleArrondissements = (number) => {
  switch (number) {
    case 75000:
      return 75004;
      break;
    case 75001:
      return 75004;
      break;
    case 75002:
      return 75004;
      break;
    case 75003:
      return 75004;
      break;
    case 75005:
      return 75004;
      break;
    case 75006:
      return 75004;
      break;
    case 75007:
      return 75004;
      break;
    case 75008:
      return 75004;
      break;
    case 75009:
      return 75004;
      break;
    case 75010:
      return 75004;
      break;
    case 75011:
      return 75004;
      break;
    case 75012:
      return 75004;
      break;
    case 75013:
      return 75004;
      break;
    case 75014:
      return 75004;
      break;
    case 75015:
      return 75004;
      break;
    case 75016:
      return 75004;
      break;
    case 75017:
      return 75004;
      break;
    case 75018:
      return 75004;
      break;
    case 75019:
      return 75004;
      break;
    case 75020:
      return 75004;
      break;
    case 13001:
      return 13000;
      break;
    case 13002:
      return 13000;
      break;
    case 13003:
      return 13000;
      break;
    case 13004:
      return 13000;
      break;
    case 13005:
      return 13000;
      break;
    case 13006:
      return 13000;
      break;
    case 13007:
      return 13000;
      break;
    case 13008:
      return 13000;
      break;
    case 13009:
      return 13000;
      break;
    case 13010:
      return 13000;
      break;
    case 13011:
      return 13000;
      break;
    case 13012:
      return 13000;
      break;
    case 13013:
      return 13000;
      break;
    case 13014:
      return 13000;
      break;
    case 13015:
      return 13000;
      break;
    case 13016:
      return 13000;
      break;
    case 69001:
      return 69000;
      break;
    case 69002:
      return 69000;
      break;
    case 69003:
      return 69000;
      break;
    case 69004:
      return 69000;
      break;
    case 69005:
      return 69000;
      break;
    case 69006:
      return 69000;
      break;
    case 69007:
      return 69000;
      break;
    case 69008:
      return 69000;
      break;
    case 69009:
      return 69000;
      break;

    default:
      return number;
      break;
  }
};

const process = (deliberation) => {
  if (Array.isArray(deliberation?.periodes?.periode)) {
    const tarif = deliberation?.periodes?.periode[0]?.tarifs?.tarif[8];
    const plafond = deliberation?.periodes?.periode[0]?.tarifs?.tarif[0];
    return {
      tarif: tarif / 100,
      plafond: plafond,
    };
  } else {
    const tarif = deliberation?.periodes?.periode?.tarifs?.tarif[8];
    const plafond = deliberation?.periodes?.periode?.tarifs?.tarif[0];
    return {
      tarif: tarif / 100,
      plafond: plafond,
    };
  }
};

export { calculerTaxeSejour };
