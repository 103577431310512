import React, { useState, useEffect } from "react";
import { BUILD_ENV, hostname } from "../App";
import { API } from "aws-amplify";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import HeroTitle from "../components/HeroTitle";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useBooking from "../hooks/useBooking";
import useAd from "../hooks/useAd";
import CircularProgress from "@mui/material/CircularProgress";
import { subDays, getDate, format, isPast } from "date-fns";
import { fr } from "date-fns/locale";

const BookingSummary = () => {
  const { currentUser } = useAuth();
  const { getBooking } = useBooking();
  const { getProperty } = useAd();
  const navigate = useNavigate();
  const location = useLocation();
  const transactionUid = location.state?.transactionUid;
  const [booking, setBooking] = useState();
  const [property, setProperty] = useState();
  const [minCancellationDate, setMinCancellationDate] = useState();

  useEffect(() => {
    const getBookingData = async () => {
      try {
        const data = await getBooking("transactionUid", transactionUid);

        if (data && currentUser?.uid === data?.[0].travelerUid) {
          setBooking(data?.[0]);
        } else {
          throw new Error("No matching booking.");
        }
      } catch (error) {
        console.log(error);
        navigate("/error");
      }
    };

    if (transactionUid) {
      if (currentUser) {
        getBookingData();
      }
    } else {
      navigate("/error");
    }
  }, [transactionUid, currentUser]);

  useEffect(() => {
    const getPropertyData = async () => {
      try {
        const data = await getProperty(booking?.propertyUid);

        if (data) {
          setMinCancellationDate(
            subDays(
              new Date(booking?.checkIn),
              parseInt(data?.termsOfCancelation)
            )
          );

          setProperty(data);
        } else {
          throw new Error("No matching property.");
        }
      } catch (error) {
        console.log(error);
        navigate("/error");
      }
    };

    if (booking?.propertyUid) {
      getPropertyData();
    }
  }, [booking]);

  const termsOfCancellation = () => {
    if (parseInt(property?.termsOfCancelation) > 0) {
      const isInThePast = isPast(minCancellationDate);

      if (!isInThePast) {
        const minCancellationDay =
          getDate(minCancellationDate) +
          " " +
          format(minCancellationDate, "LLLL yyyy", { locale: fr });

        return (
          "Vous avez jusqu’au " +
          minCancellationDay +
          " pour annuler votre réservation sans frais."
        );
      } else {
        return "Veuillez noter que toute annulation de votre part ne donnera pas droit à un remboursement.";
      }
    } else {
      return "Veuillez noter que toute annulation de votre part ne donnera pas droit à un remboursement.";
    }
  };

  function getBookingDate(dateObj) {
    const datePart = new Date(dateObj).toLocaleDateString();

    return datePart;
  }

  return (
    <main className="flex flex-col flex-grow">
      <HeroTitle
        title={"Ma réservation"}
        maxWidth={"max-w-smallScreen"}
        hidden={"flex"}
      />

      {!minCancellationDate ? (
        <div className="flex w-full h-full flex-grow justify-center items-center">
          <CircularProgress size={60} thickness={4} />
        </div>
      ) : (
        <div className="flex flex-col max-w-smallScreen h-full mx-auto px-6 md:px-10 lg:px-16 pt-[40px] md:pt-[60px] lg:pt-[90px] space-y-10 lg:space-y-12 w-full">
          <section className="flex space-x-7 items-center">
            <IonIcon
              icon={checkmarkCircleOutline}
              className="w-12 h-12 text-semantic/succes flex-none -mx-1"
            ></IonIcon>

            <h1 className="font-bold leading-[130%]">
              Votre réservation{" "}
              <span className="text-main/main-100">{property?.title}</span>
              <br /> est confirmée !
            </h1>
          </section>

          <section className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-16 xl:space-x-24 w-full">
            <div className="space-y-10 lg:space-y-8 w-full">
              <p className="text-design-system/highlight">
                Un mail de confirmation vous a été envoyé
              </p>

              <div className="flex pb-10 border-b border-neutral/gray/gray-40 space-x-6">
                <div className="space-y-4 w-[50%] xl:w-[40%]">
                  <h5 className="text-black font-bold">Arrivée</h5>

                  <div className="space-y-1">
                    <p className="font-semibold">
                      {getBookingDate(booking?.checkIn)}
                    </p>

                    <p>{property?.timeOfArrival}</p>
                  </div>
                </div>

                <div className="space-y-4 w-[50%] xl:w-[40%]">
                  <h5 className="text-black font-bold">Départ</h5>

                  <div className="space-y-1">
                    <p className="font-semibold">
                      {getBookingDate(booking?.checkOut)}
                    </p>

                    <p>{property?.timeOfDeparture}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <h5 className="text-black font-bold">Annulation</h5>

                <p>{termsOfCancellation()}</p>
              </div>

              <div className="space-y-6">
                <div className="space-y-2">
                  <h5 className="text-black font-bold">Contacter l’hôte</h5>

                  <p>
                    Pour planifier au mieux votre voyage, n’hésite pas à
                    contacter l’hôte.
                  </p>
                </div>

                <button
                  className="white-button"
                  onClick={() =>
                    (window.location = "mailto:" + booking?.contactEmail)
                  }
                >
                  CONTACTER L’HÔTE
                </button>
              </div>
            </div>

            <div className="w-full space-y-4 max-w-[385px]">
              <img
                src={property?.picturesUrls[0]}
                alt="image-property"
                className="object-contain rounded-lg w-full h-auto"
              />

              <div className="">
                <p className="leading-[160%]">{property?.category}</p>

                <p className="leading-[160%]">
                  {property?.streetNumber +
                    " " +
                    property?.street +
                    ", " +
                    property?.postalCode +
                    " " +
                    property?.city}
                </p>

                <p>{property?.country}</p>
              </div>

              <div className="flex justify-between p-2 bg-main/main-100/10">
                <p className="text-sm">Coût total</p>

                <p className="text-sm font-semibold">
                  {booking?.price.totalPrice + "€"}
                </p>
              </div>

              <div className="py-2">
                <Link to={`/property/${property?.uid}`}>
                  <button className="purple-button uppercase">
                    voir l’annonce
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      )}
    </main>
  );
};

export default BookingSummary;
