import { useState } from "react";
import { Checkbox } from "@material-tailwind/react";

const SelectProperties = ({
  eventsHelper,
  setShowBookings,
  showBookings,
  withSeeMore = false,
}) => {
  const {
    showBookingsDefaultStateFalse,
    showBookingsDefaultStateTrue,
    colorsActive,
    colorsInactive,
    properties,
    propertiesId,
    numberOfColors,
  } = eventsHelper;
  const [selectAll, setSelectAll] = useState(true);
  const [seeMore, setSeeMore] = useState(true);

  function CheckboxProperty({
    label = "",
    color = "black",
    bgColor = "",
    propertyId = 10,
  }) {
    function handleSelect(id) {
      setShowBookings({ ...showBookings, [id]: !showBookings[id] });
    }
    return (
      <div
        className={` flex jutify-center rounded-full  h-[90%] py-[1px] pr-4 ${
          showBookings[propertyId] ? bgColor : ""
        } `}
      >
        <Checkbox
          onChange={() => {
            return handleSelect(propertyId);
          }}
          checked={showBookings[propertyId]}
          className={`h-5 w-5 rounded-full border-[5px] checked:before:black  p-0 m-0 bg-white `}
          label={<div className="text-black ">{label}</div>}
          icon={
            <svg height="30" width="30">
              <circle
                cx="15"
                cy="15"
                r="8"
                stroke="white"
                strokeWidth="4.7"
                fill={color}
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    );
  }

  const CheckboxSelectAll = ({ label = "", color = "black" }) => {
    function handleSelectAll() {
      if (selectAll) {
        setShowBookings(showBookingsDefaultStateFalse);
      } else {
        setShowBookings(showBookingsDefaultStateTrue);
      }

      setSelectAll(!selectAll);
    }

    return (
      <div className={` flex jutify-center rounded-full  h-[90%]  `}>
        <Checkbox
          onChange={handleSelectAll}
          checked={selectAll}
          className={`h-5 w-5 rounded-full border-[5px]  checked:before:black  p-0 m-0 bg-white `}
          label={<div className="text-black">{label}</div>}
          icon={
            <svg height="30" width="30">
              <circle
                cx="15"
                cy="15"
                r="8"
                stroke="white"
                strokeWidth="2.7"
                fill={color}
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    );
  };
  const ListOfProperties = () => {
    let bgColor = "bg-[#F1E5C3]";
    bgColor = "bg-[#D1DED0]";
    bgColor = "bg-[#A4A7C9]";
    bgColor = "bg-[#AED7D5]";
    bgColor = "bg-[#ECCACA]";
    return (
      <div className="grid grid-cols-1 gap-1">
        {properties &&
          Object.entries(properties)?.map(([propertyId, property], index) => {
            bgColor = "bg-[" + colorsInactive[index % numberOfColors] + "]";
            return (
              <CheckboxProperty
                color={colorsActive[index % numberOfColors]}
                label={property}
                bgColor={bgColor}
                withBorder={false}
                propertyId={propertyId}
                key={index}
              />
            );
          })}
      </div>
    );
  };
  return (
    <div className="bg-[#F9F9F9] rounded-2xl p-4 py-6 h-min  ">
      <div className="grid grid-cols-1 customedScrollNoPadding">
        <div>
          <h5 className="text-[20px] font-bold center">
            Sélectionner mes biens
          </h5>
        </div>

        <div className={`flex  pt-2 ${withSeeMore ? "justify-between " : ""} `}>
          <CheckboxSelectAll
            color="black"
            label={"Tous les biens (" + propertiesId?.length + ")"}
            selectOne={false}
          />
          {withSeeMore && !seeMore && (
            <button
              className="flex flex-row gap-2 cursor-pointer w-[90px] -mr-3"
              onClick={() => {
                setSeeMore(true);
              }}
            >
              <div className="center text-sm">Voir plus</div>
              <img
                className="w-3 center"
                src={"/images/navigation-signs/arrowDown.svg"}
                alt="arrow down"
              />
            </button>
          )}
          {withSeeMore && seeMore && (
            <button
              className="flex flex-row gap-2 cursor-pointer  w-[110px] -mr-3"
              onClick={() => {
                setSeeMore(false);
              }}
            >
              <div className="center text-sm">Voir moins</div>
              <img
                className="w-3 center"
                src={"/images/navigation-signs/arrowUp.svg"}
                alt="arrow down"
              />
            </button>
          )}
        </div>

        {(!withSeeMore || (withSeeMore && seeMore)) && (
          <div className="overflow-y-auto  h-full lg:max-h-[850px] mt-6 pr-3">
            <ListOfProperties />
          </div>
        )}
      </div>
    </div>
  );
};
export default SelectProperties;
