import React from "react";
import AuthModal from "./AuthModal";
import useAuth from "../../hooks/useAuth";
const PrivateHostOrProfessionnalContent = () => {
  const { loginSubscribeDispatch } = useAuth();

  return (
    <div className="flex flex-col justify-center h-full space-y-32 md:space-y-16">
      <div className="space-y-16">
        <div className="center">
          <h2 className="font-bold center xs:text-[28px] leading-10 flex-wrap">
            Créer un compte{" "}
            <span className="pl-2 text-main/main-100">hôte</span>
          </h2>
        </div>

        <div className="space-y-4">
          <div className="center">
            <button
              className="purple-button !w-[220px] "
              onClick={() =>
                loginSubscribeDispatch({
                  type: "GO TO",
                  to: "PrivateHostAccount",
                })
              }
            >
              JE SUIS UN PARTICULIER
            </button>
          </div>

          <div className="center">
            <button
              className="white-button !w-[220px] "
              onClick={() =>
                loginSubscribeDispatch({
                  type: "GO TO",
                  to: "ProfessionalHostAccount",
                })
              }
            >
              JE SUIS UN PROFESSIONNEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const PrivateHostOrProfessionnal = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={PrivateHostOrProfessionnalContent()}
      isOpen={loginSubscribeState.openModal == "PrivateHostOrProfessional"}
    />
  );
};
export default PrivateHostOrProfessionnal;
