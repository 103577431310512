import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? process.env.REACT_APP_FIREBASE_CONFIG_PROD
    : process.env.REACT_APP_FIREBASE_CONFIG_STAGING;

const app = initializeApp(JSON.parse(firebaseConfig));
const auth = getAuth(app);

export { app, auth };
