import React, { useMemo, createContext, useContext } from "react";
import { API } from "aws-amplify";
import { BUILD_ENV } from "../App";
import { hostname } from "../App";

const BookingContext = createContext({});
export function BookingProvider({ children }) {
  const getBookingsOfAHost = async (userUid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/bookingsOfAHost`;
      const options = {
        queryStringParameters: {
          userUid: userUid,
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(
          `http://${hostname}:3003${path}?userUid=${userUid}`
        );
        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getBookingsOfATraveler = async (userUid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/bookingsOfATraveler`;
      const options = {
        queryStringParameters: {
          userUid: userUid,
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(
          `http://${hostname}:3003${path}?userUid=${userUid}`
        );
        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getBooking = async (key, value) => {
    try {
      const apiName = "bookingsManager";
      const path = `/bookings`;
      const options = {
        queryStringParameters: {
          key: key,
          value: value,
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(
          `http://${hostname}:3003${path}?key=${key}&value=${value}`
        );
        data = await data.json();
      } else {
        data = await API.get(apiName, path, options);
      }

      if (data) {
        return data;
      } else {
        throw new Error("No matching booking.");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const addDatesFromDatabase = async (data) => {
    try {
      const apiName = "bookingsManager";
      const path = `/dates`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(data);
        response = await fetch(`http://${hostname}:3003${path}`, options);
        response = await response.json();
      } else {
        options["body"] = data;
        response = await API.post(apiName, path, options);
      }
      return response.dateUid;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateDatesFromDatabase = async (dateUid, params) => {
    try {
      const apiName = "bookingsManager";
      const path = `/dates/${dateUid}`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3003${path}`, options);
      } else {
        options["body"] = params;
        data = await API.put(apiName, path, options);
      }

      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getDatesFromDatabase = async (key, value) => {
    try {
      const apiName = "bookingsManager";
      const path = `/dates`;
      const options = {
        queryStringParameters: {
          key: key,
          value: value.join(","),
        },
      };

      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(
          `http://${hostname}:3003${path}?key=${options.queryStringParameters.key}&value=${options.queryStringParameters.value}`
        );
        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteDatesFromDatabase = async (dateUid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/dates/${dateUid}`;
      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(`http://${hostname}:3003${path}`, {
          method: "DELETE",
        });
        response = await response.json();
      } else {
        response = await API.del(apiName, path);
      }
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const removeEventFromCalendar = async (calendarUrl, uid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/calendar/removeEvent`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "DELETE";
        options["body"] = JSON.stringify({ calendarUrl, uid });

        response = await fetch(`http://${hostname}:3003${path}`, options);
      } else {
        options["body"] = { calendarUrl, uid };
        response = await API.del(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteBlockedDates = async (dateUid, propertyId, calendarUrl) => {
    await deleteDatesFromDatabase(dateUid);
    await removeEventFromCalendar(calendarUrl, dateUid);
  };

  //---------------------------------------------------------------------Calendars--------------------------------------------------------------------
  const createNewCalendarInS3 = async (userUid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/calendar/${userUid}`;
      let options = {
        headers: {
          Accept: "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";

        response = await fetch(`http://${hostname}:3003${path}`, options);
        response = await response.json();
      } else {
        response = await API.post(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const addEventToCalendar = async (calendarUrl, event) => {
    try {
      const apiName = "bookingsManager";
      const path = `/calendar/addEvent`;
      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";
        options["body"] = JSON.stringify({ calendarUrl, event });
        response = await fetch(`http://${hostname}:3003${path}`, options);
      } else {
        options["body"] = { calendarUrl, event };
        response = await API.put(apiName, path, options);
      }
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteAllDatesOfImportedCalendar = async (calendarUrl, hostUid) => {
    try {
      const apiName = "bookingsManager";
      const path = "/allDatesOfImportedCalendars";

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "DELETE";
        options["body"] = JSON.stringify({ calendarUrl, hostUid });

        response = await fetch(`http://${hostname}:3003${path}`, options);
      } else {
        options["body"] = { calendarUrl, hostUid };
        response = await API.del(apiName, path, options);
      }
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateImportedCalendars = async (propertyId) => {
    try {
      const apiName = "bookingsManager";
      const path = `/updateImportedCalendars/${propertyId}`;
      let options = {
        headers: {
          Accept: "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";

        response = await fetch(`http://${hostname}:3003${path}`, options);
        response = await response.json();
      } else {
        response = await API.put(apiName, path, options);
      }
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const memodValue = useMemo(
    () => ({
      createNewCalendarInS3,
      addDatesFromDatabase,
      updateDatesFromDatabase,
      getDatesFromDatabase,
      deleteBlockedDates,
      updateImportedCalendars,
      addEventToCalendar,
      deleteAllDatesOfImportedCalendar,
      getBooking,
      getBookingsOfAHost,
      getBookingsOfATraveler,
    }),
    []
  );
  return (
    <BookingContext.Provider value={memodValue}>
      {children}
    </BookingContext.Provider>
  );
}
export default function useBooking() {
  return useContext(BookingContext);
}
