import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="flex flex-grow items-center max-w-smallScreen w-full mx-auto  px-6 md:px-10 lg:px-16">
      <div className="bg-particles flex justify-center items-center w-full h-[530px] ">
        <div className="flex flex-col items-center space-y-8">
          <img
            src="/images/oops.png"
            alt="oops-img"
            className="w-auto max-h-[165px]"
          />

          <p className="text-center">
            Il semblerait que cette page n’existe pas, peut-être même qu’elle
            n’existe plus.
          </p>

          <Link to="/">
            <button className="purple-button">Retour à l’accueil</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
