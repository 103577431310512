import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { API } from "aws-amplify";
import { BUILD_ENV } from "../App";
import { hostname } from "../App";
import { useLocation, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const AdContext = createContext({});

export function AdProvider({ children }) {
  const defaultStartAd = {
    category: "",
    entireAccomodation: "",
    streetNumber: "",
    street: "",
    addressComplement: "",
    city: "",
    postalCode: "",
    country: "France",
    title: "",
    description: "",
    numberOfTravelers: 1,
    numberOfBedrooms: 0,
    numberOfBeds: 1,
    picturesUrls: [],
    picturesFiles: [],
    price: 10,
    userConditionsChecked: false,
  };

  const defaultCompleteAd = {
    services: [],
    termsOfCancelation: "",
    rulesOfProcedure: "",
    timeOfArrival: "16:00",
    timeOfDeparture: "11:00",
    timeOfPreparation: 0,
    minTimeBeforeBooking: 1,
    arePetsAllowed: "",
    withCleaningFee: false,
    withAdditionalTravelerFee: false,
    cleaningFeeAmount: "",
    additionalTravelerFeeAmount: "",
    additionalTravelerFeeFrom: "",
    registrationNumber: "",
    isClassifiedProperty: "",
    importedCalendars: [],
    lastCalendarsUpdate: "",
    minNbOfNights: 1,
    maxNbOfNights: 30,
    coHosts: [],
    mainContact: "",
  };

  let defaultFiltersAd = {
    isOpen: false,
    destination: "",
    placeId: "",
    lat: "",
    lng: "",
    checkIn: "",
    checkOut: "",
    adults: 1,
    children: 0,
    babies: 0,
    minPrice: 0,
    maxPrice: 99999,
    numberOfBeds: 0,
    numberOfBedrooms: 0,
    arePetsAllowed: false,
    entireAccomodation: "*",
    categories: [],
    services: [],
    prices: [0, 99999],
    filterSubmit: false,
  };

  const [activeStep, setActiveStep] = useState(-1);
  const [startAd, setStartAd] = useState(defaultStartAd);
  const [completeAd, setCompleteAd] = useState(defaultCompleteAd);
  const [filtersAd, setFiltersAd] = useState(defaultFiltersAd);
  const [editAd, setEditAd] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const location = useLocation();
  const { propertyUid } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (activeStep >= 0) {
      window.localStorage.setItem(
        `CREATE_AN_AD_STATE_${currentUser?.uid}`,
        JSON.stringify(startAd)
      );
    }
  }, [startAd]);

  useEffect(() => {
    if (activeStep >= 10) {
      window.localStorage.setItem(
        `COMPLETE_AD_STATE_${completeAd?.uid}`,
        JSON.stringify(completeAd)
      );
    }
  }, [completeAd]);

  useEffect(() => {
    if (location.pathname == `/property/${propertyUid}/edit`) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [location]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep == 8) {
      setActiveStep(6);
    } else if (activeStep == 17) {
      setActiveStep(15);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const close = () => {
    if (activeStep === 7 || activeStep === 16) {
      window.location.reload();
    }

    setActiveStep(-1);
  };

  const handleChange = (event, kind) => {
    let target = event.target.value;
    setStartAd({ ...startAd, [`${kind}`]: target });
  };

  /*-------------------------------------------------Add proprety To Blockchain-----------------------------------------------------*/
  const addPropertyToBlockchain = async (propertyUid, category) => {
    try {
      const apiName = "web3Manager";
      const path = `/newProperty`;
      let options = {
        queryStringParameters: {
          propertyUid: propertyUid,
          category: category,
        },
      };

      let data;
      if (BUILD_ENV === "localhost") {
        data = await fetch(
          `http://${hostname}:3004${path}?propertyUid=${propertyUid}&category=${category}`,
          { method: "POST" }
        );
        data = await data.json();
      } else {
        data = await API.post(apiName, path, options);
      }

      if (data.msg) {
        console.log(data.msg);
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /*-------------------------------------------------Add proprety To Database-----------------------------------------------------*/
  const addPropertyToDatabase = async (dataOfProperty) => {
    try {
      const apiName = "propertiesManager";
      const path = `/properties`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(dataOfProperty);

        response = await fetch(`http://${hostname}:3001${path}`, options);
        response = await response.json();
      } else {
        options["body"] = dataOfProperty;

        response = await API.post(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  /*-------------------------------------------------Get proprety From Database-----------------------------------------------------*/
  const getProperty = async (propertyUid) => {
    try {
      const apiName = "propertiesManager";
      const path = `/properties/${propertyUid}`;

      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3001${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      if (data) {
        return data;
      } else {
        throw new Error("No matching property.");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------Update proprety From Database-----------------------------------------------------*/
  const updatePropertyFromDatabase = async (propertyId, dataOfProperty) => {
    try {
      const apiName = "propertiesManager";
      const path = `/properties/${propertyId}`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";
        options["body"] = JSON.stringify(dataOfProperty);

        response = await fetch(`http://${hostname}:3001${path}`, options);
        response = await response.json();
      } else {
        options["body"] = dataOfProperty;

        response = await API.put(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  /*-------------------------------------------------Upload Picture To S3-----------------------------------------------------*/
  const uploadPicturesToS3 = async (uid, picture) => {
    if (picture) {
      try {
        const formData = new FormData();
        formData.append("uid", uid);
        formData.append("file", picture);

        const apiName = "propertiesManager";
        const path = `/uploadPictures`;
        let options = {
          body: formData,
        };
        let response;

        if (BUILD_ENV === "localhost") {
          options["method"] = "POST";

          response = await fetch(`http://${hostname}:3001${path}`, options);
          response = await response.json();
        } else {
          response = await API.post(apiName, path, options);
        }

        return response;
      } catch (error) {
        console.log("Error uploading file:", error);
      }
    }
  };

  /*-------------------------------------------------Delete proprety-----------------------------------------------------*/
  const deleteProperty = async (propertyUid) => {
    try {
      const apiName = "propertiesManager";
      const path = `/properties/${propertyUid}`;

      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3001${path}`, {
          method: "DELETE",
        });
        data = await data.json();
      } else {
        data = await API.del(apiName, path);
      }

      if (data) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  /*-------------------------------------------------Get proprety Of An Host-----------------------------------------------------*/
  const getPropertiesOfAnHost = async (userUid) => {
    try {
      const apiName = "propertiesManager";
      const path = `/propertiesOfAnHost/${userUid}`;

      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(`http://${hostname}:3001${path}`);
        response = await response.json();
      } else {
        response = await await API.get(apiName, path);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getPropertiesOfAnHostByStatus = async (userUid, status) => {
    try {
      const apiName = "propertiesManager";
      const path = `/propertiesOfAnHostByStatus`;
      const options = {
        queryStringParameters: {
          userUid: userUid,
          status: status,
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        response = await fetch(
          `http://${hostname}:3001${path}?userUid=${userUid}&status=${status}`
        );
        response = await response.json();
      } else {
        response = await API.get(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const addProperty = async (uid, dataOfProperty) => {
    try {
      const picturesUrls = await Promise.all(
        dataOfProperty.picturesFiles.map(async (i) => {
          const picturesUrl = await uploadPicturesToS3(uid, i);
          return picturesUrl;
        })
      );

      if (picturesUrls[0] !== undefined) {
        let data = { ...dataOfProperty };
        delete data.userConditionsChecked;
        delete data.picturesFiles;
        data["userUid"] = uid;
        data["picturesUrls"] = picturesUrls;

        const property = await addPropertyToDatabase(data);

        if (property?.id) {
          await addPropertyToBlockchain(property?.id, data?.category);

          setCompleteAd({
            ...completeAd,
            uid: property?.id,
            title: data?.title,
          });
        }

        return property;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const memodValue = useMemo(
    () => ({
      activeStep,
      startAd,
      completeAd,
      filtersAd,
      isEdit,
      editAd,
      defaultFiltersAd,
      setActiveStep,
      setStartAd,
      setCompleteAd,
      setFiltersAd,
      setEditAd,
      handleNext,
      handleBack,
      handleChange,
      close,
      addProperty,
      getProperty,
      deleteProperty,
      getPropertiesOfAnHost,
      getPropertiesOfAnHostByStatus,
      updatePropertyFromDatabase,
      uploadPicturesToS3,
    }),
    [activeStep, startAd, completeAd, filtersAd, isEdit, editAd]
  );
  return <AdContext.Provider value={memodValue}>{children}</AdContext.Provider>;
}
export default function useAd() {
  return useContext(AdContext);
}
