import useAd from "../../hooks/useAd";

const NextButton = ({ isDisabled = false, handleClick = true }) => {
  const { handleNext } = useAd();
  if (handleClick === true) {
    handleClick = handleNext;
  }
  return (
    <button
      disabled={isDisabled}
      onClick={handleClick}
      className="flex normal-case bg-white rounded-lg text-sm shadow-none hover:shadow-none space-x-2"
    >
      <p className=" text-black capitalize ">Suivant</p>

      <img
        src="/images/purple-arrow.svg"
        alt="next arrow"
        className="w-6 object-cover center "
      />
    </button>
  );
};
export default NextButton;
