import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const MenuScroll = ({ sections, defautlValue }) => {
  const location = useLocation();
  const [currentSectionId, setCurrentSectionId] = useState(
    location.hash.replace("#", "") || defautlValue
  );

  useEffect(() => {
    if (currentSectionId !== defautlValue) {
      scrollTo(currentSectionId);
    }
  }, []);

  function scrollTo(sectionId) {
    const element = document.getElementById(sectionId);

    if (element) {
      window.history.pushState({}, "", `#${sectionId}`);
      setCurrentSectionId(sectionId);
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  return (
    <ul className="flex space-x-6 overflow-x-auto scrollbar-hide pr-5 border-b border-neutral/gray/gray-40">
      {sections?.map((section) => (
        <li
          key={section?.id}
          className={`flex-none ${
            section?.id === "travel-details" && "lg:hidden"
          }`}
        >
          <div
            className={`pb-2 cursor-pointer lg:text-base ${
              currentSectionId === section?.id
                ? "border-b-2 border-main/main-100 font-semibold"
                : "border-none text-neutral/gray/gray-60"
            }`}
          >
            <a
              href={"#" + section?.id}
              onClick={(e) => {
                e.preventDefault();
                scrollTo(section?.id);
              }}
            >
              {section?.name}
            </a>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default MenuScroll;
