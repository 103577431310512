const geocodingPlace = (key, place) => {
  return new Promise((resolve, reject) => {
    if (place) {
      let geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ [key]: place }, function (responses, status) {
        if (status == "OK") {
          let lat = responses[0].geometry.location.lat();
          let lng = responses[0].geometry.location.lng();

          resolve({ lat, lng });
        } else {
          reject("Geocoding failed: " + status);
        }
      });
    } else {
      reject("No place provided");
    }
  });
};

export { geocodingPlace };
