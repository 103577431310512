import React from "react";
import GenericModal from "../Modal";

const WellCanceledModalContent = (setCancelationModalStep, firstName) => {
  return (
    <>
      <div className=" pt-[110px] ">
        <div className="space-y-[60px]  px-0 md:px-10">
          <h2 className="leading-10 !text-[32px] text-center">
            <p className="font-bold ">
              La réservation de {firstName} a bien été annulée
            </p>
          </h2>

          <div className="space-y-5">
            <div className="center ">
              <button
                className="purple-button !w-[225px] !font-medium center"
                onClick={() => {}}
              >
                Contacter le voyageur
              </button>
            </div>

            <div className="center ">
              <button
                className="white-button !w-[225px] !font-medium center"
                onClick={() => {
                  setCancelationModalStep(0);
                }}
              >
                Voir mes réservations
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const WellCanceledModal = ({
  cancelationModalStep,
  setCancelationModalStep,
  firstName,
}) => {
  return (
    <GenericModal
      children={WellCanceledModalContent(setCancelationModalStep, firstName)}
      isOpen={cancelationModalStep == 2}
      handleClose={() => {
        setCancelationModalStep(0);
      }}
      withHeader={false}
      height="598px"
    />
  );
};
export default WellCanceledModal;
