import { useEffect, useState } from "react";

const useWindowsSize = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      if (screenSize !== window.innerWidth) {
        setScreenSize(window.innerWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
    return screenSize;
  };
  
  export default useWindowsSize;