import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { InputAdornment, TextField } from "@mui/material";

export default function RangeSlider({ filterState, setfilterState }) {
  const { minPrice, maxPrice, prices } = filterState;

  const handleChange = (e, newValue) => {
    setfilterState({ ...filterState, prices: newValue });
  };

  const handleInputChange = (e, key) => {
    let price = parseInt(e.target.value);
    let newValue = [...prices];

    if (key === "minPrice") {
      if (isNaN(price)) {
        newValue[0] = "";
      } else {
        newValue[0] = price;
      }

      setfilterState({ ...filterState, prices: newValue });
    } else if (key === "maxPrice") {
      if (isNaN(price)) {
        newValue[1] = "";
      } else {
        newValue[1] = price;
      }

      setfilterState({ ...filterState, prices: newValue });
    }
  };

  const onBlur = (key) => {
    let newValue = [...prices];

    if (
      key === "minPrice" &&
      (prices[0] === "" || prices[0] > maxPrice || prices[0] > prices[1])
    ) {
      newValue[0] = minPrice;
    } else if (
      key === "maxPrice" &&
      (prices[1] === "" || prices[1] < minPrice || prices[1] < prices[0])
    ) {
      newValue[1] = maxPrice;
    }

    setfilterState({ ...filterState, prices: newValue });
  };

  return (
    <Box className="space-y-2">
      <div className="grid grid-cols-3 sm:grid-cols-4">
        {/*------------------------------Minimum---------------------------------- */}
        <div>
          <div className="font-normal text-sm text-black ">Minimum</div>

          <TextField
            value={prices[0]}
            size="small"
            onChange={(e) => handleInputChange(e, "minPrice")}
            onBlur={() => onBlur("minPrice")}
            type="number"
            InputProps={{
              style: { fontSize: "font-normal text-sm " },
              endAdornment: (
                <InputAdornment position="end">&#8364;</InputAdornment>
              ),
            }}
          />
        </div>

        {/*------------------------------Maximum---------------------------------- */}
        <div className="col-start-3 sm:col-start-4">
          <div className="font-normal text-sm text-black">Maximum</div>

          <TextField
            value={prices[1]}
            size="small"
            onChange={(e) => handleInputChange(e, "maxPrice")}
            onBlur={() => onBlur("maxPrice")}
            type="number"
            InputProps={{
              style: { fontSize: "font-normal text-sm " },
              endAdornment: (
                <InputAdornment position="end">&#8364;</InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      {/*------------------------------Slider---------------------------------- */}
      <div className="px-2.5">
        <Slider
          value={prices}
          onChange={handleChange}
          valueLabelDisplay="auto"
          min={minPrice}
          max={maxPrice}
          sx={{
            "& .MuiSlider-thumb": {
              color: "var(--first-color)",
            },
            "& .MuiSlider-track": {
              color: "var(--first-color)",
            },
            "& .MuiSlider-rail": {
              color: "var(--neutral-gray)",
            },
            "& .MuiSlider-active": {
              color: "var(--first-color)",
            },
          }}
        />
      </div>
    </Box>
  );
}
