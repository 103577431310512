import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { closeOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import CarouselSlider from "../CarouselSlider";

const Gallery = ({ pictures }) => {
  const [isOpenFullGallery, setIsOpenFullGallery] = useState(false);
  const [isOpenFullImage, setIsOpenFullImage] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  const handleFullImage = (index) => {
    setActiveImage(index);
    setIsOpenFullImage(true);
  };

  return (
    <>
      <div className="hidden lg:flex px-6 lg:px-0 cursor-pointer">
        <div className="relative w-full">
          <div className="grid grid-cols-3 auto-rows-[500px] w-full gap-4">
            <img
              alt="gallery"
              className="block col-span-2 h-full w-full rounded-l-lg object-cover object-center"
              src={pictures?.[0]}
              onClick={() => setIsOpenFullGallery(true)}
            />

            <div className="col-span-1 grid grid-cols-1 auto-rows-fr gap-4">
              <img
                alt="gallery"
                className="h-full w-full rounded-tr-lg object-cover object-center"
                src={pictures?.[1]}
                onClick={() => setIsOpenFullGallery(true)}
              />

              <img
                alt="gallery"
                className="block h-full w-full rounded-br-lg object-cover object-center"
                src={pictures?.[2]}
                onClick={() => setIsOpenFullGallery(true)}
              />
            </div>
          </div>

          <div className="absolute bottom-4 right-4">
            <button
              className="uppercase text-neutral/gray/gray-100 bg-white text-sm font-semibold rounded-full py-2 px-8"
              onClick={() => setIsOpenFullGallery(true)}
            >
              Voir plus
            </button>
          </div>
        </div>
      </div>

      <Modal open={isOpenFullGallery} className="hidden lg:flex">
        <Box
          className={`flex flex-col px-8 items-center h-full w-full bg-white z-50 overflow-y-auto scrollbar-hide`}
          sx={{ outline: "none" }}
        >
          <div className="sticky top-0 w-full flex justify-end py-6 bg-white">
            <IonIcon
              icon={closeOutline}
              className="w-10 h-10 text-main/dark-100 p-[2px] cursor-pointer"
              onClick={() => setIsOpenFullGallery(false)}
            />
          </div>

          <div className="grid grid-cols-2 gap-4 pb-8 px-20 w-full max-w-[1180px]">
            {pictures?.map((image, index) => (
              <img
                key={index}
                className="col-span-1 object-cover w-full h-[400px] rounded-lg cursor-pointer"
                src={image}
                onClick={() => handleFullImage(index)}
              />
            ))}
          </div>
        </Box>
      </Modal>

      <Modal open={isOpenFullImage} className="hidden lg:flex">
        <Box
          className={`w-screen h-screen flex flex-col bg-black p-20 space-y-10`}
          sx={{ outline: "none" }}
        >
          <div className="w-full flex justify-end bg-black">
            <IonIcon
              icon={closeOutline}
              className="w-10 h-10 text-white cursor-pointer"
              onClick={() => setIsOpenFullImage(false)}
            />
          </div>

          <div className="grow overflow-hidden">
            <CarouselSlider
              pictures={pictures}
              defaultPicture={activeImage}
              arrowsIsInside={false}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Gallery;
