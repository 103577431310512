import React from "react";
export const listOfServices = [
  { picture: "wifi", name: "Wifi" },
  { picture: "tv", name: "TV" },
  { picture: "fridge", name: "Réfrigérateur" },
  { picture: "washing-machine", name: "Lave-Linge" },
  { picture: "tumble-dryer", name: "Sèche-Linge" },
  { picture: "alarm", name: "Alarme" },
  { picture: "swimming-pool", name: "Piscine" },
  { picture: "jacuzzi", name: "Jacuzzi" },
  { picture: "barbecue", name: "Barbecue" },
  { picture: "smoke-detector", name: "Détecteur de fumée" },
  { picture: "fire-extinguisher", name: "Extincteur" },
  { picture: "first-aid-kit", name: "Kit de premier secours" },
  {
    picture: "carbon-monoxide-detector",
    name: "Détecteur monoxyde de carbone",
  },
  { picture: "parking-free", name: "Parking gratuit" },
  { picture: "parking", name: "Parking Payant" },
  { picture: "air-conditionning", name: "Clim" },
  { picture: "briefcase", name: "Espace de travail" },
  { picture: "baby-cot", name: "Lit Bébé" },
  { picture: "plancha", name: "Plancha" },
];

export const listOfCategories = [
  { picture: "house", name: "Maison" },
  { picture: "officeBuildingOutline", name: "Appartement" },
  { picture: "persons", name: "Maison d'hôte" },
  { picture: "guestCottage", name: "Gîte" },
  { picture: "castle", name: "Château" },
  { picture: "rocket", name: "Insolite" },
];

const ServiceAndCategoryCard = ({ name, type, isActive = false }) => {
  let source;
  let picture;

  if (type === "service") {
    picture = listOfServices?.find(
      (service) => service.name?.toLowerCase() === name?.toLowerCase()
    )?.picture;

    source = `/images/services-icons/${picture}.svg`;
  } else {
    picture = listOfCategories?.find(
      (category) => category.name?.toLowerCase() === name?.toLowerCase()
    )?.picture;

    source = `/images/categories-icons/${picture}.svg`;
  }

  return (
    <div className="flex flex-col items-center space-y-2">
      <div
        className={`p-2 bg-main/main-100 rounded-full ${
          isActive ? "bg-opacity-50" : "bg-opacity-5"
        }`}
      >
        <img src={source} className="w-10 h-10" />
      </div>

      <p className="text-sm text-center">{name}</p>
    </div>
  );
};

export default ServiceAndCategoryCard;
