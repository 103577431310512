import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import CarouselSlider from "../CarouselSlider";
import NextButton from "./NextButton";

const AddPicturesContent = () => {
  const { setStartAd, startAd } = useAd();
  const { picturesFiles, picturesUrls } = startAd;
  const [message, setMessage] = useState("Ajoutez au minimum 3 photos");
  const maxFileSize = 10;
  const maxNbOfImages = 50;

  async function handleImageUpload(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  async function handleChange(e) {
    const images = e.target.files;
    const nbImagesAlreadySelected = picturesFiles.length;

    if (
      nbImagesAlreadySelected < maxNbOfImages &&
      images.length <= maxNbOfImages &&
      nbImagesAlreadySelected + images.length <= maxNbOfImages
    ) {
      let newPicturesUrls = picturesUrls;
      let newPicturesFiles = picturesFiles;

      for (let index = 0; index < images.length; index++) {
        const image = images[index];
        const fileType = image?.type;
        const allowedExtension = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
        ];
        const fileSize = (image?.size / 1024 / 1024).toFixed(2);

        if (allowedExtension.indexOf(fileType) > -1 && fileSize < maxFileSize) {
          try {
            const compressedFile = await handleImageUpload(image);

            newPicturesUrls.push(URL.createObjectURL(image));
            newPicturesFiles.push(compressedFile);
          } catch (e) {
            console.log(e);
          }
        } else {
          setMessage(
            `Seuls les fichiers aux formats .png, .jpg, .jpeg et .webp, d'une taille maximale de ${maxFileSize} Mo, sont acceptés`
          );
        }
      }

      if (nbImagesAlreadySelected < newPicturesFiles.length) {
        setStartAd({
          ...startAd,
          picturesUrls: newPicturesUrls,
          picturesFiles: newPicturesFiles,
        });
      }
    } else {
      setMessage(`Vous ne pouvez pas ajouter plus de ${maxNbOfImages} photos`);
    }
  }

  useEffect(() => {
    if (startAd?.picturesFiles.length < 3) {
      setMessage("Ajoutez au minimum 3 photos");
    } else {
      setMessage("");
    }
  }, [startAd]);

  return (
    <div className="space-y-6 md:px-4">
      <h4 className=" text-center ">Ajoutez des photos</h4>

      <div className="">
        <label htmlFor="file-upload" className="custom-file-upload">
          <img
            src="/images/addPictureNoHeart.svg"
            alt="next arrow"
            className="w-full h-[200px] flex-none"
          />
        </label>

        <input
          type="file"
          id="file-upload"
          className="hidden"
          onChange={handleChange}
          accept=".jpg, .png, .jpeg, .webp"
          name="fileInput"
          multiple
        />
      </div>

      <div className={`flex justify-center items-center ${message && "h-11"}`}>
        <p className="text-center">{message}</p>
      </div>

      <div className="w-full h-[210px] fle">
        <CarouselSlider
          pictures={picturesUrls}
          deleteButton={true}
          moreStyle={"rounded-lg"}
        />
      </div>

      <div className="flex w-full justify-end pt-6">
        <NextButton isDisabled={picturesUrls.length < 3} />
      </div>
    </div>
  );
};
const AddPictures = ({}) => {
  return (
    <AdModal children={<AddPicturesContent />} stepNumber={4} height="866px" />
  );
};
export default AddPictures;
